export const initialState = {
  formValue: {
    first_name: '',
    last_name: '',
    cell_phone: '',
    email: '',
  },
  isFormNotValid: true, // true initially
  activeStep: 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUE':
      return { ...state, formValue: { ...action.payload } };
    case 'SET_ACTIVE_STEP':
      return { ...state, activeStep: action.payload };
    case 'UPDATE_FORM_VALUE':
      return {
        ...state,
        formValue: {
          ...state.formValue,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_IS_FORM_NOT_VALID':
      return { ...state, isFormNotValid: action.payload };
    default:
      throw new Error('Unknown action type');
  }
};
