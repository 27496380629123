import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { Reducer as Agency } from './Agency/reducer';
import { Reducer as AgencyUsers } from './AgencyUsers/reducer';
import { Reducer as Groups } from './Group/reducer';
import { Reducer as Profile } from './Profile/reducer';
import { Reducer as Caregivers } from './Caregivers/reducer';
import { Reducer as Leads } from './Leads/reducer';
import { Reducer as Login } from './Login/reducer';
import { Reducer as MetaData } from './MetaData/reducer';
import { Reducer as Session } from './Session/reducer';
import { Reducer as Theme } from './Theme/reducer';
import { Reducer as Token } from './Token/reducer';
import { Reducer as User } from './User/reducer';
import { Reducer as Payment } from './Payment/reducer';
import { Reducer as Notifier } from '../packages/AppNotifier/reducer';
import { Reducer as FetchApiList } from '../api/reducer';
import { Reducer as Dashboard } from './Dashboard/reducer';

export default function RootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    Agency,
    Groups,
    AgencyUsers,
    Caregivers,
    Leads,
    Login,
    MetaData,
    Session,
    Theme,
    Token,
    User,
    FetchApiList,
    Profile,
    Notifier,
    Payment,
    Dashboard,
  });
}
