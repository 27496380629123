import {
  ADD_USER_API,
  GET_AGENCIES_WITH_ACCESS_API,
  GET_USER_API,
  USER_INITIAL_STATE,
} from './constants';

export function Reducer(state = USER_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_API.REQUEST:
    case ADD_USER_API.REQUEST:
    case GET_AGENCIES_WITH_ACCESS_API.REQUEST:
      return { ...state, isFetching: true };
    case ADD_USER_API.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_USER_API.SUCCESS:
      return {
        ...state,
        userList: action.data,
        isFetching: false,
      };
    case GET_AGENCIES_WITH_ACCESS_API.SUCCESS:
      return {
        ...state,
        userAccessibleAgenciesList: action.data,
        isFetching: false,
      };
    case GET_USER_API.FAILURE:
    case ADD_USER_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
