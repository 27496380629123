import { createAPIActions } from '../../utils';

export const GROUP_INITIAL_STATE = {
  isFetching: false,
  groupList: [],
  selectedGroup: {},
  selectedGroupBillingInfo: {},
};
export const CREATE_GROUP_API = createAPIActions('create', 'group/api');
export const GET_GROUP_API = createAPIActions('get', 'group/api');
export const GET_GROUP_BY_ID_API = createAPIActions('get', 'group/id/api');
export const DELETE_GROUP_BY_ID_API = createAPIActions(
  'delete',
  'group/id/api',
);
export const DOWNLOAD_DATA_BY_GROUP = createAPIActions(
  'get',
  'group/id/leads/report',
);

export const UPDATE_GROUP_BY_ID_API = createAPIActions(
  'update',
  'group/id/api',
);
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const CLEAR_SELECTED_GROUP = 'CLEAR_SELECTED_GROUP';

export const GET_SELECTED_GROUP_BILLING_INFO = createAPIActions(
  'get',
  'selected/group/billing/info',
);

export const CREATE_BILLING_INFO = createAPIActions('create', 'group/id/api');

export const UPDATE_BILLING_INFO_BY_BILLING_ID = createAPIActions(
  'update',
  'billing/id/api',
);

export const CLEAR_SELECTED_GROUP_BILLING_INFO =
  'CLEAR_SELECTED_GROUP_BILLING_INFO';

export const SET_SELECTED_GROUP_BILLING_INFO =
  'SET_SELECTED_GROUP_BILLING_INFO';
