import { SET_THEME, THEME_INITIAL_STATE } from './constants';
import { LOG_OUT } from '../Logout/constants';

export function Reducer(state = THEME_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        primary: /* action?.primaryColor || */ THEME_INITIAL_STATE.primary,
        secondary:
          /* action?.secondaryColor || */ THEME_INITIAL_STATE.secondary,
      };
    }
    case LOG_OUT:
      return THEME_INITIAL_STATE;
    default:
      return state;
  }
}
