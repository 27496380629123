import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import { LOG_IN_API } from './constants';

export const doLogin = (userDetails) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.AUTH,
    actionType: LOG_IN_API,
    data: userDetails,
  };
  return dispatch(doRequest.post(config));
};
