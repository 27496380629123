import { createAPIActions } from '../../utils';

export const USER_INITIAL_STATE = {
  userType: '',
  firstName: '',
  lastName: '',
  middleName: '',
};
export const SET_USER_INFO = 'SET_USER_INFO';
export const UPDATE_USER_PASSWORD_API = createAPIActions(
  'update',
  'user/password/api',
);
export const RESET_USER_PASSWORD_API = createAPIActions(
  'reset',
  'user/password/api',
);
