const TABLE_HEADERS = [
  { label: 'leads', key: 'total_leads', search: true, sort: true },
  { label: 'address', key: 'address', search: true, sort: true },
  { label: 'city', key: 'city', search: true, sort: true },
  { label: 'state', key: 'state', search: true, sort: true },
  { label: 'Zip', key: 'zip_postal_code', search: true, sort: true },
  { label: 'Promo Code', key: 'affiliate', search: true, sort: true },
  { label: 'Ref. Source', key: 'referrer', search: true, sort: true },
];

export const AGENCY_LIST_TABLE_HEADERS = {
  BOOMERS_ADMIN: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'Registration date',
      key: 'created_date',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'STATUS',
      key: 'status',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '80px',
    },
  ],
  SUPER_ADMIN_2: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'Registration date',
      key: 'created_date',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'STATUS',
      key: 'status',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '80px',
    },
  ],
  AGENCY_ADMIN_BASIC: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'STATUS',
      key: 'status',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '80px',
    },
  ],
  DELEGATE: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '70px',
    },
  ],
  DELEGATE_RW: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '70px',
    },
  ],
  BOOMERS_ADMIN_AGENCY: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS.filter((column) => column.label !== 'leads'),
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '80px',
    },
  ],
  DEFAULT: [
    { label: 'name', key: 'name', search: true, sort: true },
    ...TABLE_HEADERS,
    {
      label: 'STATUS',
      key: 'status',
      search: false,
      sort: true,
      width: '120px',
    },
    {
      label: 'ACTIONS',
      key: 'action',
      search: false,
      sort: false,
      width: '80px',
    },
  ],
};
