import { createAPIActions } from '../../utils';

export const AGENCY_INITIAL_STATE = {
  isFetching: false,
  agencyList: [],
  selectedAgency: {},
  selectedAgencyBillingInfo: {},
};
export const CREATE_AGENCY_API = createAPIActions('create', 'agency/api');
export const ADD_AGENCY_API = createAPIActions('add', 'agency/api');
export const GET_AGENCY_API = createAPIActions('get', 'agency/api');
export const GET_AGENCY_BY_ID_API = createAPIActions('get', 'agency/id/api');
export const DELETE_AGENCY_BY_ID_API = createAPIActions(
  'delete',
  'agency/id/api',
);
export const UPDATE_AGENCY_BY_ID_API = createAPIActions(
  'update',
  'agency/id/api',
);
export const SET_SELECTED_AGENCY = 'SET_SELECTED_AGENCY';
export const CLEAR_SELECTED_AGENCY = 'CLEAR_SELECTED_AGENCY';

export const DOWNLOAD_DATA_BY_AGENCY = createAPIActions(
  'get',
  'agency/id/leads/report',
);

export const GET_SELECTED_AGENCY_BILLING_INFO = createAPIActions(
  'get',
  'selected/agency/billing/info',
);

export const CREATE_BILLING_INFO = createAPIActions('create', 'agency/id/api');

export const UPDATE_BILLING_INFO_BY_BILLING_ID = createAPIActions(
  'update',
  'billing/id/api',
);

export const CLEAR_SELECTED_AGENCY_BILLING_INFO =
  'CLEAR_SELECTED_AGENCY_BILLING_INFO';

export const SET_SELECTED_AGENCY_BILLING_INFO =
  'SET_SELECTED_AGENCY_BILLING_INFO';
