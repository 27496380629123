import React, { Component, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeSnackbar } from './actions';
import SnackbarContentWrapper from './SnackBarContent';
import { getUniqueKey } from '../../utils';

const StyledSnackbarContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: ${(props) => props.theme.zIndex.snackbar};
  height: auto;
  width: auto;
  min-width: 288px;
  bottom: ${(props) => props.theme.spacing(2)}px;
  right: ${(props) => props.theme.spacing(2)}px;
  transition: ${(props) =>
    props.theme.transitions.create(['top', 'right', 'bottom', 'left'], {
      easing: 'ease',
    })};
`;

function SnackBarContentWrapperComponent(props) {
  const [notifications, setNotifications] = useState([]);

  const handleExit = (key) => {
    props.removeSnackbar(key);
  };
  const handleClose = ({ reason, key, itemID }) => {
    if (reason && key && reason === 'clickaway') {
      return;
    }
    handleExit(itemID);
  };

  useEffect(() => {
    setNotifications(props.notifications);
  }, [props.notifications]);

  return useMemo(
    () => (
      <StyledSnackbarContentWrapper>
        {notifications &&
          notifications.length > 0 &&
          notifications.map((notification, index) => {
            const { key: itemID, message, options = {} } = notification;
            return (
              <SnackbarContentWrapper
                key={getUniqueKey(`key_${itemID}`, index)}
                itemId={itemID}
                onClose={(event, reason) => {
                  handleClose({ event, reason, itemID });
                }}
                variant={options.variant}
                message={message}
                duration={options.autoHideDuration}
                onexit={handleExit}
                {...props}
              />
            );
          })}
      </StyledSnackbarContentWrapper>
    ),
    [notifications],
  );
}

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.notifications.length !== state.notifications.length) {
      return {
        notifications: props.notifications,
      };
    }
    // No state update necessary
    return null;
  }

  render() {
    const { notifications = [] } = this.state;
    return (
      notifications &&
      notifications.length > 0 && (
        <SnackBarContentWrapperComponent
          notifications={notifications}
          {...this.props}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.Notifier.notifications,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
