import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';
import { APP_CONSTANTS } from './constants';
import LoginPage from './pages/Login';
import RegistrationPage from './pages/Registration';
import { getMetaData } from './modules/MetaData/actions';
import AppRouteResolver from './packages/AppRouteResolver';
import ResetPasswordPage from './pages/ResetPassword';
import BasicRegistrationPage from './pages/BasicRegistration';

function BaseRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetaData());
  }, []);

  return (
    <>
      <Fade>
        <Switch>
          <Route
            path={APP_CONSTANTS.BASE_ROUTE_URLS.REGISTER}
            render={() => <RegistrationPage />}
          />
          <Route
            path={APP_CONSTANTS.BASE_ROUTE_URLS.BASIC_REGISTER}
            render={() => <BasicRegistrationPage />}
          />
          <Route
            path={APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN}
            render={() => <LoginPage />}
          />
          <Route
            path={APP_CONSTANTS.BASE_ROUTE_URLS.RESET_PASSWORD}
            render={() => <ResetPasswordPage />}
          />
          <Route
            path={APP_CONSTANTS.BASE_URL}
            render={() => <AppRouteResolver />}
          />
          <Route
            path="/"
            render={() => <Redirect to={APP_CONSTANTS.BASE_URL} />}
          />
        </Switch>
      </Fade>
    </>
  );
}

export default hot(BaseRoutes);
