import {
  GROUP_INITIAL_STATE,
  CREATE_GROUP_API,
  GET_GROUP_API,
  SET_SELECTED_GROUP,
  CLEAR_SELECTED_GROUP,
  CREATE_BILLING_INFO,
  CLEAR_SELECTED_GROUP_BILLING_INFO,
  SET_SELECTED_GROUP_BILLING_INFO,
} from './constants';

export function Reducer(state = GROUP_INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_GROUP_API.REQUEST:
    case CREATE_BILLING_INFO.REQUEST:
    case GET_GROUP_API.REQUEST:
      return { ...state, isFetching: true };
    case CREATE_GROUP_API.SUCCESS:
    case CREATE_BILLING_INFO.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_GROUP_API.SUCCESS:
      return {
        ...state,
        groupList: action.data,
        isFetching: false,
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.group,
      };
    case CLEAR_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: {},
      };
    case CLEAR_SELECTED_GROUP_BILLING_INFO:
      return {
        ...state,
        selectedGroupBillingInfo: {},
      };
    case SET_SELECTED_GROUP_BILLING_INFO:
      return {
        ...state,
        selectedGroupBillingInfo: action.data,
      };
    case CREATE_GROUP_API.FAILURE:
    case GET_GROUP_API.FAILURE:
    case CREATE_BILLING_INFO.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
