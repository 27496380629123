import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import { ValidateField } from '../../utils';
import { StyledTextField } from '../AppStyledComponents';

function TextFieldComponent(props) {
  const {
    formField: {
      id,
      value: defaultValue,
      label,
      variant,
      type,
      minNumValue,
      maxNumValue,
      watchKeyDown,
      name,
      multiline,
      rows,
      placeholder,
      regExpValue,
      required,
      disabled,
      startAdornment,
      typographyProps,
      commonFieldHeading,
      haveCommonField,
      boxProps,
      hidden,
    },
  } = props;
  const [fieldValue, setFieldValue] = useState('');
  const [error, setError] = useState(false);
  const handleErrorField = (val, regExpVal) =>
    ValidateField(val, regExpVal, required);

  // const handleErrorField = (val, regExpVal) =>
  //   required ? ValidateField(val, regExpVal) : false;

  const handleTextFieldChange = () => (event) => {
    const {
      target: { checked, value, type: fieldType },
    } = event;
    const actValue = fieldType === 'checkbox' ? checked : value;
    const err = handleErrorField(actValue, regExpValue);
    setFieldValue(actValue);
    setError(err);
    props.handleChange({ name, value: actValue, error: err });
  };

  useEffect(() => {
    setFieldValue(props.value || defaultValue);
  }, [props.value]);

  return useMemo(
    () =>
      hidden ? null : (
        <>
          {haveCommonField && commonFieldHeading && (
            <Typography variant="caption" gutterBottom {...typographyProps}>
              {commonFieldHeading}
            </Typography>
          )}
          {haveCommonField && <Box width={1} {...boxProps} />}
          <StyledTextField
            id={id}
            label={label}
            variant={variant}
            type={type}
            name={name}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder}
            required={required}
            value={fieldValue}
            error={error}
            disabled={disabled}
            InputProps={{
              inputProps: { min: minNumValue, max: maxNumValue },
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : (
                ''
              ),
            }}
            onChange={handleTextFieldChange(name, required, regExpValue)}
            onKeyDown={
              watchKeyDown
                ? (event) => props?.handelKeyDown(fieldValue, event)
                : ''
            }
          />
        </>
      ),
    [
      error,
      fieldValue,
      handleTextFieldChange,
      id,
      label,
      multiline,
      name,
      placeholder,
      regExpValue,
      required,
      rows,
      type,
      variant,
      hidden,
    ],
  );
}

export default TextFieldComponent;
