import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { AppButton } from '../../AppButton';
import { StyledDialogActions } from '../../AppStyledComponents';

const ConfirmationModal = ({
  title,
  children,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleClose = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };
  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      disableBackdropClick={false}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <StyledDialogActions>
        <AppButton size="small" label="Cancel" handleClick={handleClose} />
        <AppButton
          size="small"
          color="primary"
          label="Ok"
          variant="contained"
          marginleft={2}
          handleClick={handleConfirm}
        />
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
