import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import { useDispatch } from 'react-redux';
import {
  activateCaregiverProfile,
  deactivateCaregiverProfile,
} from '../../modules/Caregivers/actions';
import IOSSwitch from '../../packages/AppIOSSwitch';

export default function CaregiversTableActiveToggle(props) {
  const {
    row: { caregiver_identifier, status },
  } = props;
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setActive(_.toLower(status) === 'active');
  }, [status]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(activateCaregiverProfile(caregiver_identifier)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
        }
      });
    } else {
      dispatch(deactivateCaregiverProfile(caregiver_identifier)).then(
        (resp) => {
          const {
            data: { success },
          } = resp;
          if (success) {
            setActive(checked);
          }
        },
      );
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
        /* maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', */
      }}
    >
      <IOSSwitch checked={active} onChange={handleChange} name="checkedB" />
      {status}
    </TableCell>
  );
}
