import { createAPIActions } from '../../utils';

export const METADATA_INITIAL_STATE = {
  isFetching: false,
  availability: [],
  timezones: [],
  smoking: [],
  pets: [],
  language: [],
  insurance: [],
  identity: [],
  certificate: [],
  care_condition: [],
  agencyType: [],
  serviceProvided: [],
  paymentOption: [],
};
export const GET_METADATA_API = createAPIActions('get', 'app_metadata/api');
