import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  ADD_AGENCY_API,
  CLEAR_SELECTED_AGENCY,
  CLEAR_SELECTED_AGENCY_BILLING_INFO,
  CREATE_AGENCY_API,
  CREATE_BILLING_INFO,
  DELETE_AGENCY_BY_ID_API,
  DOWNLOAD_DATA_BY_AGENCY,
  GET_AGENCY_API,
  GET_AGENCY_BY_ID_API,
  GET_SELECTED_AGENCY_BILLING_INFO,
  SET_SELECTED_AGENCY,
  SET_SELECTED_AGENCY_BILLING_INFO,
  UPDATE_AGENCY_BY_ID_API,
  UPDATE_BILLING_INFO_BY_BILLING_ID,
} from './constants';
import { UPDATE_CAREGIVERS_API } from '../Caregivers/constants';

export const createAgency = (agencyDetails) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.AGENCY,
    actionType: CREATE_AGENCY_API,
    formData: true,
    filterPayload: false,
    data: agencyDetails,
  };
  return dispatch(doRequest.post(config));
};

export const addAgency = (agencyDetails) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/add`,
    actionType: ADD_AGENCY_API,
    filterPayload: true,
    data: agencyDetails,
  };
  return dispatch(doRequest.post(config));
};

export const getAgency = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.AGENCY,
    actionType: GET_AGENCY_API,
  };
  return dispatch(doRequest.get(config));
};

export const getAgencyById = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}`,
    actionType: GET_AGENCY_BY_ID_API,
  };
  return dispatch(doRequest.get(config));
};

export const updateAgency = (agencyId, agency) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}`,
    actionType: UPDATE_AGENCY_BY_ID_API,
    formData: true,
    data: agency,
  };
  return dispatch(doRequest.put(config));
};

export const deleteAgency = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}`,
    actionType: DELETE_AGENCY_BY_ID_API,
  };
  return dispatch(doRequest.delete(config));
};

export const setSelectedAgency = (agency) => (dispatch) =>
  dispatch({ type: SET_SELECTED_AGENCY, agency });

export const clearSelectedAgency = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_AGENCY });

export const activateAgency = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/activate`,
    actionType: UPDATE_CAREGIVERS_API,
  };
  return dispatch(doRequest.put(config));
};

export const deactivateAgency = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/deactivate`,
    actionType: UPDATE_CAREGIVERS_API,
  };
  return dispatch(doRequest.put(config));
};

export const downloadByAgencyId = (agencyId, fromDate, toDate) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/leads/report`,
    actionType: DOWNLOAD_DATA_BY_AGENCY,
    data: {
      from_date: fromDate,
      to_date: toDate,
    },
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(doRequest.post(config));
};

export const getSelectedAgencyBillingInfo = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/billing`,
    actionType: GET_SELECTED_AGENCY_BILLING_INFO,
  };
  return dispatch(doRequest.get(config));
};

export const createSelectedAgencyBillingInfo = (billingDetails, agencyId) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/billing`,
    actionType: CREATE_BILLING_INFO,
    formData: true,
    filterPayload: true,
    data: billingDetails,
  };
  return dispatch(doRequest.post(config));
};

export const updateSelectedAgencyBillingInfo = (billingDetails) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLING}`,
    actionType: UPDATE_BILLING_INFO_BY_BILLING_ID,
    formData: true,
    filterPayload: true,
    data: billingDetails,
  };
  return dispatch(doRequest.put(config));
};

export const clearSelectedAgencyBillingInfo = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_AGENCY_BILLING_INFO });

export const setSelectedAgencyBillingInfo = (billingDetails) => (dispatch) =>
  dispatch({ type: SET_SELECTED_AGENCY_BILLING_INFO, billingDetails });
