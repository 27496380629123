import { GET_METADATA_API, METADATA_INITIAL_STATE } from './constants';

export function Reducer(state = METADATA_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_METADATA_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_METADATA_API.SUCCESS:
      return {
        ...state,
        availability: action.data?.metadata?.AVAILABILITY,
        timezones: action.data?.metadata?.TIMEZONES,
        smoking: action.data?.metadata?.SMOKING,
        pets: action.data?.metadata?.PETS,
        language: action.data?.metadata?.LANGUAGE,
        insurance: action.data?.metadata?.INSURANCE,
        identity: action.data?.metadata?.ID,
        certificate: action.data?.metadata?.CERTIFICATE,
        care_condition: action.data?.metadata?.CARE_CONDITION,
        agencyType: action.data?.metadata?.AGENCY_TYPE,
        serviceProvided: action.data?.metadata?.SERVICE_PROVIDED,
        serviceProvidedADC: action.data?.metadata?.SERVICE_PROVIDED_ADC,
        serviceProvidedALF: action.data?.metadata?.SERVICE_PROVIDED_ALF,
        paymentOption: action.data?.metadata?.PAYMENT_OPTION,
      };
    case GET_METADATA_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
