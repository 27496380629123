import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Card,
  Box,
  Tooltip,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import * as _ from 'lodash';

// MUI Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Custom components
import ErrorBoundary from '../../packages/AppErrorBoundary';
import { AppButton } from '../../packages/AppButton';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import QontoStepIcon, { QontoConnector } from '../../packages/Stepper/Qonto';
import FormGroupComponent from '../../packages/AppFormGroup';
import Payment from '../../components/Payment';
import * as Styled from './StyledComponents';

// constants
import FormFields from './FormField';
import { APP_CONSTANTS } from '../../constants';
import {
  COUNTRY_STATES,
  COUNTRY_LABELS,
  COUNTRY_REGEX,
} from '../../data/config';
import {
  FORM_STEPS,
  REQUIRED_FIELDS,
  STEP_WISE_REQUIRED_FIELDS,
  OPTIONAL_FIELDS_WITH_VALIDATION,
} from './constants';

// utils and services
import { addAgency } from '../../modules/Agency/actions';
import { resetPayment } from '../../modules/Payment/actions';
import { initialState, reducer } from './reducer';
import { convertTimeToAMPM, debounced, validateForm } from '../../utils';

export default function AddAgencyPage() {
  const paymentChild = React.createRef();
  const history = useHistory();
  const rDispatch = useDispatch();

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const timezones = useSelector((appState) => appState.MetaData.timezones);
  const paymentOption = useSelector(
    (appState) => appState.MetaData.paymentOption,
  );
  const serviceProvided = useSelector(
    (appState) => appState.MetaData.serviceProvided,
  );
  const serviceProvidedADC = useSelector(
    (appState) => appState.MetaData.serviceProvidedADC,
  );
  const serviceProvidedALF = useSelector(
    (appState) => appState.MetaData.serviceProvidedALF,
  );
  const agencyType = useSelector((appState) => appState.MetaData.agencyType);

  const {
    loading,
    activeStep,
    formValue,
    fieldError,
    validForm,
    paymentValid,
    validOptionalField,
    stepWiseValidForm,
    lastFieldNameChanged,
  } = state;

  const setFormValue = (value) => {
    dispatch({ type: 'SET_FORM_VALUE', payload: value });
  };

  const updateFormValue = (name, value) => {
    dispatch({ type: 'UPDATE_FORM_VALUE', payload: { name, value } });
  };

  const setFieldError = (error) => {
    dispatch({ type: 'SET_FIELD_ERROR', payload: error });
  };

  const updateFieldError = (name, value) => {
    dispatch({ type: 'UPDATE_FIELD_ERROR', payload: { name, value } });
  };

  const setValidForm = (isValid) => {
    dispatch({ type: 'SET_VALID_FORM', payload: isValid });
  };

  const setValidOptionalField = (isValid) => {
    dispatch({ type: 'SET_VALID_OPTIONAL_FIELD', payload: isValid });
  };

  const setLoading = (isLoading) => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  };

  const setPaymentValid = (valid) => {
    dispatch({ type: 'SET_PAYMENT_VALID', payload: valid });
  };

  const setActiveStep = (step) => {
    dispatch({ type: 'SET_ACTIVE_STEP', payload: step });
  };

  const setStepWiseValidForm = (validState) => {
    dispatch({ type: 'SET_STEP_WISE_VALID_FORM', payload: validState });
  };

  const setLastFieldNameValue = (fields) => {
    dispatch({ type: 'SET_LAST_FIELD_NAME_CHANGED_VALUE', payload: fields });
  };

  const doFormValidation = debounced(399, () => {
    setValidForm(validateForm({ requiredFields: REQUIRED_FIELDS, fieldError }));
    setStepWiseValidForm({
      [activeStep]: validateForm({
        requiredFields: STEP_WISE_REQUIRED_FIELDS[activeStep],
        fieldError,
      }),
    });
  });

  const updateFormFieldsWithMetaData = () => {
    FormFields[0].list[7].values = [];

    FormFields[0].list[14].values = [];
    FormFields[1].list[0].values = [];

    _.forEach(agencyType, (agencyTypeVal) => {
      FormFields[0].list[7].values.push({
        label: agencyTypeVal,
        value: agencyTypeVal,
      });
    });

    _.forEach(paymentOption, (po) => {
      FormFields[0].list[14].values.push({ label: po, value: po });
    });
    _.forEach(timezones, (tz) => {
      FormFields[1].list[0].values.push({ label: tz, value: tz });
    });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = ({ name, value, error }) => {
    let updatedValidOptionalField = false;

    if (_.includes(OPTIONAL_FIELDS_WITH_VALIDATION, name)) {
      updatedValidOptionalField = error;
    }

    updateFormValue(name, value);
    updateFieldError(name, error);
    setLastFieldNameValue(`${name}+=${value}`);
    setValidOptionalField(updatedValidOptionalField);
  };

  const enableSubmit = (shouldSubmit) => {
    setPaymentValid(shouldSubmit);
  };

  const formSubmitHandler = async () => {
    const result = await paymentChild.current.showAlert();
    const { token, err } = result;

    if (!err) {
      setLoading(true);
      setValidForm(false);

      const stripeToken = token.id;

      const formData = {
        ...formValue,
        token: stripeToken,
        package_type: 'BASIC',
        office_hours_from: convertTimeToAMPM(formValue?.office_hours_from),
        office_hours_to: convertTimeToAMPM(formValue?.office_hours_to),
        t_and_c_accepted_date: new Date().toISOString(),
        service_provided: {
          service_provided: formValue?.service_provided,
        },
        agency_type: {
          agency_type:
            formValue?.agency_type && typeof formValue.agency_type === 'string'
              ? Array.from({ length: 1 }, () => formValue.agency_type)
              : [],
        },
        payment_option: { payment_option: formValue?.payment_option },
        lead_notifications_emails: {
          client_lead_notifications_emails:
            formValue?.client_lead_notifications_emails,
          employee_lead_notifications_emails:
            formValue?.employee_lead_notifications_emails,
          billing_notifications_emails: formValue?.billing_notifications_emails,
        },
        lead_notification_phones: {
          client_lead_notification_phones:
            formValue?.client_lead_notification_phones,
          employee_lead_notifications_phones:
            formValue?.employee_lead_notifications_phones,
        },
      };

      rDispatch(addAgency(formData))
        .then((resp) => {
          if (resp?.data?.success) {
            history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
          }
          setValidForm(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setValidForm(true);
        });
    }
  };

  React.useEffect(() => {
    if (lastFieldNameChanged !== '') {
      const updatedFormValue = { ...formValue };
      const updatedFieldError = { ...fieldError };

      const name = lastFieldNameChanged.split('+=')[0];
      const value = lastFieldNameChanged.split('+=')[1];

      if (name === 'agency_type') {
        FormFields[0].list[8].values = [];
        FormFields[0].list[10].hidden = false;
        FormFields[0].list[11].hidden = false;
        FormFields[0].list[12].hidden = false;
        let sp = serviceProvided;
        delete updatedFormValue?.service_provided;
        updatedFieldError.service_provided = true;
        FormFields[1].list[3].value = false;
        FormFields[1].list[1].disabled = false;
        FormFields[1].list[2].disabled = false;
        updatedFormValue.office_hours_from = '09:00';
        updatedFormValue.office_hours_to = '17:00';

        if (_.includes(value, 'Adult Day Care Facility')) {
          sp = serviceProvidedADC;
        }

        if (_.includes(value, 'Assisted Living Facility')) {
          FormFields[0].list[10].hidden = true;
          FormFields[0].list[11].hidden = true;
          FormFields[0].list[12].hidden = true;
          delete updatedFormValue?.min_hours_per_day;
          delete updatedFormValue?.min_hours_per_week;
          delete updatedFormValue?.min_hours_per_visit;
          sp = serviceProvidedALF;
          FormFields[1].list[3].value = true;
          FormFields[1].list[1].disabled = true;
          FormFields[1].list[2].disabled = true;
          updatedFormValue.office_hours_from = '00:00';
          updatedFormValue.office_hours_to = '23:59';
        }

        _.forEach(sp, (avl) => {
          FormFields[0].list[8].values.push({ label: avl, value: avl });
        });
      }

      if (name === 'country') {
        delete updatedFormValue?.state;
        delete updatedFormValue?.zip_postal_code;
        updatedFieldError.state = true;
        updatedFieldError.zip_postal_code = true;
        FormFields[0].list[5].values = COUNTRY_STATES[value];
        FormFields[0].list[5].label = COUNTRY_LABELS[value].state;
        FormFields[0].list[6].label = COUNTRY_LABELS[value].zip;
        FormFields[0].list[6].regExpValue = COUNTRY_REGEX[value];
      }

      if (name === '24hour') {
        if (value === 'true') {
          FormFields[1].list[1].disabled = true;
          FormFields[1].list[2].disabled = true;
          updatedFormValue.office_hours_from = '00:00';
          updatedFormValue.office_hours_to = '23:59';
        } else {
          updatedFormValue.office_hours_from = '09:00';
          updatedFormValue.office_hours_to = '17:00';
          FormFields[1].list[1].disabled = false;
          FormFields[1].list[2].disabled = false;
        }
      }

      setFormValue({
        ...updatedFormValue,
      });
      setFieldError({
        ...updatedFieldError,
      });
    }
  }, [lastFieldNameChanged]);

  React.useEffect(() => {
    doFormValidation();
  }, [formValue]);

  React.useEffect(() => {
    updateFormFieldsWithMetaData();
  }, [timezones, agencyType, paymentOption]);

  React.useEffect(() => {
    rDispatch(resetPayment());
  }, []);

  return (
    <>
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Add Agency">
              <Grid
                alignItems="flex-start"
                justify="space-between"
                alignContent="stretch"
                spacing={2}
                container
              >
                <AppButton
                  variant="text"
                  color="primary"
                  label="Back to Agencies"
                  handleClick={() => {
                    history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
                  }}
                />
              </Grid>
            </AppPageTitle>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Styled.MuiCardContent>
                <Stepper
                  style={{ padding: isMobile ? 0 : 8 }}
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                  className="stepper-container"
                >
                  {FORM_STEPS.map((label) => (
                    <Step key={label} className="stepper-step">
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        className="stepper-label"
                      >
                        <Typography
                          variant="subtitle1"
                          className="stepper-typography"
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <ErrorBoundary>
                  {FormFields[activeStep]?.type === 'PAYMENT' && (
                    <Payment
                      ref={paymentChild}
                      enableSubmit={enableSubmit}
                      pageType="ADD_AGENCY"
                      newPaymentMethod
                    />
                  )}
                </ErrorBoundary>

                <form
                  noValidate
                  autoComplete="off"
                  name="addAgencyForm"
                  className="form-container add-agency"
                >
                  <Box
                    className={`form-content ${
                      FormFields[activeStep]?.type === 'PAYMENT' &&
                      'payment-content'
                    }`}
                  >
                    <ErrorBoundary>
                      {FormFields[activeStep]?.type === 'NOTIFICATION' ? (
                        <>
                          {_.map(
                            FormFields[activeStep]?.list,
                            (fields, index) => (
                              <Styled.MuiAccordion
                                defaultExpanded={fields.defaultExpanded}
                                key={`notification-form-group-accordion-key-${index}`}
                              >
                                <Styled.MuiAccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Box className="accordion-summary-content">
                                    <Typography variant="subtitle1">
                                      {fields?.title}
                                    </Typography>
                                    <Tooltip
                                      title={
                                        <Typography variant="body1">
                                          {fields?.info}
                                        </Typography>
                                      }
                                    >
                                      <InfoOutlinedIcon className="accordion-summary-info-icon" />
                                    </Tooltip>
                                  </Box>
                                </Styled.MuiAccordionSummary>
                                <Styled.MuiAccordionDetails>
                                  <FormGroupComponent
                                    handleChange={handleChange}
                                    formFields={fields}
                                    values={formValue}
                                  />
                                </Styled.MuiAccordionDetails>
                              </Styled.MuiAccordion>
                            ),
                          )}
                        </>
                      ) : (
                        <FormGroupComponent
                          handleChange={handleChange}
                          formFields={FormFields[activeStep]}
                          values={formValue}
                        />
                      )}
                    </ErrorBoundary>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={3}
                    mb={1}
                  >
                    <ErrorBoundary>
                      {activeStep > 0 && (
                        <AppButton label="Back" handleClick={handleBack} />
                      )}
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <AppButton
                        marginleft={1}
                        variant="contained"
                        color="primary"
                        iconPosition={
                          activeStep === FORM_STEPS.length - 1
                            ? 'left'
                            : 'right'
                        }
                        disabled={
                          activeStep === FORM_STEPS.length - 1
                            ? !validForm || !paymentValid || validOptionalField
                            : !stepWiseValidForm[activeStep] ||
                              validOptionalField
                        }
                        label={
                          activeStep === FORM_STEPS.length - 1
                            ? 'Create Agency'
                            : 'Next'
                        }
                        loading={loading}
                        loaderSize={24}
                        handleClick={
                          activeStep === FORM_STEPS.length - 1
                            ? formSubmitHandler
                            : handleNext
                        }
                      />
                    </ErrorBoundary>
                  </Box>
                </form>
              </Styled.MuiCardContent>
            </Card>
          </Grid>
        </Grid>
      </AppContainer>
    </>
  );
}
