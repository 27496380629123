import { createAPIActions } from '../../utils';

export const PAYMENT_INITIAL_STATE = {
  isFetching: false,
  ends_with: null,
  network_brand: null,
  payment_method_id: null,
  success: false,
};
export const GET_PAYMENT_METHOD_API = createAPIActions(
  'get',
  'payment_method/api',
);
export const ADD_PAYMENT_METHOD_API = createAPIActions(
  'add',
  'payment_method/api',
);

export const PAY_AGENCY_BY_ID_API = createAPIActions('pay', 'agency/id/api');

export const RESET_PAYMENT = 'RESET_PAYMENT';
