import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { AppButton } from '../../packages/AppButton';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import AppTable from '../../packages/AppTable';
import { USER_LIST_TABLE_HEADERS } from './constants';
import { APP_CONSTANTS } from '../../constants';
import {
  ToggleUserStatus,
  UsersActions,
} from '../../components/UserTableAction';
import { getUser } from '../../modules/AgencyUsers/action';

export default function AgencyPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userList = useSelector((state) => state.AgencyUsers.userList);
  const userType = useSelector((state) => state.User.userType);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return useMemo(
    () => (
      <>
        <AppContainer>
          <Grid
            alignItems="flex-start"
            justify="space-between"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle title="Users">
                <Grid
                  alignItems="flex-start"
                  justify="space-between"
                  alignContent="stretch"
                  spacing={2}
                  container
                >
                  {userType === 'AGENCY_ADMIN_BASIC' && (
                    <Grid item>
                      <AppButton
                        variant="text"
                        color="primary"
                        label="Add User"
                        handleClick={() => {
                          history.push(APP_CONSTANTS.APP_ROUTE_URLS.ADD_USER);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AppPageTitle>
            </Grid>
            <Grid item xs={12}>
              <AppTable
                tableName="user-list-table"
                size="small"
                orderBy=""
                searchCol
                data={userList}
                headers={USER_LIST_TABLE_HEADERS}
                rowsPerPageOptions={[25, 50, 100, 500]}
                enableCellRender
                render={{
                  action: (props) => <UsersActions {...props}></UsersActions>,
                  status: (props) => <ToggleUserStatus {...props} />,
                }}
              />
            </Grid>
          </Grid>
        </AppContainer>
      </>
    ),
    [userList],
  );
}
