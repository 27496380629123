import styled from 'styled-components';
import { APP_CONSTANTS } from '../../constants';

export const AppContainer = styled.div`
  width: 100%;
  padding-right: ${(props) => props.theme.spacing(4)}px;
  padding-left: ${(props) => props.theme.spacing(4)}px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    padding-right: ${(props) => props.theme.spacing(2)}px;
    padding-left: ${(props) => props.theme.spacing(2)}px;
  }
`;

export const AppRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex-grow: 1;
`;

export const AppRoot = styled.div`
  box-sizing: border-box;
  width: 100vw;
  min-height: calc(100vh - (56px + 16px));
  margin-bottom: 16px;
  padding-top: 80px;
  flex: 1;
`;
