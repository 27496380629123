import { createAPIActions } from '../../utils';

export const SESSION_INITIAL_STATE = {
  isFetching: false,
  isAuthenticated: false,
  spk: null,
};

export const GET_APP_SERVER_SESSION_API = createAPIActions(
  'get',
  'app_server_session/api',
);
