import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AppPageTitle from '../../packages/AppPageTitle';
import { APP_CONSTANTS } from '../../constants';
import AppCard from '../../packages/AppCard';
import { AppButton } from '../../packages/AppButton';
import AppPasswordField from '../../packages/AppPasswordField';
import { debounced } from '../../utils';
import { changeUserPassword } from '../../modules/User/actions';

const cardBoxProps = {
  height: 1,
};
const breadcrumbs = [
  { title: 'Dashboard', path: APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD },
  {
    title: 'Change Password',
    path: APP_CONSTANTS.APP_ROUTE_URLS.CHANGE_PASSWORD,
  },
];

function CardAction(props) {
  const { disableForm, onCancel, onSubmit } = props;
  return (
    <Grid
      alignItems="center"
      justify="flex-end"
      alignContent="center"
      spacing={2}
      container
    >
      <Grid item>
        <AppButton label="Cancel" color="primary" handleClick={onCancel} />
      </Grid>
      <Grid item>
        <AppButton
          disabled={disableForm}
          variant="contained"
          color="primary"
          handleClick={onSubmit}
          label="Update"
        />
      </Grid>
    </Grid>
  );
}

function CardContent(props) {
  const { handleChange } = props;

  return (
    <Grid
      alignItems="center"
      justify="center"
      alignContent="center"
      spacing={2}
      container
    >
      <Grid item xs={12}>
        <AppPasswordField
          id="old_password"
          label="Old Password"
          placeholder="Enter your old password"
          required
          onChange={handleChange('old_password')}
        />
      </Grid>
      <Grid item xs={12}>
        <AppPasswordField
          id="new_password"
          label="New Password"
          placeholder="Enter your new password"
          required
          onChange={handleChange('new_password')}
        />
      </Grid>
    </Grid>
  );
}

function ChangePasswordPage() {
  const [values, setValues] = React.useState({
    old_password: '',
    new_password: '',
  });
  const [disableForm, toggleDisableForm] = React.useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const doFormValidation = debounced(400, () => {
    toggleDisableForm(
      values.old_password === '' ||
        values.old_password.length === 0 ||
        values.new_password === '' ||
        values.new_password.length === 0,
    );
  });

  React.useEffect(() => {
    doFormValidation();
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmitForm = () => {
    dispatch(changeUserPassword(values)).then((resp) => {
      const {
        data: { success },
      } = resp;
      if (success) {
        history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
      }
    });
  };

  const onCancelForm = () => {
    history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
  };

  return (
    <Container>
      <Grid
        alignItems="flex-start"
        justify="space-between"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle breadcrumbs={breadcrumbs} boxProps={{ mb: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            alignItems="center"
            justify="center"
            alignContent="center"
            spacing={2}
            container
          >
            <Grid item xs={12} md={4}>
              <AppCard
                boxProps={cardBoxProps}
                header={<Typography variant="h6">Change Password</Typography>}
                content={<CardContent handleChange={handleChange} />}
                action={
                  <CardAction
                    disableForm={disableForm}
                    onSubmit={onSubmitForm}
                    onCancel={onCancelForm}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChangePasswordPage;

CardContent.propTypes = { handleChange: PropTypes.func };
CardContent.defaultProps = { handleChange: () => {} };
CardAction.propTypes = {
  disableForm: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
CardAction.defaultProps = { onCancel: () => {}, onSubmit: () => {} };
