import React, { Fragment, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import { getUniqueKey } from '../../utils';

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.secondary.dark, 0.1),
      color: theme.palette.getContrastText(
        fade(theme.palette.secondary.dark, 0.1),
      ),
    },
  },
}))(TableRow);

/**
 *
 * @param props
 * @returns {React.Component}
 * @constructor
 */
export default function AppTable(props) {
  const {
    tableName,
    enableCellRender,
    render,
    headers,
    size,
    stickyHeader,
    color,
    searchCol,
    pagination,
    headerProps,
    cellAlign,
  } = props;
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);
  // const [selected, setSelected] = useState({});
  const [searchValue, setSearchValue] = useState({});
  /**
   *
   * @param data
   * @param p
   * @param rpp
   */
  const tableDataHandler = (data, p, rpp) => {
    const filteredData = _.slice(data, p * rpp, p * rpp + rpp);
    setTableData(filteredData);
  };

  useEffect(() => {
    setOrder(props.order);
    setOrderBy(props.orderBy);
    setRowsPerPage(props.rowsPerPageOptions[0]);
    setRowsPerPageOptions(props.rowsPerPageOptions);
  }, []);

  useEffect(() => {
    setFilterData(props.data);
    const rows = props.rowsPerPageOptions[0];
    tableDataHandler(props.data, page, rowsPerPage === 0 ? rows : rowsPerPage);
    setSearchValue({});
  }, [props.data]);
  /**
   *
   * @param event
   * @param property
   */
  const sortHandler = (event, property) => {
    const filteredData = _.orderBy(
      props.data,
      property,
      order === 'desc' ? 'asc' : 'desc',
    );
    tableDataHandler(filteredData, page, rowsPerPage);
    setOrder(order === 'desc' ? 'asc' : 'desc');
    setOrderBy(property);
  };
  /**
   *
   * @param name
   * @returns {function}
   */
  const handleSearchChange = (name) => (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const filteredData = _.filter(props.data, (item) =>
      _.includes(_.lowerCase(item[name]), _.lowerCase(value)),
    );
    setPage(0);
    tableDataHandler(filteredData, 0, rowsPerPage);
    setFilterData(filteredData);
    setSearchValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /**
   *
   * @param event
   */
  const handleChangeRowsPerPage = (event) => {
    const pageSize = event.target.value;
    setRowsPerPage(pageSize);
    setPage(0);
    tableDataHandler(props.data, 0, pageSize);
  };
  /**
   *
   * @param event
   * @param newPage
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableDataHandler(props.data, newPage, rowsPerPage);
  };
  /**
   *
   * @param row
   * @param key
   * @param width
   * @param rowIndex
   * @returns {React.Component}
   */
  const renderCell = (row, key, width, rowIndex) => {
    if (enableCellRender && key in render) {
      return render[key]({
        row,
        key,
        width,
        rowIndex,
        data: tableData,
        length: tableData.length,
        tableName,
      });
    }
    return (
      <TableCell
        align={cellAlign}
        size="small"
        style={{
          width,
        }}
      >
        {_.get(row, key)}
      </TableCell>
    );
  };
  /**
   *
   * @param label
   * @param key
   * @param sort
   * @returns {React.ReactElement}
   */
  const renderHeaderCell = (label, key, sort) => {
    if (!label || label === '') {
      return <StyledTableCell />;
    }
    if (!sort) {
      return (
        <StyledTableCell {...headerProps}>
          <Typography component="span" variant="subtitle2" noWrap>
            {label}
          </Typography>
        </StyledTableCell>
      );
    }
    return (
      <StyledTableCell
        align="left"
        sortDirection={orderBy === key ? order : false}
      >
        <TableSortLabel
          active={orderBy === key}
          direction={order}
          onClick={(event) => {
            sortHandler(event, key);
          }}
        >
          <Typography variant="subtitle2" component="span" noWrap>
            {_.upperCase(label)}
          </Typography>
        </TableSortLabel>
      </StyledTableCell>
    );
  };
  /**
   *
   * @param label
   * @param key
   * @param search
   * @returns {React.Component}
   */
  const renderSearchCell = (label, key, search) =>
    search && (
      <InputBase
        placeholder="Search"
        onChange={handleSearchChange(key)}
        value={searchValue[key] || ''}
        fullWidth
      />
    );

  return useMemo(
    () => (
      <TableContainer component={Paper} color={color} elevation={0}>
        <Table size={size} stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {_.map(headers, ({ label, key, sort }, index) => (
                <Fragment
                  key={getUniqueKey(
                    `${tableName}_header_${label}_${key}`,
                    index,
                  )}
                >
                  {renderHeaderCell(label, key, sort)}
                </Fragment>
              ))}
            </TableRow>
            {searchCol && (
              <TableRow>
                {_.map(headers, ({ label, key, search }, index) => (
                  <TableCell
                    key={getUniqueKey(
                      `${tableName}_header_search_${label}_${key}`,
                      index,
                    )}
                    size="small"
                  >
                    {renderSearchCell(label, key, search)}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {tableData && tableData.length > 0 ? (
              tableData.map((row, i) => (
                <StyledTableRow
                  key={getUniqueKey(`${tableName}_row_${row.id}`, i)}
                >
                  {_.map(headers, ({ key, width = 'auto' }, index) => (
                    <Fragment
                      key={getUniqueKey(
                        `${tableName}_row_${i}_cell_${index}`,
                        index,
                      )}
                    >
                      {renderCell(row, key, width, i)}
                    </Fragment>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers?.length}>
                  <Box p={2} width={1}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      gutterBottom
                      align="center"
                    >
                      No Records Found!
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filterData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    ),
    [tableData],
  );
}

/**
 * propTypes of AppTable
 * @property tableName {string} isRequired
 * @property color {oneOf} (['primary', 'secondary', 'default'])
 * @property order {oneOf} (['asc', 'desc'])
 * @property orderBy {string} isRequired
 * @property data {array} isRequired
 * @property headers {arrayOf}
 * @property headers.key {string} isRequired
 * @property headers.label {string} isRequired
 * @property headers.search {bool} isRequired
 * @property headers.sort {bool} isRequired
 * @property searchCol {bool},
 * @property size {oneOf} (['small', 'medium']) isRequired
 * @property stickyHeader {bool}
 * @property rowsPerPageOptions {arrayOf} number isRequired
 * @property enableCellRender {bool}
 * @property render {object}
 * @property pagination {bool}
 */
AppTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.array,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      search: PropTypes.bool,
      sort: PropTypes.bool,
    }),
  ).isRequired,
  searchCol: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  stickyHeader: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  enableCellRender: PropTypes.bool,
  render: PropTypes.object,
  pagination: PropTypes.bool,
  headerProps: PropTypes.object,
  padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
};

/**
 * Default propTypes of AppTable
 * @property color {primary}
 * @property order {asc}
 * @property data {array} []
 * @property searchCol {false}
 * @property stickyHeader {true}
 * @property rowsPerPageOptions {array} [10, 25, 50]
 * @property enableCellRender {false}
 * @property render {object} {}
 * @property pagination {true}
 * @property padding {default}
 */
AppTable.defaultProps = {
  color: 'primary',
  order: 'asc',
  data: [],
  headers: {
    search: false,
    sort: false,
  },
  searchCol: false,
  stickyHeader: true,
  rowsPerPageOptions: [10, 25, 50],
  enableCellRender: false,
  render: {},
  pagination: true,
  padding: 'default',
  cellAlign: 'left',
};
