import doRequest from '../../api';
import {
  GET_LASTFOURMONTHS_LEADSCOUNT_API,
  GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API,
} from './constants';
import { APP_CONSTANTS } from '../../constants';

export const getLastFourMonthsLeadsCount = () => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}/lastfourmonths`,
    actionType: GET_LASTFOURMONTHS_LEADSCOUNT_API,
  };
  return dispatch(doRequest.get(config));
};

export const getLastFourMonthsQRCodeAppLeadsCount = () => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}/lastfourmonths/qrcodeapp`,
    actionType: GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API,
  };
  return dispatch(doRequest.get(config));
};
