import { createAPIActions } from '../../utils';

export const USER_INITIAL_STATE = {
  isFetching: false,
  userList: [],
  userAccessibleAgenciesList: [],
};

export const GET_USER_API = createAPIActions('get', 'user/api');
export const ADD_USER_API = createAPIActions('add', 'user/api');
export const ACTIVATE_USER_API = createAPIActions('update', 'user/id/api');
export const DEACTIVATE_USER_API = createAPIActions('update', 'user/id/api');
export const GET_AGENCIES_WITH_ACCESS_API = createAPIActions(
  'get',
  'agency/with/access/api',
);
export const USER_ACCESS_API = createAPIActions('update', 'user/id/api');
