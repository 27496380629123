import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { getUniqueKey } from '../../utils';

function calculateWidth(dropIcon, width) {
  if (isMobile) {
    return '100%';
  }
  return dropIcon ? width || '130px' : '100%';
}

const StyledSection = styled.section`
  width: ${(props) => calculateWidth(props.dropIcon, props.width)};
  margin-bottom: 16px;
  p {
    background-color: #dadada;
    min-height: 40px;
    box-sizing: border-box;
    padding: 16px;
  }
  .drop-icon-container {
    box-sizing: border-box;
    padding: ${(props) => props.theme.spacing(1)}px;
    border: 2px solid ${(props) => props.theme.palette.secondary.main};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    height: 130px;
    min-width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    > svg {
      font-size: 3.5rem;
      color: ${(props) => props.theme.palette.secondary.main};
      fill: ${(props) => props.theme.palette.secondary.main};
    }
    > img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
`;

function FileUploadComponent(props) {
  const { accept, dropText, dropIcon, selectedImageUrl } = props;
  const [file, setFile] = useState([]);
  return (
    <Dropzone
      accept={accept}
      onDrop={(acceptedFiles) => {
        setFile(acceptedFiles);
        props.onDrop(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <StyledSection dropIcon={dropIcon} width={props?.width}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {dropText && (
              <>
                <Typography variant="subtitle1" align="center" component="p">
                  {dropText}
                </Typography>
                <Typography variant="caption" align="center" component="p">
                  Drag and Drop file here or click to select the file.
                </Typography>
              </>
            )}
            {dropIcon && (
              <div className="drop-icon-container">
                {file && file.length > 0 ? (
                  file.map((fileObj, index) => (
                    <img
                      key={getUniqueKey(fileObj.name, index)}
                      src={URL.createObjectURL(fileObj)}
                      alt="selected files"
                    />
                  ))
                ) : (
                  <>
                    {selectedImageUrl ? (
                      <img src={selectedImageUrl} alt="selected" />
                    ) : (
                      <CloudUploadOutlinedIcon fontSize="large" />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </StyledSection>
      )}
    </Dropzone>
  );
}

export default FileUploadComponent;
