import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import * as _ from 'lodash';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormTextFieldComponent from '../AppTextField';
import { SelectComponent } from '../AppSelect';
import FormRadioGroupComponent from '../AppRadio';
import FormCheckBoxComponent from '../AppCheckBox';
import CriteriaGroupComponent from '../AppCriteriaGroup';
import AppMultiSelect from '../AppMultiSelect';
import PhoneFieldComponent from '../AppPhoneField';
import AppMultipleEmailInput from '../AppMultipleEmailInput';
import AppMultiplePhoneNumberInput from '../AppMultiplePhoneInput';
import AppSingleCheckbox from '../AppSingleCheckbox';

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row !important;
`;
const StyledFormControl = styled(FormControl)`
  width: calc(
    ${(props) => (isMobile ? 100 : props.width || 50)}% -
      ${(props) =>
        props.width && (props.width === 100 || isMobile) ? 0 : 16}px
  );
`;

class FormGroupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  representField(formField, value = '') {
    switch (formField.type) {
      case 'text':
      case 'color':
      case 'date':
      case 'datetime-local':
      case 'time':
      case 'password':
      case 'email':
      case 'number': {
        return (
          <FormTextFieldComponent
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
            handelKeyDown={this.props?.handelKeyDown}
          />
        );
      }
      case 'select': {
        return (
          <SelectComponent
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'multiSelect': {
        return (
          <AppMultiSelect
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'radio': {
        return (
          <FormRadioGroupComponent
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'checkbox': {
        return (
          <FormCheckBoxComponent
            key={formField.id}
            checked={value && value.checked ? value.checked : false}
            formField={formField}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'criteria': {
        return (
          <CriteriaGroupComponent
            formField={formField}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'phone': {
        return (
          <PhoneFieldComponent
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'multiEmail': {
        return (
          <AppMultipleEmailInput
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'multiPhone': {
        return (
          <AppMultiplePhoneNumberInput
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      case 'singleCheckbox': {
        return (
          <AppSingleCheckbox
            formField={formField}
            value={value}
            handleChange={this.props.handleChange}
          />
        );
      }
      default:
        return false;
    }
  }

  render() {
    const { formFields, values } = this.props;
    return (
      <StyledFormGroup row style={formFields.style}>
        {_.isArray(formFields.list)
          ? _.map(
              formFields.list,
              (formField) =>
                !formField?.hidden && (
                  <StyledFormControl
                    key={formField.id}
                    margin={formField.margin}
                    width={formField.width}
                    style={formField.style}
                  >
                    {this.representField(formField, values[formField.name])}
                  </StyledFormControl>
                ),
            )
          : Object.keys(formFields.list).map(
              (keyName) =>
                !formFields.list[keyName]?.hidden && (
                  <StyledFormControl
                    key={formFields.list[keyName].id}
                    margin={formFields.list[keyName].margin}
                    width={formFields.list[keyName].width}
                    style={formFields.list[keyName].style}
                  >
                    {this.representField(
                      formFields.list[keyName],
                      values[formFields.list[keyName].name],
                    )}
                  </StyledFormControl>
                ),
            )}
      </StyledFormGroup>
    );
  }
}

export default FormGroupComponent;
