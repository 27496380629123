import React, { Component } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: ${(props) => props.theme.spacing() / 2}px;
  margin-right: ${(props) => props.theme.spacing() / 2}px;
`;
const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => props.theme.palette.text.primary};
  padding: ${(props) => props.theme.spacing()}px;
`;
const StyledCheckBoxIcon = styled.span`
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 70%;
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size : 35)}px;
  height: ${(props) => (props.size ? props.size : 35)}px;
  background-position: center;
  padding: ${(props) => props.theme.spacing()}px;
  background-image: url(${(props) => props.imageUrl});
  background-color: ${(props) =>
    props.selected ? props.theme.palette.primary.main : 'transparent'};
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  color: ${(props) =>
    props.selected
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.fontSize - 2}px;
  text-align: center;
  line-height: 18px;
`;
const StyledIcon = styled(Icon)`
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing()}px;
`;

class FormCheckBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || false,
    };
  }

  getImageUrl = (urlName) =>
    urlName && this.state[urlName] ? this.state[urlName] : this.state.artsIcon;

  handleChange = (name, key, category) => (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const categoryValue = {
      key,
      checked: value,
      category,
    };
    this.props.handleChange(name, categoryValue);
    this.props.handleChange({
      key,
      name,
      value,
      error: !value,
      checked: value,
      category,
    });
    this.setState({ checked: value });
  };

  representCheckBoxIcon = (formField) => (selected) => {
    switch (formField.checkBoxIconType) {
      case 'icon': {
        return (
          <StyledCheckBoxIcon selected={selected} size={formField.size}>
            <StyledIcon fontSize="small" className={formField.iconClass} />
          </StyledCheckBoxIcon>
        );
      }
      case 'image': {
        return (
          <StyledCheckBoxIcon
            imageUrl={this.getImageUrl(formField.imageUrl)}
            selected={selected}
            size={formField.size}
          />
        );
      }
      case 'label': {
        return (
          <StyledCheckBoxIcon selected={selected} size={formField.size}>
            {formField.checkBoxIconLabel}
          </StyledCheckBoxIcon>
        );
      }
      default:
        return <StyledCheckBoxIcon selected={selected} size={formField.size} />;
    }
  };

  render() {
    const { formField, hidden } = this.props;
    return hidden ? null : (
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            name={formField.name}
            checked={this.state.checked}
            onChange={this.handleChange(
              formField.name,
              formField[formField.key],
              formField.category,
            )}
            value={formField.value}
            icon={this.representCheckBoxIcon(formField)()}
            checkedIcon={this.representCheckBoxIcon(formField)(true)}
          />
        }
        label={formField.label}
        labelPlacement={formField.labelPlacement}
      />
    );
  }
}

export default FormCheckBoxComponent;
