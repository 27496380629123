import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  GET_PAYMENT_METHOD_API,
  ADD_PAYMENT_METHOD_API,
  PAY_AGENCY_BY_ID_API,
  RESET_PAYMENT,
} from './constants';

// agency/{agencyId}/paymentmethod
export const getPaymentMethod = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.PAYMENT_METHOD}`,
    actionType: GET_PAYMENT_METHOD_API,
  };
  return dispatch(doRequest.get(config));
};

export const addPaymentMethod = (agencyId, token) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.PAYMENT_METHOD}`,
    actionType: ADD_PAYMENT_METHOD_API,
    data: { token: token?.id },
  };
  return dispatch(doRequest.post(config));
};

export const payAgency = (agencyId, paymentDetails) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/payment`,
    actionType: PAY_AGENCY_BY_ID_API,
    data: paymentDetails,
  };
  return dispatch(doRequest.post(config));
};

export const resetPayment = () => (dispatch) => {
  dispatch({ type: RESET_PAYMENT });
};
