import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import {
  StyledDataList,
  StyledDivider,
} from '../../packages/AppStyledComponents';
import { getGroupById } from '../../modules/Group/actions';

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  min-height: 100%;
`;

export default function GroupDetailsPage() {
  const [groupObj, setGroup] = useState({});
  const userType = useSelector((state) => state.User.userType);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { groupId } = params;

  useEffect(() => {
    dispatch(getGroupById(groupId)).then((resp) => {
      setGroup(resp.data);
    });
  }, [groupId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Group Details">
            {userType === 'AGENCY_ADMIN' && (
              <AppButton
                variant="text"
                color="primary"
                label="Edit Group"
                handleClick={() => {
                  history.push(
                    `${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_GROUP}/self`,
                  );
                }}
              />
            )}
            {userType === 'AGENCY_ADMIN_BASIC' && (
              <AppButton
                variant="text"
                color="primary"
                label="Edit Group"
                handleClick={() => {
                  history.push(
                    `${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/self`,
                  );
                }}
              />
            )}
            {userType === 'BOOMERS_ADMIN' && (
              <AppButton
                variant="text"
                color="primary"
                label="Back to Groups"
                handleClick={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
                }}
              />
            )}
          </AppPageTitle>
        </Grid>
        {groupObj.package_type !== 'BASIC' && (
          <Grid item xs={12} sm={4}>
            <StyledCard elevation={0}>
              <CardHeader
                title={
                  <Typography variant="subtitle1">Theme and Logo</Typography>
                }
              />
              <StyledDivider />
              <CardContent>
                <StyledDataList w={150}>
                  <li>
                    <span>Group Logo</span>
                    {groupObj?.logo_image_url && (
                      <img
                        style={{ maxWidth: '100%', width: 80, height: 'auto' }}
                        src={groupObj?.logo_image_url}
                        alt="group"
                      />
                    )}
                  </li>
                  <li>
                    <span>Primary Color</span>
                    <span
                      style={{
                        padding: 16,
                        backgroundColor: groupObj?.primary_site_color,
                      }}
                    >
                      {groupObj?.primary_site_color}
                    </span>
                  </li>
                  <li>
                    <span>Secondary Color</span>
                    <span
                      style={{
                        padding: 16,
                        backgroundColor: groupObj?.secondary_site_color,
                      }}
                    >
                      {groupObj?.secondary_site_color}
                    </span>
                  </li>
                </StyledDataList>
              </CardContent>
            </StyledCard>
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <StyledCard elevation={0}>
            <CardHeader
              title={
                <Typography variant="subtitle1">
                  Group Basic Information
                </Typography>
              }
            />
            <StyledDivider />
            <CardContent>
              <StyledDataList w={140}>
                <li>
                  <span>Name</span>
                  <span>{groupObj?.name}</span>
                </li>
                <li>
                  <span>UUID</span>
                  <span>{groupObj?.UUID}</span>
                </li>
                <li>
                  <span>Website</span>
                  <span>{groupObj?.group_website_url}</span>
                </li>
                <li>
                  <span>Address</span>
                  <span>{groupObj?.address}</span>
                </li>
                <li>
                  <span>City</span>
                  <span>{groupObj?.city}</span>
                </li>
                <li>
                  <span>State</span>
                  <span>{groupObj?.state}</span>
                </li>
                <li>
                  <span>Zip Code</span>
                  <span>{groupObj?.zip_postal_code}</span>
                </li>
              </StyledDataList>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard elevation={0}>
            <CardHeader
              title={
                <Typography variant="subtitle1">
                  Additional Information
                </Typography>
              }
            />
            <StyledDivider />
            <CardContent>
              <StyledDataList w={150}>
                {userType === 'AGENCY_ADMIN_BASIC' && (
                  <li>
                    <span>Group Type</span>
                    <span>
                      {groupObj?.group_type?.group_type.map((at) => `${at}, `)}
                    </span>
                  </li>
                )}
                <li>
                  <span>Service Provided</span>
                  {userType === 'AGENCY_ADMIN_BASIC' ? (
                    <span>
                      {groupObj?.service_provided?.service_provided.join(', ')}
                    </span>
                  ) : (
                    <span>{groupObj?.availability}</span>
                  )}
                </li>
                <li>
                  <span>Working Hours</span>
                  <span>
                    {groupObj?.office_hours_from} - {groupObj?.office_hours_to}
                  </span>
                </li>
                <li>
                  <span>Timezone</span>
                  <span>{groupObj?.office_hours_timezone}</span>
                </li>
                <li>
                  <span>Leads</span>
                  <span>{groupObj?.total_leads || 0}</span>
                </li>
                {userType !== 'AGENCY_ADMIN_BASIC' && (
                  <li>
                    <span>Caregivers</span>
                    <span>{groupObj?.total_active_caregivers || 0}</span>
                  </li>
                )}
                {userType === 'AGENCY_ADMIN_BASIC' && (
                  <>
                    <li>
                      <span>Affiliate</span>
                      <span>{groupObj?.affiliate}</span>
                    </li>
                    <li>
                      <span>Group Info</span>
                      <span>{groupObj?.description}</span>
                    </li>
                  </>
                )}
                {groupObj?.payment_option?.payment_option &&
                  groupObj?.payment_option?.payment_option.length > 0 && (
                    <li>
                      <span>Payment Options</span>
                      <span>
                        {groupObj?.payment_option?.payment_option.join(', ')}
                      </span>
                    </li>
                  )}
                {groupObj?.rates?.hourly_rate?.from &&
                  groupObj?.rates?.hourly_rate?.to && (
                    <li>
                      <span>Hourly Rate Range</span>
                      <span>{`$${groupObj?.rates?.hourly_rate?.from} - $${groupObj?.rates?.hourly_rate?.to}`}</span>
                    </li>
                  )}
                {groupObj?.rates?.livein_rate?.from &&
                  groupObj?.rates?.livein_rate?.to && (
                    <li>
                      <span>Live-In Rate Range</span>
                      <span>{`$${groupObj?.rates?.livein_rate?.from} - $${groupObj?.rates?.livein_rate?.to}`}</span>
                    </li>
                  )}
              </StyledDataList>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
