import styled from 'styled-components';
import {
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const MuiCardContent = styled(CardContent)`
  && {
    padding: 32px;
  }
  .accordion-summary-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
  }
  .accordion-summary-info-icon {
    font-size: 20px;
    margin-left: 16px;
  }
`;

export const MuiAccordion = withStyles({
  root: {
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

export const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 26,
    '&$expanded': {
      minHeight: 26,
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(AccordionSummary);

export const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
}))(AccordionDetails);
