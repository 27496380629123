import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import * as _ from 'lodash';
import Link from '@material-ui/core/Link';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router';
import Radio from '@material-ui/core/Radio';
import { AppButton } from '../../packages/AppButton';
import FormGroupComponent from '../../packages/AppFormGroup';
import AppAdapterLink from '../../packages/AppAdapterLink';
import FormFields from './FormField';
import { APP_CONSTANTS } from '../../constants';
import { convertTimeToAMPM, debounced, validateForm } from '../../utils';
import AppBasePageCard from '../../packages/AppBasePageCard';
import QontoStepIcon, { QontoConnector } from '../../packages/Stepper/Qonto';
import FileUploadComponent from '../../packages/AppFileUpload';
import InfoModal from '../../packages/AppModal/Info';
import { createAgency } from '../../modules/Agency/actions';
import { StyledLink } from '../../packages/AppLink';

function getSteps() {
  return ['Admin Info', 'Agency Info', 'Logo & Color', 'Office Info'];
}

class RegistrationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      heading: 'Agency Registration',
      jsonFormField: FormFields,
      formValue: {
        fromTime: '09:00',
        toTime: '17:00',
        serving_radius: '5',
        primaryColor: '#000',
        secondaryColor: '#000',
      },
      fieldError: {
        fromTime: false,
        toTime: false,
        serving_radius: false,
        primaryColor: false,
        secondaryColor: false,
      },
      requiredFields: [
        'firstName',
        'lastName',
        'email',
        'cellPhone',
        'agencyName',
        'agencyWebsite',
        'agencyAddress',
        'agencyCity',
        'agencyState',
        'agencyZip',
        'coverageCities',
        'serving_radius',
        'timeZone',
        't_and_c_read',
      ],
      stepWiseRequiredFields: {
        0: ['firstName', 'lastName', 'email', 'cellPhone'],
        1: [
          'agencyName',
          'agencyWebsite',
          'agencyAddress',
          'agencyCity',
          'agencyState',
          'agencyZip',
        ],
        2: [],
        3: ['coverageCities', 'serving_radius', 'timeZone', 't_and_c_read'],
      },
      stepWiseValidForm: {
        0: false,
        1: false,
        2: true,
        3: false,
      },
      validForm: false,
      loading: false,
      activeStep: 0,
      selectedFile: [],
      dialogTitle: '',
      dialogOpen: false,
      dialogMsg: '',
    };
    this.doFormValidation = debounced(400, () => {
      this.setState((prevState) => ({
        validForm: validateForm({ ...prevState }),
      }));
      this.setState((prevState) => ({
        stepWiseValidForm: {
          ...prevState.stepWiseValidForm,
          [prevState.activeStep]: validateForm({
            requiredFields:
              prevState.stepWiseRequiredFields[prevState.activeStep],
            fieldError: prevState.fieldError,
          }),
        },
      }));
    });
    this.steps = getSteps();
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.props.history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
    }
    this.updateFormFieldsWithMetaData();
  }

  componentDidUpdate() {
    this.updateFormFieldsWithMetaData();
  }

  updateFormFieldsWithMetaData() {
    const { timezones, availability, paymentOption } = this.props;
    if (
      timezones.length > 0 &&
      availability.length > 0 &&
      paymentOption.length > 0
    ) {
      if (_.get(FormFields, '[3].list[4].values.length') === 0) {
        _.forEach(availability, (avl) => {
          FormFields[3].list[4].values.push({ label: avl, value: avl });
        });
      }
      if (_.get(FormFields, '[3].list[2].values.length') === 0) {
        _.forEach(timezones, (tz) => {
          FormFields[3].list[2].values.push({ label: tz, value: tz });
        });
      }
      if (_.get(FormFields, '[1].list[6].values.length') === 0) {
        _.forEach(paymentOption, (po) => {
          FormFields[1].list[6].values.push({ label: po, value: po });
        });
      }
      this.setState({ jsonFormField: FormFields });
    }
  }

  handleNext = () => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleChange = ({ name, value, error }) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        formValue: {
          ...prevState.formValue,
          [name]: value,
        },
        fieldError: {
          ...prevState.fieldError,
          [name]: error,
        },
      }),
      () => {
        this.doFormValidation();
      },
    );
  };

  formSubmitHandler = () => {
    this.setState({ loading: true, validForm: false });
    const {
      formValue: {
        firstName,
        lastName,
        email,
        cellPhone,
        agencyName,
        agencyWebsite,
        agencyAddress,
        agencyCity,
        agencyState,
        agencyZip,
        primaryColor,
        secondaryColor,
        fromTime,
        toTime,
        timeZone,
        coverageCities,
        serviceProvided,
        serving_radius,
        t_and_c_read,
        hourly_rate_from,
        hourly_rate_to,
        liveIn_rate_from,
        liveIn_rate_to,
        payment_option,
      },
      selectedFile,
    } = this.state;

    const formData = new FormData();

    formData.append('agency_logo', selectedFile[0]);
    formData.append(
      'agency_and_agency_admin',
      new Blob(
        [
          JSON.stringify({
            agency_and_agency_admin: {
              agency: {
                name: agencyName,
                address: agencyAddress,
                city: agencyCity,
                state: agencyState,
                zip_postal_code: agencyZip,
                t_and_c_accepted_date: new Date().toISOString(),
                primary_site_color: primaryColor,
                secondary_site_color: secondaryColor,
                logo_name: selectedFile[0]?.name,
                agency_website_url: agencyWebsite,
                office_hours_from: convertTimeToAMPM(fromTime),
                office_hours_to: convertTimeToAMPM(toTime),
                office_hours_timezone: timeZone,
                coverage_area_cities: coverageCities,
                availability: serviceProvided,
                serving_radius,
                rates: {
                  hourly_rate: {
                    from: hourly_rate_from,
                    to: hourly_rate_to,
                  },
                  livein_rate: {
                    from: liveIn_rate_from,
                    to: liveIn_rate_to,
                  },
                },
                payment_option: { payment_option },
              },
              agency_admin: {
                login_id: email,
                first_name: firstName,
                last_name: lastName,
                t_and_c_read,
                email,
                cell_phone: cellPhone,
              },
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );

    this.props
      .createAgency(formData)
      .then((resp) => {
        const {
          data: { error, message },
        } = resp;
        if (error) {
          this.setState({
            dialogTitle: 'Error while register!',
            dialogOpen: true,
            dialogMsg: message,
            loading: false,
            validForm: true,
          });
        } else {
          this.setState({
            dialogTitle: 'Agency registered successfully!',
            dialogOpen: true,
            dialogMsg:
              'Thank you for registering your agency. Please check your email for a message from AidQuest which includes a temporary password for your log in.',
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ loading: false, validForm: true });
      });
  };

  onDropFileAction = (data) => {
    this.setState({ selectedFile: data });
  };

  render() {
    const {
      jsonFormField,
      heading,
      formValue,
      validForm,
      loading,
      activeStep,
      dialogTitle,
      dialogOpen,
      dialogMsg,
      stepWiseValidForm,
    } = this.state;
    return (
      <>
        <AppBasePageCard
          title={[
            'Hello!',
            'Welcome to AidQuest.',
            'Please enter your agency info to register.',
          ]}
          md={5}
        >
          <CardHeader title={heading} className="card-heading" />
          <CardContent className="card-content" style={{ width: '100%' }}>
            <Stepper
              style={{ padding: isMobile ? 0 : 8 }}
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {this.steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <form
              noValidate
              autoComplete="off"
              name="signInForm"
              className="form-container"
            >
              {activeStep === 2 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mb={2}
                >
                  <Typography gutterBottom variant="subtitle1">
                    Upload Logo
                  </Typography>
                  <FileUploadComponent
                    style={{ marginBottom: 16 }}
                    accept=".png, .jpg, .jpeg"
                    onDrop={this.onDropFileAction}
                    dropIcon
                  />
                </Box>
              )}
              <FormGroupComponent
                handleChange={this.handleChange}
                formFields={jsonFormField[activeStep]}
                values={formValue}
              />
              {activeStep === this.steps.length - 1 ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Radio
                      name="t_and_c_read"
                      value={formValue?.t_and_c_read}
                      type="checkbox"
                      id="t_and_c_read-id"
                      required
                      checked={formValue?.t_and_c_read === true}
                      onChange={(event) => {
                        const { target } = event;
                        const value =
                          target.type === 'checkbox'
                            ? target.checked
                            : target.value;
                        this.handleChange({
                          name: 't_and_c_read',
                          value,
                          error: !value,
                        });
                      }}
                      inputProps={{ 'aria-label': 'TERMS AND CONDITION' }}
                    />
                    <Typography variant="body2">
                      I have read the{' '}
                      <StyledLink
                        href="https://aidquest.com/termsandconditions/"
                        target="_blank"
                      >
                        Terms & Conditions
                      </StyledLink>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={3}
                    mb={1}
                  >
                    <AppButton
                      label="Back"
                      disabled={activeStep === 0}
                      handleClick={this.handleBack}
                    />
                    <AppButton
                      marginleft={1}
                      variant="contained"
                      color="primary"
                      disabled={!validForm}
                      label="Sign Up"
                      loading={loading}
                      loaderSize={24}
                      handleClick={this.formSubmitHandler}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pt={3}
                  mb={1}
                >
                  <AppButton
                    label="Back"
                    disabled={activeStep === 0}
                    handleClick={this.handleBack}
                  />
                  <AppButton
                    marginleft={1}
                    label="Next"
                    variant="contained"
                    color="primary"
                    disabled={!stepWiseValidForm[activeStep]}
                    handleClick={this.handleNext}
                  />
                </Box>
              )}
            </form>
            <Typography variant="subtitle1" align="center">
              Already an AidQuest member?
              <Link
                component={AppAdapterLink}
                to={APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN}
                color="primary"
                style={{ marginLeft: 8 }}
              >
                Sign-in here.
              </Link>
            </Typography>
            {/* <Typography variant="subtitle1" align="center">
              <Link
                component={AppAdapterLink}
                to={APP_CONSTANTS.BASE_ROUTE_URLS.HOME}
                color="primary"
              >
                Back to Home
              </Link>
            </Typography> */}
          </CardContent>
        </AppBasePageCard>
        <InfoModal
          title={dialogTitle}
          isOpen={dialogOpen}
          onClose={() => {
            this.setState({
              dialogTitle: '',
              dialogOpen: false,
              dialogMsg: '',
            });
          }}
        >
          {dialogMsg}
        </InfoModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  timezones: state.MetaData.timezones,
  paymentOption: state.MetaData.paymentOption,
  availability: state.MetaData.availability,
  isAuthenticated: state.Session.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAgency,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegistrationPage),
);
