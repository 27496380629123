import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import * as _ from 'lodash';
import { AppContainer } from '../../packages/AppContainer';
import { AppButton } from '../../packages/AppButton';
import AppPageTitle from '../../packages/AppPageTitle';
import AppTable from '../../packages/AppTable';
import { TABLE_HEADER } from './constants';
import { APP_CONSTANTS } from '../../constants';
import { getAgenciesWithAccess } from '../../modules/AgencyUsers/action';
import { ToggleUserAccess } from '../../components/UserTableAction';

export default function AgencyUserAccess() {
  const dispatch = useDispatch();
  const history = useHistory();
  const agencyList = useSelector(
    (state) => state.AgencyUsers.userAccessibleAgenciesList,
  );
  const userType = useSelector((state) => state.User.userType);
  const [userId, firstName, lastName] = history.location.state;

  useEffect(() => {
    dispatch(getAgenciesWithAccess(userId));
  }, []);

  return useMemo(
    () => (
      <>
        <AppContainer>
          <Grid
            alignItems="flex-start"
            justifyContent="center"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle
                title={`Manage Permissions for ${_.upperFirst(
                  firstName,
                )} ${_.upperFirst(lastName)}:`}
              >
                <Grid
                  alignItems="flex-start"
                  justify="space-between"
                  alignContent="stretch"
                  spacing={2}
                  container
                >
                  {userType === 'AGENCY_ADMIN_BASIC' && (
                    <Grid item>
                      <AppButton
                        variant="text"
                        color="primary"
                        label="Back to Users"
                        handleClick={() => {
                          history.push(APP_CONSTANTS.APP_ROUTE_URLS.USERS);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AppPageTitle>
            </Grid>
            <Grid item xl={5} lg={5} md={5} xs={12}>
              <AppTable
                tableName="user-accessible-agency-list-table"
                size="small"
                orderBy=""
                searchCol
                data={agencyList}
                headers={TABLE_HEADER}
                rowsPerPageOptions={[10, 15, 25, 35, 50]}
                enableCellRender
                render={{
                  access_status: (props) => <ToggleUserAccess {...props} />,
                }}
              />
            </Grid>
          </Grid>
        </AppContainer>
      </>
    ),
    [agencyList],
  );
}
