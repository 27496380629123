export const GROUP_LIST_TABLE_HEADERS = [
  { label: 'name', key: 'name', search: true, sort: true },
  {
    label: 'type',
    key: 'type',
    search: true,
    sort: true,
  },
  { label: 'Agency Count', key: 'agency_count', search: true, sort: true },
  {
    label: 'ACTIONS',
    key: 'action',
    search: false,
    sort: false,
    width: '80px',
  },
];

// {
//   "agency_count": 3,
//   "id": "B1GRPh0mchvfeCtEvWK30dIubakgGWyT-K2wXczYVxlBynlo=**",
//   "name": "My Group",
//   "type": "REPORTING"
// }
