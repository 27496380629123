import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  ACTIVATE_USER_API,
  ADD_USER_API,
  DEACTIVATE_USER_API,
  GET_AGENCIES_WITH_ACCESS_API,
  GET_USER_API,
  USER_ACCESS_API,
} from './constants';

export const getUser = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.USER,
    actionType: GET_USER_API,
  };
  return dispatch(doRequest.get(config));
};

export const addUser = (userDetails) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.USER}`,
    actionType: ADD_USER_API,
    filterPayload: true,
    data: userDetails,
  };
  return dispatch(doRequest.post(config));
};

export const activateUser = (userId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.USER}/${userId}/activate`,
    actionType: ACTIVATE_USER_API,
  };
  return dispatch(doRequest.put(config));
};

export const deactivateUser = (userId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.USER}/${userId}/deactivate`,
    actionType: DEACTIVATE_USER_API,
  };
  return dispatch(doRequest.put(config));
};

export const getAgenciesWithAccess = (userId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.USER}/${userId}/agencyandaccess`,
    actionType: GET_AGENCIES_WITH_ACCESS_API,
  };
  return dispatch(doRequest.get(config));
};

export const userAccess = (userAndAgencyDetails) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.USER}/access`,
    actionType: USER_ACCESS_API,
    data: userAndAgencyDetails,
  };
  return dispatch(doRequest.put(config));
};
