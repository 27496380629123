import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import { AppContainer } from '../../packages/AppContainer';
import {
  convertTime12to24,
  convertTimeToAMPM,
  debounced,
  validateForm,
} from '../../utils';
import { defaultFieldError, requiredFields } from './constants';
import FormFields from './FormField';
import FormGroupComponent from '../../packages/AppFormGroup';
import {
  getAgency,
  getAgencyById,
  updateAgency,
} from '../../modules/Agency/actions';
import FileUploadComponent from '../../packages/AppFileUpload';
import AppCard from '../../packages/AppCard';

export default function UpdateAgencyPage() {
  const [formValue, setFormValue] = useState({
    payment_option: '',
  });
  const [fieldError, setFieldError] = useState({ ...defaultFieldError });
  const [validForm, setValidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [agencyObj, setAgency] = useState({});

  const userType = useSelector((state) => state.User.userType);
  const timezones = useSelector((state) => state.MetaData.timezones);
  const availability = useSelector((state) => state.MetaData.availability);
  const paymentOption = useSelector((state) => state.MetaData.paymentOption);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { agencyId } = params;

  const doFormValidation = debounced(400, () => {
    setValidForm(validateForm({ requiredFields, fieldError }));
  });
  const handleChange = ({ name, value, error }) => {
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
    setFieldError((prevState) => ({ ...prevState, [name]: error }));
  };
  const formSubmitHandler = () => {
    setLoading(true);
    setValidForm(false);
    const formData = new FormData();
    if (selectedFile && selectedFile.length > 0) {
      formData.append('agency_logo', selectedFile[0]);
    }
    formData.append(
      'agency_details',
      new Blob(
        [
          JSON.stringify({
            agency_details: {
              ...agencyObj,
              ...formValue,
              rates: {
                hourly_rate: {
                  from:
                    'hourly_rate_from' in formValue
                      ? formValue?.hourly_rate_from
                      : agencyObj?.rates?.hourly_rate.from,
                  to:
                    'hourly_rate_to' in formValue
                      ? formValue?.hourly_rate_to
                      : agencyObj?.rates?.hourly_rate.to,
                },
                livein_rate: {
                  from:
                    'liveIn_rate_from' in formValue
                      ? formValue?.liveIn_rate_from
                      : agencyObj?.rates?.livein_rate.from,
                  to:
                    'liveIn_rate_to' in formValue
                      ? formValue?.liveIn_rate_to
                      : agencyObj?.rates?.livein_rate.to,
                },
              },
              payment_option: {
                payment_option:
                  'payment_option' in formValue
                    ? formValue?.payment_option
                    : agencyObj?.payment_option?.payment_option,
              },
              logo_name:
                selectedFile && selectedFile.length > 0
                  ? selectedFile[0].name
                  : agencyObj?.logo_name || '',
              t_and_c_accepted_date: agencyObj?.terms_and_conditon_accepted_date
                ? new Date(agencyObj?.terms_and_conditon_accepted_date)
                : '',
              office_hours_from: formValue?.office_hours_from
                ? convertTimeToAMPM(formValue?.office_hours_from)
                : agencyObj.office_hours_from,
              office_hours_to: formValue?.office_hours_to
                ? convertTimeToAMPM(formValue?.office_hours_to)
                : agencyObj.office_hours_to,
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    dispatch(updateAgency(agencyId, formData))
      .then((resp) => {
        if (resp?.data?.success) {
          dispatch(getAgency());
        }
        setValidForm(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setValidForm(true);
      });
  };

  useEffect(() => {
    doFormValidation();
  }, [formValue]);
  useEffect(() => {
    if (
      _.get(FormFields, '[0].list.office_hours_timezone.values.length') === 0
    ) {
      _.forEach(timezones, (tz) => {
        FormFields[0].list.office_hours_timezone.values.push({
          label: tz,
          value: tz,
        });
      });
    }
    if (_.get(FormFields, '[0].list.availability.values.length') === 0) {
      _.forEach(availability, (avl) => {
        FormFields[0].list.availability.values.push({ label: avl, value: avl });
      });
    }
    if (_.get(FormFields, '[0].list.payment_option.values.length') === 0) {
      _.forEach(paymentOption, (po) => {
        FormFields[0].list.payment_option.values.push({ label: po, value: po });
      });
    }
  }, [timezones, availability, paymentOption]);
  useEffect(() => {
    if (userType === 'AGENCY_ADMIN') {
      FormFields[0].list.UUID.disabled = true;
    }
  }, [userType]);
  useEffect(() => {
    dispatch(getAgencyById(agencyId)).then((resp) => {
      setAgency(resp.data);
    });
  }, [agencyId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Update Agency">
            <AppButton
              variant="text"
              color="primary"
              label="Back to Agencies"
              handleClick={() => {
                history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
              }}
            />
          </AppPageTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid
            direction="column"
            alignItems="center"
            justify="center"
            alignContent="center"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppCard
                content={
                  <>
                    <FormGroupComponent
                      handleChange={handleChange}
                      formFields={FormFields[0]}
                      values={{
                        ...agencyObj,
                        payment_option:
                          agencyObj?.payment_option?.payment_option,
                        hourly_rate_from: agencyObj?.rates?.hourly_rate?.from,
                        hourly_rate_to: agencyObj?.rates?.hourly_rate?.to,
                        liveIn_rate_from: agencyObj?.rates?.livein_rate?.from,
                        liveIn_rate_to: agencyObj?.rates?.livein_rate?.to,
                        office_hours_to: convertTime12to24(
                          agencyObj.office_hours_to,
                        ),
                        office_hours_from: convertTime12to24(
                          agencyObj.office_hours_from,
                        ),
                      }}
                    />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div>
                        <Typography gutterBottom variant="subtitle1">
                          Upload Logo
                        </Typography>
                        <FileUploadComponent
                          selectedImageUrl={agencyObj.logo_image_url}
                          style={{ marginBottom: 16 }}
                          width={220}
                          accept=".png, .jpg, .jpeg"
                          onDrop={(data) => setSelectedFile(data)}
                          dropIcon
                        />
                      </div>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <AppButton
                          label="Cancel"
                          loaderColor="secondary"
                          handleClick={() =>
                            history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES)
                          }
                        />
                        <AppButton
                          marginleft={1}
                          variant="contained"
                          color="primary"
                          loaderColor="secondary"
                          label="Update Agency"
                          disabled={!validForm}
                          loading={loading}
                          loaderSize={24}
                          handleClick={formSubmitHandler}
                        />
                      </Box>
                    </Box>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
