export const TABLE_HEADER = [
  {
    label: 'agency_name',
    key: 'agency_name',
    search: true,
    sort: true,
  },
  {
    label: 'READ ONLY ACCESS',
    key: 'access_status',
    search: false,
    sort: true,
    width: '40px',
  },
];
