import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  ADD_CAREGIVERS_API,
  GET_ALL_CAREGIVERS_API,
  GET_CAREGIVER_BY_ID_API,
  UPDATE_CAREGIVERS_API,
  UPLOAD_CAREGIVERS_PROFILE_API,
  UPLOAD_CAREGIVERS_PROFILE_PROCESS_AND_MERGE_API,
} from './constants';

export const getAllCaregivers = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.CAREGIVERS,
    actionType: GET_ALL_CAREGIVERS_API,
  };
  return dispatch(doRequest.get(config));
};

export const getCaregiversByAgencyId = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.CAREGIVERS}`,
    actionType: GET_ALL_CAREGIVERS_API,
  };
  return dispatch(doRequest.get(config));
};

export const addCaregiver = (caregiver) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.CAREGIVERS,
    actionType: ADD_CAREGIVERS_API,
    formData: true,
    data: caregiver,
  };
  return dispatch(doRequest.post(config));
};

export const updateCaregiver = (caregiver, caregiverID) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.CAREGIVERS}/${caregiverID}`,
    actionType: UPDATE_CAREGIVERS_API,
    formData: true,
    data: caregiver,
  };
  return dispatch(doRequest.put(config));
};

export const activateCaregiverProfile = (caregiverID) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.CAREGIVERS}/${caregiverID}/activate`,
    actionType: UPDATE_CAREGIVERS_API,
  };
  return dispatch(doRequest.put(config));
};

export const deactivateCaregiverProfile = (caregiverID) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.CAREGIVERS}/${caregiverID}/deactivate`,
    actionType: UPDATE_CAREGIVERS_API,
  };
  return dispatch(doRequest.put(config));
};

export const uploadCaregiverData = (caregiversData) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.UPLOAD_CAREGIVERS_PROFILE,
    actionType: UPLOAD_CAREGIVERS_PROFILE_API,
    formData: true,
    data: caregiversData,
  };
  return dispatch(doRequest.post(config));
};

export const uploadCaregiverProfileProcessAndMerge = (caregivers) => (
  dispatch,
) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.UPLOAD_CAREGIVERS_PROFILE_PROCESS_AND_MERGE,
    actionType: UPLOAD_CAREGIVERS_PROFILE_PROCESS_AND_MERGE_API,
    formData: true,
    data: caregivers,
  };
  return dispatch(doRequest.post(config));
};

export const getCaregiverByID = (caregiverID) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.CAREGIVERS}/${caregiverID}`,
    actionType: GET_CAREGIVER_BY_ID_API,
  };
  return dispatch(doRequest.get(config));
};
