import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import ListItemComponent from '../AppListItem';
import { APP_CONSTANTS } from '../../constants';
import { AppContainer, AppRow } from '../AppContainer';
import ListComponent from '../AppList';
import LinkComponent from '../AppLink';

const StyledLink = styled(LinkComponent)`
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    white-space: initial;
    width: 100%;
  }
`;

const StyledListComponent = styled(ListComponent)`
  margin-left: -${(props) => props.theme.spacing() * 2}px;
  > li {
    padding: 0;
    margin-left: ${(props) => props.theme.spacing()}px;
    > a {
      padding: ${(props) => props.theme.spacing()}px;
      display: block;
    }
  }
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    margin-left: -${(props) => props.theme.spacing()}px;
    width: 100%;
    > li {
      margin: 0;
    }
  }
`;

const StyledAppRow = styled(AppRow)`
  min-height: ${(props) => props.theme.spacing() * 7}px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-content: center;
  align-items: center;
  align-self: center;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    padding: ${(props) => props.theme.spacing() * 2}px 0;
  }
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  height: auto;
  min-height: auto;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const Copyright = styled(Typography)`
  display: block;
  padding: ${(props) => props.theme.spacing()}px 0
    ${(props) => props.theme.spacing()}px ${(props) => props.theme.spacing()}px;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    margin-left: -${(props) => props.theme.spacing()}px;
  }
`;

class AppFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  nextPath = (path) => {
    this.props.history.push(path);
  };

  render() {
    return (
      <StyledBottomNavigation>
        <AppContainer>
          <StyledAppRow>
            <StyledListComponent>
              {APP_CONSTANTS.APP_FOOTER_LINK
                ? APP_CONSTANTS.APP_FOOTER_LINK.map((linkItem) =>
                    linkItem.visible ? (
                      <ListItemComponent key={linkItem.key}>
                        <StyledLink
                          fontSize={10}
                          path={linkItem.link}
                          title={linkItem.name}
                          activeclassname="active"
                          onClick={(e) =>
                            linkItem.active ? true : e.preventDefault()
                          }
                        />
                      </ListItemComponent>
                    ) : null,
                  )
                : null}
            </StyledListComponent>
            <Copyright noWrap component="span" variant="subtitle2">
              {`Copyright © ${new Date().getFullYear()} AidQuest. All Rights Reserved.`}
            </Copyright>
          </StyledAppRow>
        </AppContainer>
      </StyledBottomNavigation>
    );
  }
}

export default AppFooter;
