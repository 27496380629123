import { createAPIActions } from '../../utils';

export const DASHBOARD_INITIAL_STATE = {
  isFetching: false,
  lastFourMonthsLeads: {},
  lastFourMonthsQRCodeAppLeads: {},
};

export const GET_LASTFOURMONTHS_LEADSCOUNT_API = createAPIActions(
  'get',
  'leads/lastfourmonthscount/api',
);

export const GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API = createAPIActions(
  'get',
  'leads/lastfourmonthsqrcodeappcount/api',
);
