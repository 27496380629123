import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import FileUploadComponent from '../../packages/AppFileUpload';
import {
  uploadCaregiverData,
  uploadCaregiverProfileProcessAndMerge,
} from '../../modules/Caregivers/actions';

export default function UploadCaregiversPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [profileFile, setProfileFile] = useState([]);
  const [profileFileUploaded, setProfileFileUploaded] = useState(false);
  const [certificationFile, setCertificationFile] = useState([]);
  const [certificationFileUpld, setCertificationFileUpld] = useState(false);

  const handleProfileFileUploadAction = () => {
    const formData = new FormData();
    formData.append('caregivers', profileFile[0]);
    dispatch(uploadCaregiverData(formData)).then((resp) => {
      const {
        data: { success },
      } = resp;
      if (success) {
        setProfileFileUploaded(true);
        setProfileFile([]);
      }
    });
  };
  const handleCertificationFileUploadAction = () => {
    const formData = new FormData();
    formData.append('caregivers', certificationFile[0]);
    dispatch(uploadCaregiverData(certificationFile)).then((resp) => {
      const {
        data: { success },
      } = resp;
      if (success) {
        setCertificationFileUpld(true);
        setCertificationFile([]);
      }
    });
  };
  const handleProfileFileProcessAndMergeAction = () => {
    dispatch(uploadCaregiverProfileProcessAndMerge()).then((resp) => {
      const {
        data: { success },
      } = resp;
      if (success) {
        setProfileFileUploaded(false);
        setCertificationFileUpld(false);
      }
    });
  };

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Upload Caregivers">
            <AppButton
              variant="text"
              color="primary"
              label="Back to Caregivers"
              handleClick={() => {
                history.push(APP_CONSTANTS.APP_ROUTE_URLS.CARE_GIVERS);
              }}
            />
          </AppPageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={0}>
            <CardHeader title="Upload Profile" />
            <CardContent>
              <FileUploadComponent
                style={{ marginBottom: 16 }}
                accept=".xlx, .xlsx, .csv"
                onDrop={(data) => {
                  setProfileFile(data);
                }}
                dropText="Select Profile File"
              />
            </CardContent>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={2}
            >
              <AppButton
                label="Upload"
                variant="contained"
                color="primary"
                loaderColor="secondary"
                disabled={!(profileFile && profileFile.length > 0)}
                handleClick={handleProfileFileUploadAction}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={0}>
            <CardHeader title="Upload Certifications" />
            <CardContent>
              <FileUploadComponent
                style={{ marginBottom: 16 }}
                accept=".xlx, .xlsx, .csv"
                onDrop={(data) => {
                  setCertificationFile(data);
                }}
                dropText="Select Certifications File"
              />
            </CardContent>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={2}
            >
              <AppButton
                label="Upload"
                variant="contained"
                color="primary"
                loaderColor="secondary"
                disabled={!(certificationFile && certificationFile.length > 0)}
                handleClick={handleCertificationFileUploadAction}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <AppButton
            marginleft={1}
            variant="contained"
            color="primary"
            loaderColor="secondary"
            label="Process and Merge"
            disabled={!(profileFileUploaded && certificationFileUpld)}
            handleClick={handleProfileFileProcessAndMergeAction}
          />
        </Grid>
      </Grid>
    </AppContainer>
  );
}
