import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { withStyles } from '@material-ui/core/styles';
// import { StyledDivider } from '../../packages/AppStyledComponents';
import { AppButton } from '../../packages/AppButton';

const StyledCardContent = withStyles({
  root: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
})(CardContent);

export default forwardRef(
  ({ enableSubmit, pageType, newPaymentMethod }, ref) => {
    const payment = useSelector((state) => state.Payment);
    const stripe = useStripe();
    const elements = useElements();

    const [nameOnCard, setNameOnCard] = useState(null);
    const [cardError, setCardError] = useState({});
    const [updatePaymentMethod, setUpdatePayment] = useState(
      newPaymentMethod || false,
    );

    useImperativeHandle(ref, () => ({
      async showAlert() {
        const result = await handlePaymentMethodSubmit();
        const { error } = result;
        if (error) {
          setCardError(error);
        }
        return result;
      },
    }));

    const handleNameChange = (event) => {
      const {
        target: { value },
      } = event;
      setNameOnCard(value);
    };

    const handleCardChange = (event) => {
      setCardError({});
      if (nameOnCard && nameOnCard !== '' && event.complete) {
        enableSubmit(true);
      } else {
        enableSubmit(false);
      }
    };

    const handlePaymentMethodSubmit = async () => {
      const cardElement = elements.getElement(CardElement);

      const result = await stripe.createToken(cardElement, {
        name: nameOnCard,
      });

      return result;
    };

    useState(() => {
      if (newPaymentMethod) {
        setUpdatePayment(true);
      }
    }, [newPaymentMethod]);

    return useMemo(
      () => (
        <Grid item xs={12} md={12}>
          <Card elevation={0} style={{ height: '100%' }}>
            <StyledCardContent
              style={{ height: '100%', boxSizing: 'border-box' }}
            >
              <Box
                display="flex"
                flexDirection="column"
                height="calc(100% - 30px)"
                boxSizing="border-box"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {payment?.success && !updatePaymentMethod ? (
                  <>
                    <Typography variant="h5" align="center" gutterBottom>
                      Card Details
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                      {`Your ${payment.network_brand} Card ending in ${payment.ends_with}`}
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                      {`Expiry: ${payment?.expiry}`}
                    </Typography>
                    <Box mb={2} />
                    <AppButton
                      variant="contained"
                      color="primary"
                      label="Update Payment Method"
                      handleClick={() => {
                        setUpdatePayment(true);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{ justifyContent: 'center' }}
                    >
                      {!isMobile && (
                        <Grid item xs={6}>
                          <form name="AddCard" noValidate autoComplete="off">
                            <FormControl fullWidth margin="dense">
                              <TextField
                                id="name"
                                label="Name on card"
                                required
                                error={nameOnCard === ''}
                                onChange={handleNameChange}
                              />
                            </FormControl>
                            <Box mb={2} />
                            <FormControl fullWidth margin="dense">
                              <CardElement
                                onChange={handleCardChange}
                                options={{
                                  style: {
                                    base: {
                                      fontSize: '16px',
                                      color: '#424770',
                                      '::placeholder': {
                                        color: '#aab7c4',
                                      },
                                    },
                                    invalid: {
                                      color: '#9e2146',
                                    },
                                  },
                                }}
                              />
                              {cardError?.message && (
                                <FormHelperText error>
                                  {cardError?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <Box mb={2} />
                            {/* <FormControl fullWidth margin="dense">
                          <AppButton
                            disabled={
                              !stripe || !nameOnCard || nameOnCard === ''
                            }
                            variant="contained"
                            color="primary"
                            label="Save"
                            handleClick={handlePaymentMethodSubmit}
                            fullWidth
                          />
                        </FormControl> */}
                            {payment.success && (
                              <FormControl fullWidth margin="dense">
                                <AppButton
                                  variant="contained"
                                  color="inherit"
                                  label="Cancel"
                                  handleClick={() => {
                                    setUpdatePayment(false);
                                    setNameOnCard(null);
                                  }}
                                  fullWidth
                                />
                              </FormControl>
                            )}
                          </form>
                        </Grid>
                      )}
                      {isMobile && (
                        <Grid item xs={12}>
                          <form name="AddCard" noValidate autoComplete="off">
                            <FormControl fullWidth margin="dense">
                              <TextField
                                id="name"
                                label="Name on card"
                                required
                                error={nameOnCard === ''}
                                onChange={handleNameChange}
                              />
                            </FormControl>
                            <Box mb={2} />
                            <FormControl fullWidth margin="dense">
                              <CardElement
                                onChange={handleCardChange}
                                options={{
                                  style: {
                                    base: {
                                      fontSize: '16px',
                                      color: '#424770',
                                      '::placeholder': {
                                        color: '#aab7c4',
                                      },
                                    },
                                    invalid: {
                                      color: '#9e2146',
                                    },
                                  },
                                }}
                              />
                              {cardError?.message && (
                                <FormHelperText error>
                                  {cardError?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <Box mb={2} />
                            {/* <FormControl fullWidth margin="dense">
                        <AppButton
                          disabled={
                            !stripe || !nameOnCard || nameOnCard === ''
                          }
                          variant="contained"
                          color="primary"
                          label="Save"
                          handleClick={handlePaymentMethodSubmit}
                          fullWidth
                        />
                      </FormControl> */}
                            {payment.success && (
                              <FormControl fullWidth margin="dense">
                                <AppButton
                                  variant="contained"
                                  color="inherit"
                                  label="Cancel"
                                  handleClick={() => {
                                    setUpdatePayment(false);
                                    setNameOnCard(null);
                                  }}
                                  fullWidth
                                />
                              </FormControl>
                            )}
                          </form>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Box>
            </StyledCardContent>
          </Card>
        </Grid>
      ),
      [nameOnCard, cardError, payment, updatePaymentMethod, pageType],
    );
  },
);
