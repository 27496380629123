const FormFields = [
  {
    id: 'register-admin-page',
    title: '',
    type: 'register-admin-page',
    list: {
      contact_name: {
        name: 'contact_name',
        value: '',
        type: 'text',
        id: 'contact_name-id',
        label: 'Contact Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      contact_phone: {
        name: 'contact_phone',
        value: '',
        type: 'phone',
        id: 'contact_number-id',
        label: 'Contact Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '(XXX) XXX-XXXX',
        error: false,
        required: true,
        width: 33,
        regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
      },
      contact_email: {
        name: 'contact_email',
        value: '',
        type: 'email',
        id: 'contact_email-id',
        label: 'Contact Email',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 33,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },

      name: {
        name: 'name',
        value: '',
        type: 'text',
        id: 'agency-name-id',
        label: 'Agency Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      agency_website_url: {
        name: 'agency_website_url',
        value: '',
        type: 'text',
        id: 'agency-website-id',
        label: 'Agency Website',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },

      address: {
        name: 'address',
        value: '',
        type: 'text',
        id: 'agency-address-id',
        label: 'Agency Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      city: {
        name: 'city',
        value: '',
        type: 'text',
        id: 'agency-city-id',
        label: 'Agency City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      state: {
        name: 'state',
        value: '',
        type: 'select',
        id: 'agency-state-id',
        label: 'Agency State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 16.5,
        values: [
          {
            label: 'AL',
            value: 'AL',
          },
          {
            label: 'AK',
            value: 'AK',
          },
          {
            label: 'AS',
            value: 'AS',
          },
          {
            label: 'AZ',
            value: 'AZ',
          },
          {
            label: 'AR',
            value: 'AR',
          },
          {
            label: 'CA',
            value: 'CA',
          },
          {
            label: 'CO',
            value: 'CO',
          },
          {
            label: 'CT',
            value: 'CT',
          },
          {
            label: 'DE',
            value: 'DE',
          },
          {
            label: 'DC',
            value: 'DC',
          },
          {
            label: 'FM',
            value: 'FM',
          },
          {
            label: 'FL',
            value: 'FL',
          },
          {
            label: 'GA',
            value: 'GA',
          },
          {
            label: 'GU',
            value: 'GU',
          },
          {
            label: 'HI',
            value: 'HI',
          },
          {
            label: 'ID',
            value: 'ID',
          },
          {
            label: 'IL',
            value: 'IL',
          },
          {
            label: 'IN',
            value: 'IN',
          },
          {
            label: 'IA',
            value: 'IA',
          },
          {
            label: 'KS',
            value: 'KS',
          },
          {
            label: 'KY',
            value: 'KY',
          },
          {
            label: 'LA',
            value: 'LA',
          },
          {
            label: 'ME',
            value: 'ME',
          },
          {
            label: 'MH',
            value: 'MH',
          },
          {
            label: 'MD',
            value: 'MD',
          },
          {
            label: 'MA',
            value: 'MA',
          },
          {
            label: 'MI',
            value: 'MI',
          },
          {
            label: 'MN',
            value: 'MN',
          },
          {
            label: 'MS',
            value: 'MS',
          },
          {
            label: 'MO',
            value: 'MO',
          },
          {
            label: 'MT',
            value: 'MT',
          },
          {
            label: 'NE',
            value: 'NE',
          },
          {
            label: 'NV',
            value: 'NV',
          },
          {
            label: 'NH',
            value: 'NH',
          },
          {
            label: 'NJ',
            value: 'NJ',
          },
          {
            label: 'NM',
            value: 'NM',
          },
          {
            label: 'NY',
            value: 'NY',
          },
          {
            label: 'NC',
            value: 'NC',
          },
          {
            label: 'ND',
            value: 'ND',
          },
          {
            label: 'MP',
            value: 'MP',
          },
          {
            label: 'OH',
            value: 'OH',
          },
          {
            label: 'OK',
            value: 'OK',
          },
          {
            label: 'OR',
            value: 'OR',
          },
          {
            label: 'PW',
            value: 'PW',
          },
          {
            label: 'PA',
            value: 'PA',
          },
          {
            label: 'PR',
            value: 'PR',
          },
          {
            label: 'RI',
            value: 'RI',
          },
          {
            label: 'SC',
            value: 'SC',
          },
          {
            label: 'SD',
            value: 'SD',
          },
          {
            label: 'TN',
            value: 'TN',
          },
          {
            label: 'TX',
            value: 'TX',
          },
          {
            label: 'UT',
            value: 'UT',
          },
          {
            label: 'VT',
            value: 'VT',
          },
          {
            label: 'VI',
            value: 'VI',
          },
          {
            label: 'VA',
            value: 'VA',
          },
          {
            label: 'WA',
            value: 'WA',
          },
          {
            label: 'WV',
            value: 'WV',
          },
          {
            label: 'WI',
            value: 'WI',
          },
          {
            label: 'WY',
            value: 'WY',
          },
        ],
      },
      zip_postal_code: {
        name: 'zip_postal_code',
        value: '',
        type: 'text',
        id: 'agency-zip-id',
        label: 'Agency Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 16.5,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
      payment_option: {
        name: 'payment_option',
        value: [],
        type: 'multiSelect',
        id: 'payment_option',
        label: 'Payment Options',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: false,
        width: 40,
        values: [],
      },
      hourly_rate_from: {
        name: 'hourly_rate_from',
        value: '',
        type: 'number',
        id: 'hourly_rate_from',
        label: 'From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 15,
        startAdornment: '$',
        haveCommonField: true,
        commonFieldHeading: 'Hourly Rate Range',
      },
      hourly_rate_to: {
        name: 'hourly_rate_to',
        value: '',
        type: 'number',
        id: 'hourly_rate_to',
        label: 'To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 15,
        haveCommonField: true,
        startAdornment: '$',
        boxProps: {
          paddingTop: 3.75,
        },
      },
      liveIn_rate_from: {
        name: 'liveIn_rate_from',
        value: '',
        type: 'number',
        id: 'liveIn_rate_from',
        label: 'From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 15,
        haveCommonField: true,
        commonFieldHeading: 'Live-in Rate Range',
        startAdornment: '$',
      },
      liveIn_rate_to: {
        name: 'liveIn_rate_to',
        value: '',
        type: 'number',
        id: 'liveIn_rate_to',
        label: 'To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 15,
        haveCommonField: true,
        startAdornment: '$',
        boxProps: {
          paddingTop: 3.75,
        },
      },

      office_hours_from: {
        name: 'office_hours_from',
        value: '09:00',
        type: 'time',
        id: 'from-time-id',
        label: 'Office Hours From Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
      },
      office_hours_to: {
        name: 'office_hours_to',
        value: '17:00',
        type: 'time',
        id: 'to-time-id',
        label: 'Office Hours To Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
      },
      office_hours_timezone: {
        name: 'office_hours_timezone',
        value: '',
        type: 'select',
        id: 'time-zone-id',
        label: 'Time Zone',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        values: [],
      },

      coverage_area_cities: {
        name: 'coverage_area_cities',
        value: '',
        type: 'text',
        id: 'coverage-cities-id',
        label: 'Coverage Cities',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      availability: {
        name: 'availability',
        value: '',
        type: 'select',
        id: 'service-provided-id',
        label: 'Service Provided',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
        values: [],
      },
      serving_radius: {
        name: 'serving_radius',
        value: '5',
        type: 'select',
        id: 'serving_radius-id',
        label: 'Serving Radius',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        values: [
          {
            label: '5 miles',
            value: '5',
          },
          {
            label: '10 miles',
            value: '10',
          },
          {
            label: '20 miles',
            value: '20',
          },
          {
            label: '30 miles',
            value: '30',
          },
          {
            label: '50 miles',
            value: '50',
          },
          {
            label: '100 miles',
            value: '100',
          },
        ],
      },

      UUID: {
        name: 'UUID',
        value: '',
        type: 'text',
        id: 'agency-UUID-id',
        label: 'Agency UUID',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      primary_site_color: {
        name: 'primary_site_color',
        value: '',
        type: 'color',
        id: 'primary-color-id',
        label: 'Primary Color Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{4,8}\\b',
      },
      secondary_site_color: {
        name: 'secondary_site_color',
        value: '',
        type: 'color',
        id: 'secondary-color-id',
        label: 'Secondary Color Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{4,8}\\b',
      },
    },
  },
];

export default FormFields;
