export const BILLINGS_TABLE_HEADERS_AGENCY = [
  { label: 'Agency Name', key: 'agency_name', search: true, sort: true },
  { label: 'Billing Status', key: 'billing_status', search: true, sort: true },
  { label: 'Total Leads', key: 'leads_count', search: true, sort: true },
  {
    label: 'Total Billed Amount',
    key: 'total_billed_amount',
    search: true,
    sort: true,
  },
  { label: 'Discount', key: 'discount', search: true, sort: true },
  { label: 'Credits', key: 'credits', search: true, sort: true },
  {
    label: 'Invoice Pdf',
    key: 'invoice_pdf_url',
    search: false,
    sort: true,
  },
  { label: 'Report CSV', key: 'report_csv_url', search: false, sort: true },
  {
    label: 'Approve Bill',
    key: 'approved',
    search: true,
    sort: true,
  },
];

export const BILLINGS_TABLE_HEADERS_GROUP = [
  { label: 'Agency Name', key: 'agency_name', search: true, sort: true },
  { label: 'Group Name', key: 'group_name', search: true, sort: true },
  {
    label: 'Group Total Bill',
    key: 'group_total_amount',
    search: true,
    sort: true,
  },
  { label: 'Billing Status', key: 'billing_status', search: true, sort: true },
  { label: 'Total Leads', key: 'leads_count', search: true, sort: true },
  {
    label: 'Total Billed Amount',
    key: 'total_billed_amount',
    search: true,
    sort: true,
  },
  { label: 'Discount', key: 'discount', search: true, sort: true },
  { label: 'Credits', key: 'credits', search: true, sort: true },
  {
    label: 'Invoice Pdf',
    key: 'invoice_pdf_url',
    search: false,
    sort: true,
  },
  { label: 'Report CSV', key: 'report_csv_url', search: false, sort: true },
  {
    label: 'Approve Bill',
    key: 'approved',
    search: true,
    sort: true,
  },
];
