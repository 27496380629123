import React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const StyledButtonContainer = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  position: relative;
  margin-left: ${(props) =>
    props.theme.spacing() * (props.marginleft ? props.marginleft : 0)}px;
  margin-bottom: ${(props) =>
    props.theme.spacing() * (props.marginbottom ? props.marginbottom : 0)}px;
`;
const StyledButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;
const StyledIcon = styled(Icon)`
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing()}px;
  margin-left: ${(props) => props.theme.spacing()}px;
`;

const getIconClassNames = (label) => {
  let iconClassNames = null;
  const knownKeywords = [
    {
      label: 'save',
      iconClasses: 'far fa-save',
    },
    {
      label: 'update',
      iconClasses: 'far fa-save',
    },
    {
      label: 'cancel',
      iconClasses: 'fas fa-times',
    },
    {
      label: 'close',
      iconClasses: 'fas fa-times',
    },
    {
      label: 'add',
      iconClasses: 'fas fa-plus',
    },
    {
      label: 'send',
      iconClasses: 'far fa-paper-plane',
    },
    {
      label: 'visit',
      iconClasses: 'far fa-paper-plane',
    },
    {
      label: 'submit',
      iconClasses: 'far fa-paper-plane',
    },
    {
      label: 'search',
      iconClasses: 'fas fa-search',
    },
    {
      label: 'menu',
      iconClasses: 'fas fa-bars',
    },
    {
      label: 'delete',
      iconClasses: 'fas fa-trash',
    },
    {
      label: 'modify',
      iconClasses: 'far fa-edit',
    },
    {
      label: 'edit',
      iconClasses: 'far fa-edit',
    },
    {
      label: 'call',
      iconClasses: 'fas fa-phone-alt',
    },
    {
      label: 'ok',
      iconClasses: 'fas fa-check',
    },
    {
      label: 'create',
      iconClasses: 'fas fa-plus',
    },
    {
      label: 'pay',
      iconClasses: 'fas fa-dollar-sign',
    },
    {
      label: 'upload',
      iconClasses: 'fas fa-file-upload',
    },
    {
      label: 'refresh',
      iconClasses: 'fas fa-sync-alt',
    },
    {
      label: 'sync',
      iconClasses: 'fas fa-sync-alt',
    },
    {
      label: 'back',
      iconClasses: 'fas fa-arrow-left',
    },
    {
      label: 'next',
      iconClasses: 'fas fa-arrow-right',
    },
  ];
  // eslint-disable-next-line consistent-return
  _.forEach(knownKeywords, (item) => {
    if (_.includes(label, item.label)) {
      iconClassNames = item.iconClasses;
      return false;
    }
  });

  return iconClassNames;
};

export function AppButton(props) {
  const {
    color,
    fullWidth,
    variant,
    disabled,
    label,
    loading,
    loaderSize,
    iconClassNames,
    marginleft,
    marginbottom,
    loaderColor,
    style,
    size,
    withIcon = true,
    iconPosition = 'left',
  } = props;

  const [isUnmounted, setIsUnmounted] = React.useState(false);
  const iconClass = iconClassNames || getIconClassNames(_.toLower(label));

  // eslint-disable-next-line arrow-body-style
  React.useEffect(() => {
    return () => {
      setIsUnmounted(true);
    };
  }, []);

  const handleClick = (event) => {
    if (!isUnmounted && props.handleClick) {
      props.handleClick(event);
    }
  };

  const renderIcon = () => {
    if (withIcon && iconClass) {
      return <StyledIcon fontSize="small" className={iconClass} />;
    }
    return null;
  };

  return (
    <StyledButtonContainer
      marginleft={marginleft}
      marginbottom={marginbottom}
      fullWidth={fullWidth}
    >
      <Button
        fullWidth={fullWidth}
        onClick={handleClick}
        color={color}
        variant={variant}
        disabled={disabled}
        disableElevation
        style={style}
        size={size}
        startIcon={withIcon && iconPosition === 'left' && renderIcon()}
        endIcon={withIcon && iconPosition === 'right' && renderIcon()}
      >
        {label}
      </Button>
      {loading && !isUnmounted && (
        <StyledButtonCircularProgress size={loaderSize} color={loaderColor} />
      )}
    </StyledButtonContainer>
  );
}
