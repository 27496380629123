import React, { useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import AppTable from '../../packages/AppTable';
import { CAREGIVER_LIST_TABLE_HEADERS } from './constants';
import { APP_CONSTANTS } from '../../constants';
import {
  getAllCaregivers,
  getCaregiversByAgencyId,
} from '../../modules/Caregivers/actions';
import {
  CaregiversActions,
  CaregiversFirstName,
  CaregiversLastName,
} from '../../components/CaregiversTableAction';
import CaregiversTableActiveToggle from '../../components/CaregiversTableActiveToggle';
import { getAgencyById, setSelectedAgency } from '../../modules/Agency/actions';

function GetTableCustomRenderObj(userType) {
  const tableCustomRenderObj = {
    BOOMERS_ADMIN: {},
    AGENCY_ADMIN: {
      first_name: (props) => <CaregiversFirstName {...props} />,
      last_name: (props) => <CaregiversLastName {...props} />,
      action: (props) => <CaregiversActions {...props} />,
      status: (props) => <CaregiversTableActiveToggle {...props} />,
    },
  };
  return tableCustomRenderObj[userType];
}

export default function CaregiversPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { agencyId } = params;
  const caregiversList = useSelector(
    (state) => state.Caregivers.caregiversList,
  );
  const selectedAgency = useSelector((state) => state.Agency.selectedAgency);
  const userType = useSelector((state) => state.User.userType);
  const tableCustomRenderObj = GetTableCustomRenderObj(userType);
  const caregiverTableProps = {
    tableName: 'caregiver-list-table',
    size: 'small',
    orderBy: '',
    searchCol: true,
    data: caregiversList,
    headers: CAREGIVER_LIST_TABLE_HEADERS(userType),
    rowsPerPageOptions: [10, 15, 25, 35, 50],
    enableCellRender: true,
    render: tableCustomRenderObj,
  };

  useEffect(() => {
    if (agencyId) {
      dispatch(getCaregiversByAgencyId(agencyId));
    } else {
      dispatch(getAllCaregivers());
    }
    if (!(selectedAgency && 'agency_id' in selectedAgency)) {
      dispatch(getAgencyById(agencyId)).then((resp) => {
        const { data } = resp;
        dispatch(setSelectedAgency(data));
      });
    }
  }, []);

  return useMemo(
    () => (
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Caregivers">
              <Grid
                alignItems="flex-start"
                justify="space-between"
                alignContent="stretch"
                spacing={2}
                container
              >
                {userType === 'AGENCY_ADMIN' && (
                  <>
                    <Grid item>
                      <AppButton
                        variant="text"
                        color="primary"
                        label="Add"
                        handleClick={() => {
                          history.push(
                            APP_CONSTANTS.APP_ROUTE_URLS.ADD_CARE_GIVERS,
                          );
                        }}
                      />
                    </Grid>
                    {/* <Grid item>
                      <AppButton
                        variant="text"
                        color="primary"
                        label="Upload"
                        handleClick={() => {
                          history.push(
                            APP_CONSTANTS.APP_ROUTE_URLS.UPLOAD_CARE_GIVERS,
                          );
                        }}
                      />
                    </Grid> */}
                  </>
                )}
              </Grid>
            </AppPageTitle>
          </Grid>
          <Grid item xs={12}>
            <ErrorBoundary>
              <AppTable {...caregiverTableProps} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </AppContainer>
    ),
    [caregiversList],
  );
}
