const FormFields = [
  {
    id: 'personal-details-page',
    title: '',
    type: 'personal-details',
    list: [
      {
        name: 'firstName',
        value: '',
        type: 'text',
        id: 'first-name',
        label: 'First Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'lastName',
        value: '',
        type: 'text',
        id: 'last-name',
        label: 'Last Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'gender',
        value: '',
        type: 'select',
        id: 'gender-id',
        label: 'Gender',
        margin: 'normal',

        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        values: [
          {
            label: 'Male',
            value: 'MALE',
          },
          {
            label: 'Female',
            value: 'FEMALE',
          },
        ],
      },
      {
        name: 'yearsOfExp',
        value: '',
        type: 'number',
        id: 'years-of-exp',
        label: 'Years Of Experience',
        margin: 'normal',

        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[0-9]{1,16}\\b',
      },
      {
        name: 'availability',
        value: '',
        type: 'select',
        id: 'availability-id',
        label: 'Availability',
        margin: 'normal',

        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'language',
        value: [],
        type: 'multiSelect',
        id: 'language-id',
        label: 'Language',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'identity',
        value: [],
        type: 'multiSelect',
        id: 'identity-id',
        label: `ID's`,
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'insurance',
        value: [],
        type: 'multiSelect',
        id: 'insurance-id',
        label: 'Insurance',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'certificate',
        value: [],
        type: 'multiSelect',
        id: 'certificate-id',
        label: 'Certifications',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'skills',
        value: [],
        type: 'multiSelect',
        id: 'skills-id',
        label: 'Skills',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'pets',
        value: '',
        type: 'select',
        id: 'pets-id',
        label: 'Pets',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [
          {
            label: 'Okay With Pets',
            value: 'ALL',
          },
          {
            label: 'Okay With Cats',
            value: 'CATS',
          },
          {
            label: 'Okay With Dogs',
            value: 'DOGS',
          },
          {
            label: 'Not Okay With Pets',
            value: 'NONE',
          },
        ],
      },
      {
        name: 'smoker',
        value: '',
        type: 'select',
        id: 'smoker-id',
        label: 'Can Handle Smokers',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [
          {
            label: 'Yes',
            value: 'TRUE',
          },
          {
            label: 'No',
            value: 'FALSE',
          },
        ],
      },
    ],
  },
  {
    id: 'contact-details-page',
    title: '',
    type: 'contact-details',
    list: [
      {
        name: 'email',
        value: '',
        type: 'email',
        id: 'email-id',
        label: 'Email Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },
      {
        name: 'cellPhone',
        value: '',
        type: 'number',
        id: 'cellPhone-id',
        label: 'Phone Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        regExpValue: '\\b[0-9]{10}\\b',
      },
      {
        name: 'state',
        value: '',
        type: 'select',
        id: 'state-id',
        label: 'State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [
          {
            label: 'AL',
            value: 'AL',
          },
          {
            label: 'AK',
            value: 'AK',
          },
          {
            label: 'AS',
            value: 'AS',
          },
          {
            label: 'AZ',
            value: 'AZ',
          },
          {
            label: 'AR',
            value: 'AR',
          },
          {
            label: 'CA',
            value: 'CA',
          },
          {
            label: 'CO',
            value: 'CO',
          },
          {
            label: 'CT',
            value: 'CT',
          },
          {
            label: 'DE',
            value: 'DE',
          },
          {
            label: 'DC',
            value: 'DC',
          },
          {
            label: 'FM',
            value: 'FM',
          },
          {
            label: 'FL',
            value: 'FL',
          },
          {
            label: 'GA',
            value: 'GA',
          },
          {
            label: 'GU',
            value: 'GU',
          },
          {
            label: 'HI',
            value: 'HI',
          },
          {
            label: 'ID',
            value: 'ID',
          },
          {
            label: 'IL',
            value: 'IL',
          },
          {
            label: 'IN',
            value: 'IN',
          },
          {
            label: 'IA',
            value: 'IA',
          },
          {
            label: 'KS',
            value: 'KS',
          },
          {
            label: 'KY',
            value: 'KY',
          },
          {
            label: 'LA',
            value: 'LA',
          },
          {
            label: 'ME',
            value: 'ME',
          },
          {
            label: 'MH',
            value: 'MH',
          },
          {
            label: 'MD',
            value: 'MD',
          },
          {
            label: 'MA',
            value: 'MA',
          },
          {
            label: 'MI',
            value: 'MI',
          },
          {
            label: 'MN',
            value: 'MN',
          },
          {
            label: 'MS',
            value: 'MS',
          },
          {
            label: 'MO',
            value: 'MO',
          },
          {
            label: 'MT',
            value: 'MT',
          },
          {
            label: 'NE',
            value: 'NE',
          },
          {
            label: 'NV',
            value: 'NV',
          },
          {
            label: 'NH',
            value: 'NH',
          },
          {
            label: 'NJ',
            value: 'NJ',
          },
          {
            label: 'NM',
            value: 'NM',
          },
          {
            label: 'NY',
            value: 'NY',
          },
          {
            label: 'NC',
            value: 'NC',
          },
          {
            label: 'ND',
            value: 'ND',
          },
          {
            label: 'MP',
            value: 'MP',
          },
          {
            label: 'OH',
            value: 'OH',
          },
          {
            label: 'OK',
            value: 'OK',
          },
          {
            label: 'OR',
            value: 'OR',
          },
          {
            label: 'PW',
            value: 'PW',
          },
          {
            label: 'PA',
            value: 'PA',
          },
          {
            label: 'PR',
            value: 'PR',
          },
          {
            label: 'RI',
            value: 'RI',
          },
          {
            label: 'SC',
            value: 'SC',
          },
          {
            label: 'SD',
            value: 'SD',
          },
          {
            label: 'TN',
            value: 'TN',
          },
          {
            label: 'TX',
            value: 'TX',
          },
          {
            label: 'UT',
            value: 'UT',
          },
          {
            label: 'VT',
            value: 'VT',
          },
          {
            label: 'VI',
            value: 'VI',
          },
          {
            label: 'VA',
            value: 'VA',
          },
          {
            label: 'WA',
            value: 'WA',
          },
          {
            label: 'WV',
            value: 'WV',
          },
          {
            label: 'WI',
            value: 'WI',
          },
          {
            label: 'WY',
            value: 'WY',
          },
        ],
      },
      {
        name: 'city',
        value: '',
        type: 'text',
        id: 'city-id',
        label: 'City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'zip',
        value: '',
        type: 'text',
        id: 'zip-id',
        label: 'Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
    ],
  },
];

export default FormFields;
