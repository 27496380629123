import React, { useEffect, useMemo, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { StyledDivider } from '../../packages/AppStyledComponents';
import FormGroupComponent from '../../packages/AppFormGroup';
import FormFields from './FormField';
import { fieldErrorInitialState, requiredFields } from './constants';
import { debounced, validateForm } from '../../utils';
import { AppButton } from '../../packages/AppButton';

export default function ProfileForm(props) {
  const { loading, profile, onCancel = () => {}, onSubmit = () => {} } = props;

  const [formValue, setFormValue] = useState({});
  const [fieldError, setFieldError] = useState({
    ...fieldErrorInitialState,
  });
  const [validForm, setValidForm] = useState(false);

  const doFormValidation = debounced(400, () => {
    setValidForm(validateForm({ requiredFields, fieldError }));
  });
  const handleChange = ({ name, value, error }) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setFieldError((prevValue) => ({
      ...prevValue,
      [name]: error,
    }));
  };
  const formSubmitHandler = () => {
    onSubmit({
      // address: formValue?.address,
      phone_number: formValue?.cellNumber,
      // city: formValue?.city,
      contact_preference: formValue?.contactPreference,
      email: formValue?.email,
      first_name: formValue?.firstName,
      last_name: formValue?.lastName,
      // middle_initial: formValue?.middleInitial,
      // state: formValue?.state,
      // country: formValue?.country,
      // zip_postal_code: formValue?.zip,
    });
  };

  useEffect(() => {
    doFormValidation();
  }, [fieldError]);
  useEffect(() => {
    if (profile) {
      setValidForm(true);
      setFormValue(profile);
    }
  }, [profile]);

  return useMemo(
    () => (
      <form
        noValidate
        autoComplete="off"
        name="profileForm"
        className="form-container"
      >
        <Grid
          alignItems="center"
          justify="center"
          alignContent="center"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardContent>
                <Typography variant="subtitle1">Personal Details</Typography>
                <StyledDivider marginbottom={2} />
                <FormGroupComponent
                  handleChange={handleChange}
                  formFields={FormFields[0]}
                  values={formValue}
                />
              </CardContent>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={2}
              >
                <AppButton
                  label="Cancel"
                  loaderColor="secondary"
                  handleClick={onCancel}
                />
                <AppButton
                  marginleft={1}
                  variant="contained"
                  color="primary"
                  loaderColor="secondary"
                  label="Update Profile"
                  disabled={!validForm}
                  loading={loading}
                  loaderSize={24}
                  handleClick={formSubmitHandler}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
    ),
    [validForm, formValue],
  );
}
