import {
  PAYMENT_INITIAL_STATE,
  GET_PAYMENT_METHOD_API,
  ADD_PAYMENT_METHOD_API,
  RESET_PAYMENT,
} from './constants';

export function Reducer(state = PAYMENT_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PAYMENT_METHOD_API.REQUEST:
      return { ...state, isFetching: true };
    case ADD_PAYMENT_METHOD_API.SUCCESS:
    case GET_PAYMENT_METHOD_API.SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      };
    case GET_PAYMENT_METHOD_API.FAILURE:
      return { ...state, isFetching: false };
    case ADD_PAYMENT_METHOD_API.REQUEST:
      return { ...state, isFetching: true };
    case ADD_PAYMENT_METHOD_API.FAILURE:
      return { ...state, isFetching: false };
    case RESET_PAYMENT:
      return { ...PAYMENT_INITIAL_STATE };
    default:
      return state;
  }
}
