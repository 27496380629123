export const initialState = {
  formValue: {
    office_hours_from: '09:00',
    office_hours_to: '17:00',
  },
  fieldError: {
    office_hours_from: false,
    office_hours_to: false,
  },
  stepWiseValidForm: {
    0: false,
    1: false,
    2: false,
    3: false,
  },
  validForm: false,
  loading: false,
  activeStep: 0,
  validOptionalField: false,
  lastFieldNameChanged: '',
  paymentValid: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUE':
      return { ...state, formValue: { ...action.payload } };
    case 'UPDATE_FORM_VALUE':
      return {
        ...state,
        formValue: {
          ...state.formValue,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_LAST_FIELD_NAME_CHANGED_VALUE':
      return { ...state, lastFieldNameChanged: action.payload };
    case 'SET_FIELD_ERROR':
      return { ...state, fieldError: action.payload };
    case 'UPDATE_FIELD_ERROR':
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_VALID_FORM':
      return { ...state, validForm: action.payload };
    case 'SET_VALID_OPTIONAL_FIELD':
      return { ...state, validOptionalField: action.payload };
    case 'SET_PAYMENT_VALID':
      return { ...state, paymentValid: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ACTIVE_STEP':
      return { ...state, activeStep: action.payload };
    case 'SET_STEP_WISE_VALID_FORM':
      return {
        ...state,
        stepWiseValidForm: { ...state.stepWiseValidForm, ...action.payload },
      };
    default:
      throw new Error('Unknown action type');
  }
};
