import React, { useEffect, useRef, useState } from 'react';
import { lowerCase as _lowerCase } from 'lodash';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { getUniqueKey } from '../../utils';

const StyledMenuList = styled(MenuList)`
  .list-item-icon {
    min-width: 40px;
  }
`;

const IconList = {
  dashboard: <HomeOutlinedIcon fontSize="large" />,
  caregivers: <SupervisedUserCircleOutlinedIcon fontSize="large" />,
  leads: <PersonAddOutlinedIcon fontSize="large" />,
  agencies: <AccountBalanceOutlinedIcon fontSize="large" />,
};

function getIcon(name) {
  return IconList[_lowerCase(name)];
}

export default function AppMenu(props) {
  const { links } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" mr={2}>
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={open ? 'app-menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          aria-label="Menu"
          disableRipple
          disableFocusRipple
          size="small"
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Box mt={1}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <StyledMenuList
                    autoFocusItem={open}
                    id="app-menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {links.map((item, index) => (
                      <MenuItem
                        key={getUniqueKey(`${item.name}_${item.key}_`, index)}
                        onClick={(event) => {
                          handleClose(event);
                          history.push(item.link);
                        }}
                        dense
                      >
                        <ListItemIcon className="list-item-icon">
                          {getIcon(item.name)}
                        </ListItemIcon>
                        <Typography variant="subtitle1" noWrap>
                          {item.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </StyledMenuList>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  );
}
