import React, { Component } from 'react';
import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';

const StyledFormLabel = styled(FormLabel)`
  color: ${(props) => props.theme.palette.text.primary};
`;
const StyledRadio = styled(Radio)`
  color: ${(props) => props.theme.palette.common.black};
`;

class FormRadioGroupComponent extends Component {
  constructor(props) {
    super(props);
    const { formField, value } = props;
    this.state = {
      [formField.name]: value || '',
    };
  }

  handleRadioChange = (name) => (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.props.handleChange(name, value);
    this.setState({ [name]: value });
  };

  render() {
    const { formField, hidden } = this.props;
    return hidden ? null : (
      <FormGroup style={formField.radioGroupStyle}>
        <StyledFormLabel component="legend">{formField.label}</StyledFormLabel>
        <RadioGroup
          name={formField.name}
          row
          value={this.state[formField.name]}
          onChange={this.handleRadioChange(formField.name)}
        >
          {formField.values.map((eachRadio) => (
            <FormControlLabel
              key={eachRadio.id}
              value={eachRadio.value}
              control={<StyledRadio />}
              label={eachRadio.label}
            />
          ))}
        </RadioGroup>
      </FormGroup>
    );
  }
}

export default FormRadioGroupComponent;
