const FormFields = [
  {
    id: 'profile-form',
    title: '',
    type: 'profile-form',
    list: [
      {
        name: 'firstName',
        value: '',
        type: 'text',
        id: 'first-name',
        label: 'First Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'lastName',
        value: '',
        type: 'text',
        id: 'last-name',
        label: 'Last Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'email',
        value: '',
        type: 'email',
        id: 'email-id',
        label: 'Email Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },
      {
        name: 'cellNumber',
        value: '',
        type: 'phone',
        id: 'cellPhone-id',
        label: 'Phone Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '(XXX) XXX-XXXX',
        required: true,
        error: false,
        width: 50,
        length: 13,
        regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
      },
      // {
      //   name: 'address',
      //   value: '',
      //   type: 'text',
      //   id: 'address',
      //   label: 'Address',
      //   margin: 'normal',
      //   fullWidth: true,
      //   multiline: true,
      //   rows: 2,
      //   placeholder: '',
      //   required: true,
      //   error: false,
      //   width: 100,
      //   regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      // },
      // {
      //   name: 'city',
      //   value: '',
      //   type: 'text',
      //   id: 'city',
      //   label: 'City',
      //   margin: 'normal',
      //   fullWidth: true,
      //   multiline: false,
      //   placeholder: '',
      //   required: true,
      //   error: false,
      //   width: 33,
      //   regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      // },
      // {
      //   name: 'state',
      //   value: '',
      //   type: 'select',
      //   id: 'state-id',
      //   label: 'State',
      //   margin: 'normal',
      //   fullWidth: true,
      //   multiline: false,
      //   placeholder: '',
      //   required: true,
      //   error: false,
      //   width: 33,
      //   values: [
      //     {
      //       label: 'AL',
      //       value: 'AL',
      //     },
      //     {
      //       label: 'AK',
      //       value: 'AK',
      //     },
      //     {
      //       label: 'AS',
      //       value: 'AS',
      //     },
      //     {
      //       label: 'AZ',
      //       value: 'AZ',
      //     },
      //     {
      //       label: 'AR',
      //       value: 'AR',
      //     },
      //     {
      //       label: 'CA',
      //       value: 'CA',
      //     },
      //     {
      //       label: 'CO',
      //       value: 'CO',
      //     },
      //     {
      //       label: 'CT',
      //       value: 'CT',
      //     },
      //     {
      //       label: 'DE',
      //       value: 'DE',
      //     },
      //     {
      //       label: 'DC',
      //       value: 'DC',
      //     },
      //     {
      //       label: 'FM',
      //       value: 'FM',
      //     },
      //     {
      //       label: 'FL',
      //       value: 'FL',
      //     },
      //     {
      //       label: 'GA',
      //       value: 'GA',
      //     },
      //     {
      //       label: 'GU',
      //       value: 'GU',
      //     },
      //     {
      //       label: 'HI',
      //       value: 'HI',
      //     },
      //     {
      //       label: 'ID',
      //       value: 'ID',
      //     },
      //     {
      //       label: 'IL',
      //       value: 'IL',
      //     },
      //     {
      //       label: 'IN',
      //       value: 'IN',
      //     },
      //     {
      //       label: 'IA',
      //       value: 'IA',
      //     },
      //     {
      //       label: 'KS',
      //       value: 'KS',
      //     },
      //     {
      //       label: 'KY',
      //       value: 'KY',
      //     },
      //     {
      //       label: 'LA',
      //       value: 'LA',
      //     },
      //     {
      //       label: 'ME',
      //       value: 'ME',
      //     },
      //     {
      //       label: 'MH',
      //       value: 'MH',
      //     },
      //     {
      //       label: 'MD',
      //       value: 'MD',
      //     },
      //     {
      //       label: 'MA',
      //       value: 'MA',
      //     },
      //     {
      //       label: 'MI',
      //       value: 'MI',
      //     },
      //     {
      //       label: 'MN',
      //       value: 'MN',
      //     },
      //     {
      //       label: 'MS',
      //       value: 'MS',
      //     },
      //     {
      //       label: 'MO',
      //       value: 'MO',
      //     },
      //     {
      //       label: 'MT',
      //       value: 'MT',
      //     },
      //     {
      //       label: 'NE',
      //       value: 'NE',
      //     },
      //     {
      //       label: 'NV',
      //       value: 'NV',
      //     },
      //     {
      //       label: 'NH',
      //       value: 'NH',
      //     },
      //     {
      //       label: 'NJ',
      //       value: 'NJ',
      //     },
      //     {
      //       label: 'NM',
      //       value: 'NM',
      //     },
      //     {
      //       label: 'NY',
      //       value: 'NY',
      //     },
      //     {
      //       label: 'NC',
      //       value: 'NC',
      //     },
      //     {
      //       label: 'ND',
      //       value: 'ND',
      //     },
      //     {
      //       label: 'MP',
      //       value: 'MP',
      //     },
      //     {
      //       label: 'OH',
      //       value: 'OH',
      //     },
      //     {
      //       label: 'OK',
      //       value: 'OK',
      //     },
      //     {
      //       label: 'OR',
      //       value: 'OR',
      //     },
      //     {
      //       label: 'PW',
      //       value: 'PW',
      //     },
      //     {
      //       label: 'PA',
      //       value: 'PA',
      //     },
      //     {
      //       label: 'PR',
      //       value: 'PR',
      //     },
      //     {
      //       label: 'RI',
      //       value: 'RI',
      //     },
      //     {
      //       label: 'SC',
      //       value: 'SC',
      //     },
      //     {
      //       label: 'SD',
      //       value: 'SD',
      //     },
      //     {
      //       label: 'TN',
      //       value: 'TN',
      //     },
      //     {
      //       label: 'TX',
      //       value: 'TX',
      //     },
      //     {
      //       label: 'UT',
      //       value: 'UT',
      //     },
      //     {
      //       label: 'VT',
      //       value: 'VT',
      //     },
      //     {
      //       label: 'VI',
      //       value: 'VI',
      //     },
      //     {
      //       label: 'VA',
      //       value: 'VA',
      //     },
      //     {
      //       label: 'WA',
      //       value: 'WA',
      //     },
      //     {
      //       label: 'WV',
      //       value: 'WV',
      //     },
      //     {
      //       label: 'WI',
      //       value: 'WI',
      //     },
      //     {
      //       label: 'WY',
      //       value: 'WY',
      //     },
      //   ],
      // },
      // {
      //   name: 'zip',
      //   value: '',
      //   type: 'text',
      //   id: 'zip',
      //   label: 'ZIP',
      //   margin: 'normal',
      //   fullWidth: true,
      //   multiline: false,
      //   placeholder: '',
      //   required: true,
      //   error: false,
      //   width: 33,
      //   regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      // },
    ],
  },
];

export default FormFields;
