import { createAPIActions } from '../../utils';

export const PROFILE_INITIAL_STATE = {
  isFetching: false,
  address: '',
  cellNumber: '',
  city: '',
  contactPreference: '',
  email: '',
  firstName: '',
  lastName: '',
  middleInitial: '',
  state: '',
  zip: '',
};
export const GET_USER_PROFILE_API = createAPIActions('get', 'user/profile/api');
export const UPDATE_USER_PROFILE_API = createAPIActions(
  'update',
  'user/profile/api',
);
