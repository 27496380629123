// React Imports
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as _ from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

// Custom Imports
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import QontoStepIcon, { QontoConnector } from '../../packages/Stepper/Qonto';
import formFields from './FormField';
import FormGroupComponent from '../../packages/AppFormGroup';
import { initialState, reducer } from './reducer';
import AppTable from '../../packages/AppTable';
import { ToggleUserAgencyAccess } from '../../components/UserTableAction';
import { TABLE_HEADER } from './constants';
import { APP_CONSTANTS } from '../../constants';
import { clearSelectedAgency, getAgency } from '../../modules/Agency/actions';
import { addUser } from '../../modules/AgencyUsers/action';

export default function AddUserAndPermission() {
  const [userAccessibleAgencies, setUserAccessibleAgencies] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const agencyList = useSelector((appState) => appState.Agency.agencyList);
  const aDispatch = useDispatch();
  const history = useHistory();

  const { formValue } = state;
  const { isFormNotValid } = state;
  const { activeStep } = state;

  const FORM_STEPS = ['User Info', 'Manage Permissions'];

  useEffect(() => {
    aDispatch(clearSelectedAgency());
    aDispatch(getAgency());
  }, []);

  const formData = {
    ...formValue,
    login_id: formValue.email,
    linked_agencies: userAccessibleAgencies,
  };

  function setActiveStep(step) {
    dispatch({ type: 'SET_ACTIVE_STEP', payload: step });
  }

  function handleChange({ name, value, error }) {
    dispatch({ type: 'UPDATE_FORM_VALUE', payload: { name, value } });
    dispatch({ type: 'SET_IS_FORM_NOT_VALID', payload: error });
  }
  // function to give agency access to user
  function giveAgencyAccess(agencyId) {
    setUserAccessibleAgencies((current) => [...current, agencyId]);
  }

  // function remove agency access to user
  function removeAgencyAccess(agencyId) {
    setUserAccessibleAgencies((current) => {
      const index = _.indexOf(current, agencyId);
      current.splice(index, 1);
      return [...current];
    });
  }

  function handleSubmit() {
    aDispatch(addUser(formData)).then((resp) => {
      if (resp?.data?.success) {
        history.push(`${APP_CONSTANTS.APP_ROUTE_URLS.USERS}`, [
          resp.data.id,
          resp.data.first_name,
          resp.data.last_name,
        ]);
      }
    });
  }

  return useMemo(
    () => (
      <>
        <AppContainer>
          <Grid
            alignItems="flex-start"
            justify="space-between"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle title="Add User">
                <Grid
                  alignItems="flex-start"
                  justify="space-between"
                  alignContent="stretch"
                  spacing={2}
                  container
                >
                  <AppButton
                    variant="text"
                    color="primary"
                    label="Back to Users"
                    handleClick={() => {
                      history.push(APP_CONSTANTS.APP_ROUTE_URLS.USERS);
                    }}
                  />
                </Grid>
              </AppPageTitle>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent
                  style={{ paddingRight: '50px', paddingLeft: '50px' }}
                >
                  <Stepper
                    style={{ padding: isMobile ? 0 : 8, marginBottom: '10px' }}
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<QontoConnector />}
                    className="stepper-container"
                  >
                    {FORM_STEPS.map((label) => (
                      <Step key={label} className="stepper-step">
                        <StepLabel
                          StepIconComponent={QontoStepIcon}
                          className="stepper-label"
                        >
                          <Typography
                            variant="subtitle1"
                            className="stepper-typography"
                          >
                            {label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {activeStep === 0 ? (
                    <>
                      <FormGroupComponent
                        handleChange={handleChange}
                        formFields={formFields}
                        values={formValue}
                      />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pt={3}
                        mb={1}
                      >
                        <Button
                          marginleft={1}
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={
                            isFormNotValid ||
                            !formValue.last_name ||
                            !formValue.first_name ||
                            !formValue.email ||
                            !formValue.cell_phone
                          }
                          onClick={() => {
                            setActiveStep(1);
                          }}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Grid
                      container
                      alignItems="flex-start"
                      justifyContent="center"
                      alignContent="stretch"
                      spacing={2}
                    >
                      <Grid item xl={6} lg={6} md={6} xs={12}>
                        <AppTable
                          tableName="user-accessible-agency-list-table"
                          size="small"
                          orderBy=""
                          searchCol
                          data={agencyList}
                          headers={TABLE_HEADER}
                          rowsPerPageOptions={[10, 15, 25, 35, 50]}
                          enableCellRender
                          render={{
                            status: (props) => (
                              <ToggleUserAgencyAccess
                                {...props}
                                giveAccess={giveAgencyAccess}
                                removeAccess={removeAgencyAccess}
                              />
                            ),
                          }}
                        />
                        <Box
                          display="flex"
                          justifyContent="center"
                          gridColumnGap="17px"
                          alignItems="center"
                          pt={3}
                          mb={1}
                        >
                          <AppButton
                            label="Back"
                            handleClick={() => {
                              setActiveStep(0);
                            }}
                          />
                          <Button
                            marginleft={4}
                            size="medium"
                            variant="contained"
                            color="primary"
                            disabled={userAccessibleAgencies.length === 0}
                            onClick={handleSubmit}
                          >
                            Add User
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </AppContainer>
      </>
    ),
    [agencyList, activeStep, formValue, userAccessibleAgencies],
  );
}
