import Card from '@material-ui/core/Card';
import * as _ from 'lodash';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { StyledDivider } from '../../packages/AppStyledComponents';
import FormGroupComponent from '../../packages/AppFormGroup';
import FormFields from './FormField';
import FileUploadComponent from '../../packages/AppFileUpload';
import { AppButton } from '../../packages/AppButton';
import { debounced, validateForm } from '../../utils';
import {
  FIELD_ERROR_INITIAL_STATE,
  REQUIRED_FIELDS,
  UPDATE_FIELD_ERROR_INITIAL_STATE,
} from './constants';

function MapDataToFormValue(data) {
  const {
    first_name: firstName,
    last_name: lastName,
    gender,
    years_of_experience: yearsOfExp,
    availability,
    languages: language,
    id_list: identity,
    insurance_list: insurance,
    certifications: certificate,
    offered_care_conditions: skills,
    pet_comfort: pets,
    smoking_environment: smoker,
    email,
    cell_phone: cellPhone,
    city,
    state,
    zip_postal_code: zip,
  } = data;

  return {
    firstName,
    lastName,
    gender,
    yearsOfExp,
    availability,
    language: language?.languages,
    identity: identity?.id_list,
    insurance: insurance?.insurance_list,
    certificate: certificate?.certifications,
    skills: skills?.offered_care_conditions,
    pets,
    smoker: smoker ? 'TRUE' : 'FALSE',
    email,
    cellPhone,
    state,
    city,
    zip,
  };
}

function MapFormValueToPayload(formValue, selectedFile) {
  const {
    firstName,
    lastName,
    gender,
    yearsOfExp,
    availability,
    language,
    identity,
    insurance,
    certificate,
    skills,
    pets,
    smoker,
    email,
    cellPhone,
    city,
    state,
    zip,
  } = formValue;
  const formData = new FormData();
  formData.append(
    'caregiver',
    new Blob(
      [
        JSON.stringify({
          email,
          availability,
          gender,
          city,
          state,
          cell_phone: cellPhone,
          certifications: {
            certifications: certificate,
          },
          first_name: firstName,
          last_name: lastName,
          offered_care_conditions: {
            offered_care_conditions: skills,
          },
          id_list: {
            id_list: identity,
          },
          insurance_list: {
            insurance_list: insurance,
          },
          languages: {
            languages: language,
          },
          pet_comfort: pets,
          smoking_environment: _.toLower(smoker) === 'true',
          years_of_experience: yearsOfExp,
          zip_postal_code: zip,
        }),
      ],
      {
        type: 'application/json',
      },
    ),
  );
  if (selectedFile && selectedFile.length > 0) {
    formData.append('profile_photo', selectedFile[0]);
  }

  return formData;
}

export default function CaregiverForm(props) {
  const {
    loading,
    formType,
    onCancel = () => {},
    onSubmit = () => {},
    caregiver,
    timezones,
    availability,
    language,
    insurance,
    certificate,
    care_condition,
    identity,
  } = props;
  const fieldErrorConst =
    formType === 'Update'
      ? UPDATE_FIELD_ERROR_INITIAL_STATE
      : FIELD_ERROR_INITIAL_STATE;

  const [formValue, setFormValue] = useState({
    language: [],
    identity: [],
    insurance: [],
    certificate: [],
    skills: [],
  });
  const [fieldError, setFieldError] = useState({ ...fieldErrorConst });
  const [validForm, setValidForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showForm, toggleForm] = useState(false);

  const doFormValidation = debounced(400, () => {
    setValidForm(validateForm({ requiredFields: REQUIRED_FIELDS, fieldError }));
  });
  const handleChange = ({ name, value, error }) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setFieldError((prevValue) => ({
      ...prevValue,
      [name]: error,
    }));
  };
  const formSubmitHandler = () => {
    const payload = MapFormValueToPayload(formValue, selectedFile);
    onSubmit(payload);
  };

  useEffect(() => {
    toggleForm(false);
    if (_.get(FormFields, '[0].list[4].values.length') === 0) {
      _.forEach(availability, (val) => {
        FormFields[0].list[4].values.push({ label: val, value: val });
      });
    }
    if (_.get(FormFields, '[0].list[5].values.length') === 0) {
      _.forEach(language, (val) => {
        FormFields[0].list[5].values.push({ label: val, value: val });
      });
    }
    if (_.get(FormFields, '[0].list[6].values.length') === 0) {
      _.forEach(identity, (val) => {
        FormFields[0].list[6].values.push({ label: val, value: val });
      });
    }
    if (_.get(FormFields, '[0].list[7].values.length') === 0) {
      _.forEach(insurance, (val) => {
        FormFields[0].list[7].values.push({ label: val, value: val });
      });
    }
    if (_.get(FormFields, '[0].list[8].values.length') === 0) {
      _.forEach(certificate, (val) => {
        FormFields[0].list[8].values.push({ label: val, value: val });
      });
    }
    if (_.get(FormFields, '[0].list[9].values.length') === 0) {
      _.forEach(care_condition, (val) => {
        FormFields[0].list[9].values.push({ label: val, value: val });
      });
    }
    toggleForm(true);
  }, [
    timezones,
    availability,
    language,
    insurance,
    certificate,
    care_condition,
    identity,
  ]);

  useEffect(() => {
    if (_.isEmpty(formValue)) {
      setValidForm(false);
    } else {
      doFormValidation();
    }
  }, [fieldError]);

  useEffect(() => {
    if (caregiver) {
      setValidForm(true);
      setFormValue(MapDataToFormValue(caregiver));
    }
  }, [caregiver]);

  return (
    showForm && (
      <form
        noValidate
        autoComplete="off"
        name="signInForm"
        className="form-container"
      >
        <Card elevation={0}>
          <CardContent>
            <Grid
              alignItems="stretch"
              justify="flex-start"
              alignContent="stretch"
              spacing={2}
              container
            >
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Personal Details</Typography>
                <StyledDivider marginbottom={2} />
                <FormGroupComponent
                  handleChange={handleChange}
                  formFields={FormFields[0]}
                  values={formValue}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Contact Details</Typography>
                <StyledDivider marginbottom={2} />
                <FormGroupComponent
                  handleChange={handleChange}
                  formFields={FormFields[1]}
                  values={formValue}
                />
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                  pt={2}
                >
                  <Typography gutterBottom variant="subtitle1">
                    Upload Profile Picture
                  </Typography>
                  <FileUploadComponent
                    style={{ marginBottom: 16 }}
                    accept=".png, .jpg, .jpeg"
                    onDrop={(data) => {
                      setSelectedFile(data);
                    }}
                    dropIcon
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            p={2}
          >
            <AppButton
              label="Cancel"
              loaderColor="secondary"
              handleClick={onCancel}
            />
            <AppButton
              marginleft={1}
              variant="contained"
              color="primary"
              loaderColor="secondary"
              label={`${formType} Caregiver`}
              disabled={!validForm}
              loading={loading}
              loaderSize={24}
              handleClick={formSubmitHandler}
            />
          </Box>
        </Card>
      </form>
    )
  );
}
