import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { getUniqueKey } from '../../utils';
import { StyledTextField } from '../AppStyledComponents';

export function SelectComponent(props) {
  const {
    formField: {
      id,
      label,
      name,
      required,
      defaultValue,
      values,
      width,
      hidden,
    },
    handleChange,
  } = props;
  const [selectedValue, setSelectValue] = useState('');
  const [error, setError] = useState(true);

  useEffect(() => {
    setSelectValue(props.value || defaultValue || '');
  }, [props.value]);

  const handleSelectChange = (event) => {
    const { target } = event;
    const val = target.value;
    const err = required ? val && val.length > 0 : true;
    if (handleChange) {
      handleChange({ name, value: val, error: !err });
    }
    setSelectValue(val);
    setError(err);
  };

  return useMemo(
    () =>
      hidden ? null : (
        <StyledTextField
          select
          label={label}
          required={required}
          value={selectedValue}
          error={!error}
          onChange={handleSelectChange}
        >
          {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
          {_.map(
            values,
            ({ label: optionLabel, value: optionValue }, index) => (
              <MenuItem
                value={optionValue}
                key={getUniqueKey(optionValue, index)}
              >
                {optionLabel || optionValue}
              </MenuItem>
            ),
          )}
        </StyledTextField>
      ),
    [id, label, required, width, name, selectedValue, error, values],
  );
}
