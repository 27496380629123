import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import { APP_CONSTANTS } from '../../constants';
import LinkComponent from '../../packages/AppLink';

export function CaregiversActions(props) {
  const {
    row: { caregiver_identifier },
  } = props;
  const history = useHistory();
  return (
    <TableCell
      align="justify"
      size="small"
      style={{
        width: props.width,
        /* maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', */
      }}
    >
      <IconButton
        size="small"
        color="secondary"
        onClick={() => {
          history.push(
            `${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_CARE_GIVER}${caregiver_identifier}`,
          );
        }}
      >
        <EditOutlinedIcon />
      </IconButton>
    </TableCell>
  );
}

export function CaregiversFirstName(props) {
  const {
    row: { caregiver_identifier, first_name },
  } = props;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_CARE_GIVER}${caregiver_identifier}`}
      >
        {first_name}
      </LinkComponent>
    </TableCell>
  );
}

export function CaregiversLastName(props) {
  const {
    row: { caregiver_identifier, last_name },
  } = props;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_CARE_GIVER}${caregiver_identifier}`}
      >
        {last_name}
      </LinkComponent>
    </TableCell>
  );
}
