import doRequest from '../../api';
import {
  RESET_USER_PASSWORD_API,
  SET_USER_INFO,
  UPDATE_USER_PASSWORD_API,
} from './constants';
import { APP_CONSTANTS } from '../../constants';

export const setUserInfo = (userInfo) => (dispatch) => {
  dispatch({ type: SET_USER_INFO, userInfo });
};

export const changeUserPassword = (data) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.PASSWORD,
    actionType: UPDATE_USER_PASSWORD_API,
    data,
  };
  return dispatch(doRequest.put(config));
};

export const resetUserPassword = (data) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.RESET_PASSWORD,
    actionType: RESET_USER_PASSWORD_API,
    data,
  };
  return dispatch(doRequest.post(config));
};
