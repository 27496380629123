import React, { useMemo } from 'react';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Titillium Web', sans-serif;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html, body {
    background-color: rgb(57 30 23 / 0.03);
  }

`;

function GenerateTheme(props) {
  const { theme } = props;
  return useMemo(
    () => (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={{ ...theme }}>
          <>
            <GlobalStyle theme={theme} />
            {props.children}
          </>
        </ThemeProvider>
      </MuiThemeProvider>
    ),
    [theme],
  );
}

export default function WithThemes(props) {
  const primaryColor = useSelector((state) => state.Theme.primary);
  const secondaryColor = useSelector((state) => state.Theme.secondary);
  let theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
      shade1: {
        main: '#559FD7',
        light: '#77b2df',
        dark: '#3b6f96',
        contrastText: '#fff',
      },
      shade2: {
        main: '#195C74',
        light: '#477c8f',
        dark: '#114051',
        contrastText: '#fff',
      },
      shade3: {
        main: '#185077',
        light: '#467392',
        dark: '#103853',
        contrastText: '#fff',
      },
      shade4: {
        light: '#e7ecf3',
        main: '#e1e8f0',
        dark: '#9da2a8',
        contrastText: '#000',
      },
    },
    typography: {
      fontFamily: [
        '"Titillium Web"',
        '-apple-system',
        'BlinkMacSystemFont',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
      h1: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      h2: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      h3: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      h4: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      h5: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      h6: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      subtitle1: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      subtitle2: {
        fontFamily: [
          '"Lato"',
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 2,
    },
  });
  theme = responsiveFontSizes(theme);

  return <GenerateTheme theme={theme} {...props} />;
}
