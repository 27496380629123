import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import ImageComponent from '../AppImage/Lodable';
import { APP_CONSTANTS } from '../../constants';

export const StyledBand = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing() / 4}px;
  background-color: ${(props) => props.theme.palette.secondary.main};
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-bottom: ${(props) =>
    props.theme.spacing(props.marginbottom ? props.marginbottom : 0)}px;
  margin-top: ${(props) =>
    props.theme.spacing(props.margintop ? props.margintop : 0)}px;
`;

export const LogoWithTextImageComponent = styled(ImageComponent)`
  width: 550px;
  cursor: pointer;
`;

export const StyledBannerButton = styled(Button)`
  font-size: 18px;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    font-size: 14px;
  }
`;
export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding: 0 24px 14px;
  margin: 0;
`;

export const StyledDataList = styled.ul`
  width: 100%;
  box-sizing: border-box;
  > li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: center;
    padding: 4px 0;
    box-sizing: border-box;

    > i {
      font-size: 16px;
      padding-right: 16px;
      word-break: break-word;
      color: ${(props) => props.theme.palette.text.primary};
      font-weight: bold;
      font-style: normal;
    }
    > span {
      &:first-child {
        font-size: 14px;
        text-transform: capitalize;
        min-width: ${(props) => props.w}px;
        word-break: break-word;
        font-weight: 800;
        color: ${(props) => props.theme.palette.secondary.dark};
      }
      display: inline-block;
      font-size: 13px;
      font-weight: 300;
      line-height: 18px;
      word-break: break-word;
    }
    &.list-with-email-phone {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      > span {
        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }
`;

export const StyledInputLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
}))(InputLabel);

export const StyledTextField = styled(TextField)`
  > label {
    color: ${(props) => props.theme.palette.text.primary};
    /* font-size: 1.2rem; */
    /* font-weight: bolder !important; */
  }
`;
