import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  GET_ALL_LEADS_API,
  GET_LEAD_BY_ID_API,
  GET_LEAD_COUNT_API,
  GET_LEADS_REPORT_API,
  UPDATE_LEAD_BY_ID_API,
} from './constants';

export const getAllLeads = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.LEADS,
    actionType: GET_ALL_LEADS_API,
  };
  return dispatch(doRequest.get(config));
};

export const getAllLeadsByAgencyId = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.LEADS}`,
    actionType: GET_ALL_LEADS_API,
  };
  return dispatch(doRequest.get(config));
};

export const getLeadById = (leadId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}/${leadId}`,
    actionType: GET_LEAD_BY_ID_API,
  };
  return dispatch(doRequest.get(config));
};

export const getLeadByIdAndAgencyId = (leadId, agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.LEADS}/${leadId}`,
    actionType: GET_LEAD_BY_ID_API,
  };
  return dispatch(doRequest.get(config));
};

export const getLeadCountByAgencyId = (agencyId, fromDate, toDate) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}${APP_CONSTANTS.API_LINKS.LEADS}/count`,
    actionType: GET_LEAD_COUNT_API,
    data: {
      from_date: fromDate,
      to_date: toDate,
    },
  };
  return dispatch(doRequest.post(config));
};

export const downloadLeadsReport = (fromDate, toDate) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}${APP_CONSTANTS.API_LINKS.REPORT}`,
    actionType: GET_LEADS_REPORT_API,
    data: {
      from_date: fromDate,
      to_date: toDate,
    },
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(doRequest.post(config));
};

export const activateLead = (leadId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}/${leadId}/billable`,
    actionType: UPDATE_LEAD_BY_ID_API,
  };
  return dispatch(doRequest.put(config));
};

export const deactivateLead = (leadId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.LEADS}/${leadId}/nonbillable`,
    actionType: UPDATE_LEAD_BY_ID_API,
  };
  return dispatch(doRequest.put(config));
};
