const FormFields = {
  id: 'user-info-form-fields',
  title: '',
  type: 'USER_INFO',
  list: [
    {
      name: 'first_name',
      value: '',
      type: 'text',
      id: 'first-name',
      label: 'First Name',
      margin: 'normal',
      fullWidth: true,
      multiline: false,
      placeholder: '',
      required: true,
      error: false,
      width: 50,
      regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
    },
    {
      name: 'last_name',
      value: '',
      type: 'text',
      id: 'last-name',
      label: 'Last Name',
      margin: 'normal',
      fullWidth: true,
      multiline: false,
      placeholder: '',
      required: true,
      error: false,
      width: 50,
      regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
    },
    {
      name: 'email',
      value: '',
      type: 'email',
      id: 'email-id',
      label: 'Email Address(Login Id)',
      margin: 'normal',
      fullWidth: true,
      multiline: false,
      placeholder: '',
      required: true,
      error: false,
      width: 50,
      regExpValue: '\\S+@\\S+\\.\\S+',
    },
    {
      name: 'cell_phone',
      value: '',
      type: 'phone',
      id: 'cellPhone-id',
      label: 'Phone Number',
      margin: 'normal',
      fullWidth: true,
      multiline: false,
      placeholder: '(XXX) XXX-XXXX',
      required: true,
      error: false,
      width: 50,
      regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
    },
  ],
};

export default FormFields;
