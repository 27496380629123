import React from 'react';
import styled from 'styled-components';
import ImageComponent from '../AppImage/Lodable';
import Logo from '../../assets/images/Aidquest_logo.png';
import { APP_CONSTANTS } from '../../constants';

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: ${(props) => props.width || 190}px;
  @media (max-width: ${APP_CONSTANTS.SCREEN_SIZES.md}px) {
    width: 135px;
  }
`;

export default function AppLogo(props) {
  return (
    <StyledLogoContainer {...props}>
      <ImageComponent url={Logo} />
    </StyledLogoContainer>
  );
}
