import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import AppTable from '../../packages/AppTable';
import { GROUP_LIST_TABLE_HEADERS } from './constants';
import {
  GroupAction,
  GroupCaregivers,
  GroupLeads,
  GroupName,
  ToggleGroupStatus,
} from '../../components/GroupActions';
import {
  clearSelectedGroup,
  deleteGroup,
  getGroup,
} from '../../modules/Group/actions';
import ConfirmationModal from '../../packages/AppModal/Confirmation';

export default function GroupPage() {
  const dispatch = useDispatch();
  const [confirmDeleteGroup, toggleDeleteGroup] = useState(false);
  const [selectedGroupId, toggleGroupSelection] = useState('');
  const groupList = useSelector((state) => state.Groups.groupList);

  const toggleDeleteGroupAction = (groupId) => {
    toggleDeleteGroup((state) => !state);
    toggleGroupSelection(groupId || '');
  };
  const deleteGroupAction = () => {
    dispatch(deleteGroup(selectedGroupId)).then((resp) => {
      if (resp?.data?.success) {
        toggleDeleteGroupAction();
        dispatch(getGroup());
      }
    });
  };

  useEffect(() => {
    dispatch(clearSelectedGroup());
  }, []);

  return useMemo(
    () => (
      <>
        <AppContainer>
          <Grid
            alignItems="flex-start"
            justify="space-between"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle title="Groups">
                <Grid
                  alignItems="flex-start"
                  justify="space-between"
                  alignContent="stretch"
                  spacing={2}
                  container
                ></Grid>
              </AppPageTitle>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary>
                <AppTable
                  tableName="group-list-table"
                  size="small"
                  orderBy=""
                  searchCol
                  data={groupList}
                  headers={GROUP_LIST_TABLE_HEADERS}
                  rowsPerPageOptions={[100, 500, 1000]}
                  enableCellRender
                  render={{
                    name: (props) => <GroupName {...props} />,
                    action: (props) => (
                      <GroupAction
                        {...props}
                        deleteGroup={toggleDeleteGroupAction}
                      />
                    ),
                    status: (props) => <ToggleGroupStatus {...props} />,
                    total_leads: (props) => <GroupLeads {...props} />,
                    total_active_caregivers: (props) => (
                      <GroupCaregivers {...props} />
                    ),
                  }}
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </AppContainer>
        <ConfirmationModal
          title="Delete Group"
          isOpen={confirmDeleteGroup}
          onCancel={toggleDeleteGroupAction}
          onConfirm={() => deleteGroupAction()}
        >
          <Typography variant="body1">
            Do you want to continue delete the group?
          </Typography>
        </ConfirmationModal>
      </>
    ),
    [groupList, confirmDeleteGroup, selectedGroupId],
  );
}
