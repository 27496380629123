import { CAREGIVERS_INITIAL_STATE, GET_ALL_CAREGIVERS_API } from './constants';

export function Reducer(state = CAREGIVERS_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_CAREGIVERS_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_ALL_CAREGIVERS_API.SUCCESS:
      return {
        ...state,
        caregiversList: action.data,
        isFetching: false,
      };
    case GET_ALL_CAREGIVERS_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
