export const requiredFields = [
  'contact_name',
  'contact_phone',
  'contact_email',
  'UUID',
  'name',
  'agency_website_url',
  'address',
  'state',
  'zip_postal_code',
  'primary_site_color',
  'secondary_site_color',
  'office_hours_from',
  'office_hours_to',
  'office_hours_timezone',
  'city',
  'availability',
  'coverage_area_cities',
];

export const defaultFieldError = {
  contact_name: false,
  contact_phone: false,
  contact_email: false,
  UUID: false,
  name: false,
  agency_website_url: false,
  address: false,
  state: false,
  zip_postal_code: false,
  primary_site_color: false,
  secondary_site_color: false,
  office_hours_from: false,
  office_hours_to: false,
  office_hours_timezone: false,
  city: false,
  availability: false,
  coverage_area_cities: false,
  payment_option: false,
  hourly_rate_from: false,
  hourly_rate_to: false,
  liveIn_rate_from: false,
  liveIn_rate_to: false,
};
