const DEFAULT_LEADS_LIST_TABLE_HEADERS = [
  { label: 'First Name', key: 'first_name', search: true, sort: true },
  { label: 'Last Name', key: 'last_name', search: true, sort: true },
  { label: 'email', key: 'email', search: true, sort: true, width: '200px' },
  { label: 'phone', key: 'cell_phone', search: true, sort: true },
  { label: 'zip', key: 'zip_postal_code', search: true, sort: true },
  {
    label: 'Time & Date',
    key: 'creation_timestamp',
    search: true,
    sort: true,
    width: '150px',
  },
  { label: 'Type', key: 'lead_type', search: true, sort: true },
  { label: 'Source', key: 'source', search: true, sort: true },
];

export const LEADS_LIST_TABLE_HEADERS = (userType) =>
  userType === 'BOOMERS_ADMIN'
    ? [
        ...DEFAULT_LEADS_LIST_TABLE_HEADERS,
        {
          label: 'BILLABLE',
          key: 'billable',
          search: false,
          sort: true,
          width: '80px',
        },
        {
          label: 'ACTIONS',
          key: 'action',
          search: false,
          sort: false,
          width: '70px',
        },
      ]
    : [
        ...DEFAULT_LEADS_LIST_TABLE_HEADERS,
        {
          label: 'ACTIONS',
          key: 'action',
          search: false,
          sort: false,
          width: '70px',
        },
      ];
