import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import { GET_USER_PROFILE_API, UPDATE_USER_PROFILE_API } from './constants';

export const getUserProfile = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.USERS,
    actionType: GET_USER_PROFILE_API,
  };
  return dispatch(doRequest.get(config));
};

export const updateUserProfile = (profile) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.USERS,
    actionType: UPDATE_USER_PROFILE_API,
    data: profile,
  };
  return dispatch(doRequest.put(config));
};
