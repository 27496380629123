import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  CLEAR_SELECTED_GROUP,
  CREATE_GROUP_API,
  DELETE_GROUP_BY_ID_API,
  GET_GROUP_API,
  GET_GROUP_BY_ID_API,
  SET_SELECTED_GROUP,
  UPDATE_GROUP_BY_ID_API,
  DOWNLOAD_DATA_BY_GROUP,
  CLEAR_SELECTED_GROUP_BILLING_INFO,
  SET_SELECTED_GROUP_BILLING_INFO,
  UPDATE_BILLING_INFO_BY_BILLING_ID,
  CREATE_BILLING_INFO,
  GET_SELECTED_GROUP_BILLING_INFO,
} from './constants';

export const createGroup = (agencyDetails) => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.GROUP,
    actionType: CREATE_GROUP_API,
    formData: true,
    filterPayload: true,
    data: agencyDetails,
  };
  return dispatch(doRequest.post(config));
};

export const getGroup = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.GROUP,
    actionType: GET_GROUP_API,
  };
  return dispatch(doRequest.get(config));
};

export const getGroupById = (groupId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}`,
    actionType: GET_GROUP_BY_ID_API,
  };
  return dispatch(doRequest.get(config));
};

export const updateGroup = (groupId, group) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}`,
    actionType: UPDATE_GROUP_BY_ID_API,
    formData: true,
    data: group,
  };
  return dispatch(doRequest.put(config));
};

export const deleteGroup = (groupId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}`,
    actionType: DELETE_GROUP_BY_ID_API,
  };
  return dispatch(doRequest.delete(config));
};

export const setSelectedGroup = (group) => (dispatch) =>
  dispatch({ type: SET_SELECTED_GROUP, group });

export const clearSelectedGroup = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_GROUP });

export const activateGroup = (groupId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}/activate`,
    actionType: UPDATE_GROUP_BY_ID_API,
  };
  return dispatch(doRequest.put(config));
};

export const deactivateGroup = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${agencyId}/deactivate`,
    actionType: UPDATE_GROUP_BY_ID_API,
  };
  return dispatch(doRequest.put(config));
};

export const downloadByGroupId = (groupId, fromDate, toDate) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}/leads/report`,
    actionType: DOWNLOAD_DATA_BY_GROUP,
    data: {
      from_date: fromDate,
      to_date: toDate,
    },
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(doRequest.post(config));
};

export const getSelectedGroupBillingInfo = (groupId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}/billing`,
    actionType: GET_SELECTED_GROUP_BILLING_INFO,
  };
  return dispatch(doRequest.get(config));
};

export const createSelectedGroupBillingInfo = (billingDetails, groupId) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.GROUP}/${groupId}/billing`,
    actionType: CREATE_BILLING_INFO,
    formData: true,
    filterPayload: true,
    data: billingDetails,
  };
  return dispatch(doRequest.post(config));
};

export const updateSelectedGroupBillingInfo = (billingDetails) => (
  dispatch,
) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLING}`,
    actionType: UPDATE_BILLING_INFO_BY_BILLING_ID,
    formData: true,
    filterPayload: true,
    data: billingDetails,
  };
  return dispatch(doRequest.put(config));
};

export const clearSelectedGroupBillingInfo = () => (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_GROUP_BILLING_INFO });

export const setSelectedGroupBillingInfo = (billingDetails) => (dispatch) =>
  dispatch({ type: SET_SELECTED_GROUP_BILLING_INFO, billingDetails });
