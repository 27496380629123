/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import * as _ from 'lodash';

const StyledChip = withStyles((theme) => ({
  root: {
    borderColor: (props) =>
      props.color === 'error' ? theme.palette.error.main : null,
    color: (props) =>
      props.color === 'error' ? theme.palette.error.main : null,
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginLeft: '-8px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function validatePhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.length > 0 && phoneNumber !== '') {
    // Regular expression pattern for a valid phone number
    const regexPattern = /^\d{10}$/; // Assumes a 10-digit phone number

    // Test the provided phone number against the regular expression
    return regexPattern.test(phoneNumber);
  }
  return true;
}

function validateMobileNumbers(numbers, maxMobileNumbers, required) {
  let error = '';
  // Remove duplicates
  const uniqueMobileNumbers = Array.from(new Set(numbers));

  // Validate mobile number format
  const mobileNumberFormatRegex = /^\d{10}$/;
  const invalidMobileNumbers = uniqueMobileNumbers.filter(
    (number) => !mobileNumberFormatRegex.test(number),
  );

  if (invalidMobileNumbers.length > 0) {
    error = 'Invalid mobile number format';
  } else if (required && uniqueMobileNumbers.length === 0) {
    error = 'At least one Mobile number is required';
  } else if (
    maxMobileNumbers &&
    uniqueMobileNumbers.length > maxMobileNumbers
  ) {
    error = `Maximum ${maxMobileNumbers} mobile numbers allowed`;
  }

  return error;
}

const AppMultiplePhoneNumberInput = (props) => {
  const {
    formField: {
      id,
      value: defaultValue,
      label,
      variant,
      maxMobileNumbers,
      type,
      name,
      multiline,
      rows,
      placeholder,
      required,
      disabled,
      startAdornment,
      boxProps,
      commonFieldHeading,
      commonFieldTypoProps,
      haveCommonField,
      changeFloatLabelColor,
    },
  } = props;

  const classes = useStyles();
  const [mobileNumbers, setMobileNumbers] = useState(defaultValue || '');
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const inputMobileNumbers = event.target.value
      .split(',')
      .map((number) => number.trim());

    const errorResponse = validateMobileNumbers(
      inputMobileNumbers,
      maxMobileNumbers,
      required,
    );

    setMobileNumbers(inputMobileNumbers);
    setError(errorResponse);

    props.handleChange({
      name,
      value: inputMobileNumbers,
      error: !!errorResponse,
    });
  };

  const handleBlur = () => {
    const errorResponse = validateMobileNumbers(
      mobileNumbers,
      maxMobileNumbers,
      required,
    );

    // Remove duplicates
    const uniqueMobileNumbers = Array.from(new Set(mobileNumbers));

    props.handleChange({
      name,
      value: uniqueMobileNumbers,
      error: !!errorResponse,
    });
    setMobileNumbers(uniqueMobileNumbers);
    setError(errorResponse);
  };

  const handleDeleteMobileNumber = (numberToDelete) => {
    const updatedMobileNumbers = [...mobileNumbers];
    const indexToDelete = updatedMobileNumbers.indexOf(numberToDelete);
    if (indexToDelete > -1) {
      updatedMobileNumbers.splice(indexToDelete, 1);
    }

    const errorResponse = validateMobileNumbers(
      updatedMobileNumbers,
      maxMobileNumbers,
      required,
    );

    props.handleChange({
      name,
      value: updatedMobileNumbers,
      error: !!errorResponse,
    });

    setMobileNumbers(updatedMobileNumbers);
    setError(errorResponse);
  };

  React.useEffect(() => {
    if (mobileNumbers.length === 0 && props.value.length > 0)
      setMobileNumbers(props.value || defaultValue);
  }, [props.value]);

  return (
    <>
      {haveCommonField && commonFieldHeading && (
        <Typography gutterBottom {...commonFieldTypoProps}>
          {commonFieldHeading}
        </Typography>
      )}
      <Box width={1} {...boxProps}>
        <TextField
          value={mobileNumbers}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={error !== ''}
          helperText={error}
          fullWidth
          id={id}
          label={label}
          variant={variant}
          type={type}
          name={name}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          InputLabelProps={{
            style: {
              color: changeFloatLabelColor === true ? '#5EC5BA' : 'black',
            },
          }}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : (
              ''
            ),
          }}
        />
        <Box component="ul" className={classes.root}>
          {_.map(mobileNumbers, (number, index) => (
            <li key={`multi-email-chip-list-${index}`}>
              <StyledChip
                label={`(${number.slice(0, 3)}) ${number.slice(
                  3,
                  6,
                )}-${number.slice(6)}`}
                onDelete={() => handleDeleteMobileNumber(number)}
                variant="outlined"
                color={validatePhoneNumber(number) ? 'secondary' : 'error'}
                className={classes.chip}
              />
            </li>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default AppMultiplePhoneNumberInput;
