import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TableCell } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import {
  approveBill,
  downloadBillCsvReport,
  downloadBillPdf,
  rejectBill,
} from '../../modules/Billing/actions';
import IOSSwitch from '../../packages/AppIOSSwitch';

export function DownloadPdf(props) {
  const dispatch = useDispatch();

  const {
    row: { invoice_pdf_url },
  } = props;

  const downloadPdf = () => {
    dispatch(downloadBillPdf(invoice_pdf_url)).then((resp) => {
      const { data } = resp;
      if (data) {
        // eslint-disable-next-line no-unused-vars
        let filename = 'Invoice.pdf';
        const disposition = resp.headers['content-disposition'];
        if (disposition && disposition.indexOf('inline') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        fileDownload(data, filename);
      }
    });
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <Button style={{ color: '#5EC5BA' }} variant="text" onClick={downloadPdf}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textDecoration: 'underline',
          }}
        >
          <h3 style={{ marginRight: '5px' }}>PDF</h3>
          <GetAppIcon />
        </div>
      </Button>
    </TableCell>
  );
}

export function DownloadCsv(props) {
  const dispatch = useDispatch();

  const {
    row: { report_csv_url },
  } = props;

  const downloadBillCsv = () => {
    dispatch(downloadBillCsvReport(report_csv_url)).then((resp) => {
      const { data } = resp;
      if (data) {
        // eslint-disable-next-line no-unused-vars
        let filename = 'Report.csv';
        const disposition = resp.headers['content-disposition'];
        if (disposition && disposition.indexOf('inline') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        fileDownload(data, filename);
      }
    });
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <Button
        style={{ color: '#5EC5BA' }}
        variant="text"
        onClick={downloadBillCsv}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textDecoration: 'underline',
          }}
        >
          <h3 style={{ marginRight: '5px' }}>CSV</h3>
          <GetAppIcon />
        </div>
      </Button>
    </TableCell>
  );
}

export function BillApprovalRejection(props) {
  const [isBillApproved, setIsBillApproved] = useState(false);
  const {
    row: { bill_id, approved, billing_status },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setIsBillApproved(approved);
  }, [approved]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(approveBill(bill_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setIsBillApproved(checked);
        }
      });
    } else {
      dispatch(rejectBill(bill_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setIsBillApproved(checked);
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <IOSSwitch
        disabled={billing_status !== 'GENERATED'}
        checked={isBillApproved}
        onChange={handleChange}
        name={bill_id}
      />
    </TableCell>
  );
}
