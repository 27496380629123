import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import {
  getLeadById,
  getLeadByIdAndAgencyId,
} from '../../modules/Leads/actions';
import {
  StyledDataList,
  StyledDivider,
} from '../../packages/AppStyledComponents';
import { getUniqueKey } from '../../utils';

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
`;

export default function LeadDetailsPage() {
  const [leadObj, setLead] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id: leadId, agencyId } = params;

  useEffect(() => {
    if (agencyId) {
      dispatch(getLeadByIdAndAgencyId(leadId, agencyId)).then((resp) => {
        setLead(resp.data);
      });
    } else {
      dispatch(getLeadById(leadId)).then((resp) => {
        setLead(resp.data);
      });
    }
  }, [leadId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Lead Details">
            <AppButton
              variant="text"
              color="primary"
              label="Back to Leads"
              handleClick={() => {
                if (agencyId) {
                  history.push(
                    `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agencyId}/leads`,
                  );
                } else {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.LEADS);
                }
              }}
            />
          </AppPageTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid
            alignItems="stretch"
            justify="flex-start"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12} lg={3}>
              <StyledCard elevation={0}>
                <CardHeader
                  title={
                    <Typography variant="subtitle1">
                      Lead Basic Information
                    </Typography>
                  }
                />
                <StyledDivider />
                <CardContent>
                  <StyledDataList w={130}>
                    <li>
                      <span>Name</span>
                      <span>
                        {leadObj?.first_name} {leadObj?.last_name}
                      </span>
                    </li>
                    {(leadObj?.needed_for || leadObj?.care_is_for) && (
                      <li>
                        <span>Looking For</span>
                        <span>
                          {leadObj?.needed_for || leadObj?.care_is_for}
                        </span>
                      </li>
                    )}
                    {leadObj?.age && (
                      <li>
                        <span>Age</span>
                        <span>{leadObj?.age}</span>
                      </li>
                    )}
                    {leadObj?.weight && (
                      <li>
                        <span>Weight</span>
                        <span>{leadObj?.weight}</span>
                      </li>
                    )}
                    {leadObj?.gender && (
                      <li>
                        <span>Gender</span>
                        <span>{leadObj?.gender}</span>
                      </li>
                    )}
                    {leadObj?.email && (
                      <li>
                        <span>Email</span>
                        <span>{leadObj?.email}</span>
                      </li>
                    )}
                    {(leadObj?.cell_phone || leadObj?.phone_number) && (
                      <li>
                        <span>Phone Number</span>
                        <span>
                          {leadObj?.cell_phone || leadObj?.phone_number}
                        </span>
                      </li>
                    )}
                    {leadObj?.country && (
                      <li>
                        <span>Country</span>
                        <span>{leadObj?.country}</span>
                      </li>
                    )}
                    {leadObj?.zip_postal_code && (
                      <li>
                        <span>Zip Code</span>
                        <span>{leadObj?.zip_postal_code}</span>
                      </li>
                    )}
                    {leadObj?.zip_where_care_needed && (
                      <li>
                        <span>Zip where care needed</span>
                        <span>{leadObj?.zip_where_care_needed}</span>
                      </li>
                    )}
                    {leadObj?.skills?.skills && (
                      <li>
                        <span>Skills</span>
                        <span>
                          {_.get(leadObj, 'skills.skills').join(', ')}
                        </span>
                      </li>
                    )}
                    {leadObj?.lead_type && (
                      <li>
                        <span>Lead Type</span>
                        <span>{leadObj?.lead_type}</span>
                      </li>
                    )}
                    {leadObj?.source && (
                      <li>
                        <span>Source</span>
                        <span>{leadObj?.source}</span>
                      </li>
                    )}
                    {leadObj?.years_of_experience && (
                      <li>
                        <span>Years of Experience</span>
                        <span>{leadObj?.years_of_experience}</span>
                      </li>
                    )}
                    {leadObj?.start_time && (
                      <li>
                        <span>Start Time</span>
                        <span>{leadObj?.start_time}</span>
                      </li>
                    )}
                  </StyledDataList>
                </CardContent>
              </StyledCard>
            </Grid>

            {leadObj?.lead_type === 'CAREGIVER' && (
              <>
                <Grid item xs={12} lg={2}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">Care Needs</Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <StyledDataList>
                        {_.map(
                          _.get(leadObj, 'required_services.required_services'),
                          (reqSer, index) => (
                            <li key={getUniqueKey(reqSer, index)}>
                              <i>{'->'}</i>
                              <span>{reqSer}</span>
                            </li>
                          ),
                        )}
                      </StyledDataList>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">Conditions</Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <StyledDataList>
                        {_.map(
                          _.get(
                            leadObj,
                            'required_care_conditions.required_care_conditions',
                          ),
                          (cri, index) => (
                            <li key={getUniqueKey(cri, index)}>
                              <i>{'->'}</i>
                              <span>{cri}</span>
                            </li>
                          ),
                        )}
                      </StyledDataList>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">Preference</Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <StyledDataList w={150}>
                        <li>
                          <span>Preferred Time</span>
                          <span>{leadObj?.availability}</span>
                        </li>
                        <li>
                          <span>Preferred Gender</span>
                          <span>{leadObj?.preffered_gender}</span>
                        </li>
                        <li>
                          <span>Pets</span>
                          <span>{leadObj?.pet_comfort}</span>
                        </li>
                        <li>
                          <span>Do You Smoke?</span>
                          <span>
                            {leadObj?.smoking_environment ? 'Yes' : 'No'}
                          </span>
                        </li>
                      </StyledDataList>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">
                          Interested In Caregivers
                        </Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <StyledDataList>
                        {_.map(
                          _.get(leadObj, 'interested_caregivers'),
                          (intCaregiver, index) => (
                            <li
                              key={getUniqueKey(
                                _.get(intCaregiver, 'first_name'),
                                index,
                              )}
                            >
                              <i>{'->'}</i>
                              <span>{`${_.get(
                                intCaregiver,
                                'first_name',
                              )} ${_.get(intCaregiver, 'last_name')}`}</span>
                            </li>
                          ),
                        )}
                      </StyledDataList>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </>
            )}

            {leadObj?.lead_type !== 'CAREGIVER' && (
              <>
                <Grid item xs={12} lg={4}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">Message</Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <Typography variant="caption">
                        {_.get(leadObj, 'lead_message')}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <StyledCard elevation={0}>
                    <CardHeader
                      title={
                        <Typography variant="subtitle1">
                          Chat Transcript
                        </Typography>
                      }
                    />
                    <StyledDivider />
                    <CardContent>
                      <Typography
                        variant="caption"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {_.get(leadObj, 'chat_transcript')}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
