import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import { GET_APP_SERVER_SESSION_API } from './constants';

export const getAppServerSession = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.APP_SERVER_SESSION_API,
    actionType: GET_APP_SERVER_SESSION_API,
  };
  return dispatch(doRequest.get(config));
};
