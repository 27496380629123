import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import AppTable from '../../packages/AppTable';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import {
  BILLINGS_TABLE_HEADERS_AGENCY,
  BILLINGS_TABLE_HEADERS_GROUP,
} from './constants';
import {
  getAgencyBills,
  getGroupBills,
  getPeriods,
} from '../../modules/Billing/actions';
import {
  BillApprovalRejection,
  DownloadCsv,
  DownloadPdf,
} from '../../components/BillingActions';

export default function Billings() {
  const [billingList, setBilligList] = useState([]);
  const [periodsList, setPeriodsList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getPeriodsList();
  }, []);

  const handelTabValueChange = (event, value) => {
    setTabValue(value);
    getBillingList(value, selectedPeriod);
  };

  const handelPeriodChange = (event) => {
    const value = event?.target?.value;
    setSelectedPeriod(value);
    getBillingList(tabValue, value);
  };

  const getPeriodsList = () => {
    dispatch(getPeriods()).then((resp) => {
      const { data } = resp;
      const manipulatedList = data.reverse(); // Reverse List Here
      setPeriodsList([...manipulatedList]);
      setSelectedPeriod(manipulatedList[0]?.billing_period_id);
      getBillingList(tabValue, manipulatedList[0]?.billing_period_id);
    });
  };

  const getBillingList = (currentTab, periodId) => {
    if (currentTab === 0) {
      // if the cuurrent tab is 0 then getting the agency bills
      dispatch(getAgencyBills(periodId)).then((resp) => {
        const { data } = resp;
        setBilligList([...data]);
      });
    } else {
      // if the cuurrent tab is 1 then getting the group bills
      dispatch(getGroupBills(periodId)).then((resp) => {
        const { data } = resp;
        setBilligList([...data]);
      });
    }
  };

  return (
    <>
      <AppContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handelTabValueChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value={0} label="Agency Billings" />
            <Tab value={1} label="Group Billings" />
          </Tabs>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            style={{ width: '200px' }}
            value={selectedPeriod}
            onChange={handelPeriodChange}
          >
            {periodsList.map((obj) => (
              <MenuItem value={obj?.billing_period_id}>
                {obj?.billingPeriod}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <p style={{ color: 'red', fontSize: '12px' }}>
            Note: If you want to get the bills of groups or agencies for
            different period then you can change the period from top right
            corner.
          </p>
        </div>

        <div>
          <Grid
            alignItems="flex-start"
            justify="space-between"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle title=""></AppPageTitle>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary>
                <AppTable
                  tableName="billing-list-table"
                  size="small"
                  orderBy=""
                  searchCol
                  data={billingList}
                  headers={
                    tabValue === 0
                      ? BILLINGS_TABLE_HEADERS_AGENCY
                      : BILLINGS_TABLE_HEADERS_GROUP
                  }
                  render={{
                    invoice_pdf_url: (props) => <DownloadPdf {...props} />,
                    report_csv_url: (props) => <DownloadCsv {...props} />,
                    approved: (props) => <BillApprovalRejection {...props} />,
                  }}
                  rowsPerPageOptions={[500, 1000]}
                  enableCellRender
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </div>
      </AppContainer>
    </>
  );
}
