import { hot } from 'react-hot-loader/root';
import React from 'react';
import AppRoutes from './routes';
import { AppRoot } from '../packages/AppContainer';
import AppHeader from '../packages/AppHeader';
import AppFooter from '../packages/AppFooter';

function AppPage() {
  return (
    <>
      <AppHeader />
      <AppRoot>
        <AppRoutes />
      </AppRoot>
      <AppFooter />
    </>
  );
}

export default hot(AppPage);
