/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import * as _ from 'lodash';

const StyledChip = withStyles((theme) => ({
  root: {
    borderColor: (props) =>
      props.color === 'error' ? theme.palette.error.main : null,
    color: (props) =>
      props.color === 'error' ? theme.palette.error.main : null,
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginLeft: '-8px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function validateEmail(email) {
  if (email && email.length > 0 && email !== '') {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    return emailRegex.test(email);
  }
  return true;
}

const validateEmails = (emails, maxEmail, required) => {
  let error = '';

  // Remove duplicates
  const uniqueEmails = Array.from(new Set(emails));

  // Validate email format
  const emailFormatRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
  const invalidEmails = uniqueEmails.filter(
    (email) => !emailFormatRegex.test(email),
  );

  if (invalidEmails.length > 0) {
    error = 'Invalid email format';
  } else if (required && uniqueEmails.length === 0) {
    error = 'At least one email is required';
  } else if (maxEmail && uniqueEmails.length > maxEmail) {
    error = `Maximum ${maxEmail} emails allowed`;
  }

  return error;
};

const AppMultipleEmailInput = (props) => {
  const {
    formField: {
      id,
      value: defaultValue,
      label,
      variant,
      maxEmail,
      type,
      name,
      multiline,
      rows,
      placeholder,
      required,
      disabled,
      startAdornment,
      boxProps,
      commonFieldHeading,
      commonFieldTypoProps,
      haveCommonField,
      changeFloatLabelColor,
    },
  } = props;

  const classes = useStyles();

  const [emails, setEmails] = useState(defaultValue || '');
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const inputEmails = event.target.value
      .split(',')
      .map((email) => email.trim());
    const errorResponse = validateEmails(inputEmails, maxEmail, required);
    setEmails(inputEmails);
    setError(errorResponse);
    props.handleChange({
      name,
      value: inputEmails,
      error: !!errorResponse,
    });
  };

  const handleBlur = () => {
    const errorResponse = validateEmails(emails, maxEmail, required);

    // Remove duplicates
    const uniqueEmails = Array.from(new Set(emails));

    props.handleChange({
      name,
      value: uniqueEmails,
      error: !!errorResponse,
    });
    setEmails(uniqueEmails);
    setError(errorResponse);
  };

  const handleDeleteEmail = (emailToDelete) => {
    const updatedEmails = [...emails];
    const indexToDelete = updatedEmails.indexOf(emailToDelete);
    if (indexToDelete > -1) {
      updatedEmails.splice(indexToDelete, 1);
    }

    const errorResponse = validateEmails(updatedEmails, maxEmail, required);

    props.handleChange({
      name,
      value: updatedEmails,
      error: !!errorResponse,
    });

    setEmails(updatedEmails);
    setError(errorResponse);
  };

  React.useEffect(() => {
    if (emails.length === 0 && props.value.length > 0)
      setEmails(props.value || defaultValue);
  }, [props.value]);

  return (
    <>
      {haveCommonField && commonFieldHeading && (
        <Typography gutterBottom {...commonFieldTypoProps}>
          {commonFieldHeading}
        </Typography>
      )}
      <Box width={1} {...boxProps}>
        <TextField
          value={emails}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={error !== ''}
          helperText={error}
          fullWidth
          id={id}
          label={label}
          variant={variant}
          type={type}
          name={name}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          InputLabelProps={{
            style: {
              color: changeFloatLabelColor ? '#5EC5BA' : 'black',
            },
          }}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : (
              ''
            ),
          }}
        />
        <Box component="ul" className={classes.root}>
          {_.map(emails, (email, index) => (
            <li key={`multi-email-chip-list-${index}`}>
              <StyledChip
                label={email}
                onDelete={() => handleDeleteEmail(email)}
                variant="outlined"
                color={validateEmail(email) ? 'secondary' : 'error'}
                className={classes.chip}
              />
            </li>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default AppMultipleEmailInput;
