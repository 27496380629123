import React, { PureComponent } from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { SelectComponent } from '../AppSelect';

const StyledFormLabel = styled(FormLabel)`
  color: ${(props) => props.theme.palette.text.primary};
`;

class FormCriteriaGroupComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      formField: { criteriaGroupStyle, label, values, hidden },
    } = this.props;
    return hidden ? null : (
      <FormGroup style={criteriaGroupStyle}>
        <StyledFormLabel component="legend">{label}</StyledFormLabel>
        {values.map((eachSelect) => (
          <SelectComponent
            key={eachSelect.id}
            formField={eachSelect}
            value={eachSelect.value}
          />
        ))}
      </FormGroup>
    );
  }
}

export default FormCriteriaGroupComponent;
