import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgencyAction,
  AgencyLeads,
  AgencyName,
  ToggleAgencyStatus,
  AgencyCreationDateCell,
} from '../../components/AgencyActions';
import {
  clearSelectedAgency,
  deleteAgency,
  getAgency,
} from '../../modules/Agency/actions';
import { AppButton } from '../../packages/AppButton';
import { AppContainer } from '../../packages/AppContainer';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import ConfirmationModal from '../../packages/AppModal/Confirmation';
import AppPageTitle from '../../packages/AppPageTitle';
import AppTable from '../../packages/AppTable';
import { AGENCY_LIST_TABLE_HEADERS } from './constants';
import { APP_CONSTANTS } from '../../constants';

export default function AgencyPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmDeleteAgency, toggleDeleteAgency] = useState(false);
  const [selectedAgencyId, toggleAgencySelection] = useState('');
  const agencyList = useSelector((state) => state.Agency.agencyList);
  const userType = useSelector((state) => state.User.userType);

  const toggleDeleteAgencyAction = (agencyId) => {
    toggleDeleteAgency((state) => !state);
    toggleAgencySelection(agencyId || '');
  };
  const deleteAgencyAction = () => {
    dispatch(deleteAgency(selectedAgencyId)).then((resp) => {
      if (resp?.data?.success) {
        toggleDeleteAgencyAction();
        dispatch(getAgency());
      }
    });
  };

  useEffect(() => {
    dispatch(clearSelectedAgency());
    dispatch(getAgency());
  }, []);

  return useMemo(
    () => (
      <>
        <AppContainer>
          <Grid
            alignItems="flex-start"
            justify="space-between"
            alignContent="stretch"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppPageTitle title="Agencies">
                <Grid
                  alignItems="flex-start"
                  justify="space-between"
                  alignContent="stretch"
                  spacing={2}
                  container
                >
                  {userType === 'AGENCY_ADMIN_BASIC' && (
                    <Grid item>
                      <AppButton
                        variant="text"
                        color="primary"
                        label="Add Agency"
                        handleClick={() => {
                          history.push(APP_CONSTANTS.APP_ROUTE_URLS.ADD_AGENCY);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AppPageTitle>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary>
                {userType === 'BOOMERS_ADMIN' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={
                      AGENCY_LIST_TABLE_HEADERS[userType] ||
                      AGENCY_LIST_TABLE_HEADERS.DEFAULT
                    }
                    rowsPerPageOptions={[500, 1000]}
                    enableCellRender
                    render={{
                      name: (props) => <AgencyName {...props} />,
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                      status: (props) => <ToggleAgencyStatus {...props} />,
                      total_leads: (props) => <AgencyLeads {...props} />,
                      created_date: (props) => (
                        <AgencyCreationDateCell {...props} />
                      ),
                    }}
                  />
                )}
                {userType === 'SUPER_ADMIN_2' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={
                      AGENCY_LIST_TABLE_HEADERS[userType] ||
                      AGENCY_LIST_TABLE_HEADERS.DEFAULT
                    }
                    rowsPerPageOptions={[500, 1000]}
                    enableCellRender
                    render={{
                      name: (props) => <AgencyName {...props} />,
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                      total_leads: (props) => <AgencyLeads {...props} />,
                      created_date: (props) => (
                        <AgencyCreationDateCell {...props} />
                      ),
                    }}
                  />
                )}
                {userType === 'AGENCY_ADMIN_BASIC' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={
                      AGENCY_LIST_TABLE_HEADERS[userType] ||
                      AGENCY_LIST_TABLE_HEADERS.DEFAULT
                    }
                    rowsPerPageOptions={[100, 500, 1000]}
                    enableCellRender
                    render={{
                      name: (props) => <AgencyName {...props} />,
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                      total_leads: (props) => <AgencyLeads {...props} />,
                    }}
                  />
                )}
                {userType === 'DELEGATE' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={AGENCY_LIST_TABLE_HEADERS[userType]}
                    rowsPerPageOptions={[100, 500, 1000]}
                    enableCellRender
                    render={{
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                      total_leads: (props) => <AgencyLeads {...props} />,
                    }}
                  />
                )}
                {userType === 'DELEGATE_RW' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={AGENCY_LIST_TABLE_HEADERS[userType]}
                    rowsPerPageOptions={[100, 500, 1000]}
                    enableCellRender
                    render={{
                      name: (props) => <AgencyName {...props} />,
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                      total_leads: (props) => <AgencyLeads {...props} />,
                    }}
                  />
                )}
                {userType === 'BOOMERS_ADMIN_AGENCY' && (
                  <AppTable
                    tableName="agency-list-table"
                    size="small"
                    orderBy=""
                    searchCol
                    data={agencyList}
                    headers={
                      AGENCY_LIST_TABLE_HEADERS[userType] ||
                      AGENCY_LIST_TABLE_HEADERS.DEFAULT
                    }
                    rowsPerPageOptions={[500, 1000]}
                    enableCellRender
                    render={{
                      name: (props) => <AgencyName {...props} />,
                      action: (props) => (
                        <AgencyAction
                          {...props}
                          deleteAgency={toggleDeleteAgencyAction}
                        />
                      ),
                    }}
                  />
                )}
              </ErrorBoundary>
            </Grid>
          </Grid>
        </AppContainer>
        <ConfirmationModal
          title="Delete Agency"
          isOpen={confirmDeleteAgency}
          onCancel={toggleDeleteAgencyAction}
          onConfirm={() => deleteAgencyAction()}
        >
          <Typography variant="body1">
            Do you want to continue delete the agency?
          </Typography>
        </ConfirmationModal>
      </>
    ),
    [agencyList, confirmDeleteAgency, selectedAgencyId],
  );
}
