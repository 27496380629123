import React, { useEffect, useRef, useState } from 'react';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { doLogOut } from '../../modules/Logout/actions';
import { APP_CONSTANTS } from '../../constants';
import ConfirmationModal from '../AppModal/Confirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItemIcon: {
    minWidth: 26,
  },
}));

export default function AppUserProfileMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [logOutConfirmation, toggleLogOut] = useState(false);
  const anchorRef = useRef(null);
  const loggedIn = useSelector((state) => state.Session?.isAuthenticated);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {loggedIn && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="primary.main"
            ml={2}
          >
            <Button
              variant="text"
              onClick={handleToggle}
              ref={anchorRef}
              size="large"
            >
              SETTINGS
            </Button>
          </Box>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 2 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Box mt={2}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="app-user-profile-menu"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={(event) => {
                            handleClose(event);
                            history.push(APP_CONSTANTS.APP_ROUTE_URLS.PROFILE);
                          }}
                          dense
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <AccountCircleOutlinedIcon />
                          </ListItemIcon>
                          <Typography variant="subtitle2" noWrap>
                            Profile
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            handleClose(event);
                            history.push(
                              APP_CONSTANTS.APP_ROUTE_URLS.CHANGE_PASSWORD,
                            );
                          }}
                          dense
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <VpnKeyOutlinedIcon />
                          </ListItemIcon>
                          <Typography variant="subtitle2" noWrap>
                            Password
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            handleClose(event);
                            toggleLogOut(true);
                          }}
                          dense
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <PowerSettingsNewOutlinedIcon />
                          </ListItemIcon>
                          <Typography variant="subtitle2" noWrap>
                            Logout
                          </Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Box>
              </Grow>
            )}
          </Popper>
        </>
      )}
      <ConfirmationModal
        title="Logout Confirmation"
        isOpen={logOutConfirmation}
        onCancel={() => {
          toggleLogOut(false);
        }}
        onConfirm={() => {
          dispatch(doLogOut());
        }}
      >
        <Typography variant="subtitle1">
          Are you sure you want to logout?
        </Typography>
      </ConfirmationModal>
    </>
  );
}
