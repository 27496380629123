import { LOG_OUT, RESET_STORE } from './constants';
import { APP_CONSTANTS } from '../../constants';

export const doLogOut = () => (dispatch) => {
  const localStorageName = `persist:${APP_CONSTANTS.APP_NAME}`;
  dispatch({ type: LOG_OUT });
  dispatch({ type: RESET_STORE });
  if (
    typeof Storage !== 'undefined' &&
    window?.localStorage?.getItem(localStorageName)
  ) {
    // eslint-disable-next-line no-unused-expressions
    window?.localStorage?.removeItem(localStorageName);
  }
  setTimeout(() => {
    window.location.reload();
  }, 700);
};
