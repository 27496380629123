import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { ValidateField } from '../../utils';
import { StyledTextField } from '../AppStyledComponents';

const TextMaskPhone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
      keepCharPositions={false}
    />
  );
};

TextMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function PhoneFieldComponent(props) {
  const {
    formField: {
      id,
      value: defaultValue,
      label,
      variant,
      type,
      name,
      multiline,
      rows,
      placeholder,
      regExpValue,
      required,
      disabled,
      typographyProps,
      commonFieldHeading,
      haveCommonField,
      boxProps,
      hidden,
    },
  } = props;
  const [fieldValue, setFieldValue] = useState('');
  const [error, setError] = useState(false);
  const handleErrorField = (val, regExpVal) =>
    ValidateField(val, regExpVal, required);

  // const handleErrorField = (val, regExpVal) =>
  //   required ? ValidateField(val, regExpVal) : false;

  const handleTextFieldChange = () => (event) => {
    const {
      target: { checked, value, type: fieldType },
    } = event;
    const actValue = fieldType === 'checkbox' ? checked : value;
    const err = handleErrorField(actValue, regExpValue);
    const number = actValue.replace(/[^0-9]/g, '');
    setFieldValue(actValue);
    setError(err);
    props.handleChange({ name, value: number, error: err });
  };

  useEffect(() => {
    setFieldValue(props.value || defaultValue);
  }, [props.value]);

  return useMemo(
    () =>
      hidden ? null : (
        <>
          {haveCommonField && commonFieldHeading && (
            <Typography variant="caption" gutterBottom {...typographyProps}>
              {commonFieldHeading}
            </Typography>
          )}
          {haveCommonField && <Box width={1} {...boxProps} />}
          <StyledTextField
            id={id}
            label={label}
            variant={variant}
            type={type}
            name={name}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder}
            required={required}
            value={fieldValue}
            error={error}
            disabled={disabled}
            InputProps={{
              inputComponent: TextMaskPhone,
              value: fieldValue,
              onChange: handleTextFieldChange(name, required, regExpValue),
            }}
          />
        </>
      ),
    [
      error,
      fieldValue,
      handleTextFieldChange,
      id,
      label,
      multiline,
      name,
      placeholder,
      regExpValue,
      required,
      rows,
      type,
      variant,
    ],
  );
}

export default PhoneFieldComponent;
