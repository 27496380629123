import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import {
  getAllLeadsByAgencyId,
  activateLead,
  deactivateLead,
} from '../../modules/Leads/actions';
import IOSSwitch from '../../packages/AppIOSSwitch';
import { APP_CONSTANTS } from '../../constants';

export function LeadsActions(props) {
  const {
    agencyId,
    row: { lead_id },
  } = props;
  const history = useHistory();
  const actionHandler = (event) => {
    event.preventDefault();
    if (agencyId) {
      history.push(
        `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agencyId}/lead/${lead_id}`,
      );
    } else {
      history.push(`${APP_CONSTANTS.APP_ROUTE_URLS.LEAD_DETAILS}${lead_id}`);
    }
  };
  return (
    <TableCell
      align="center"
      size="small"
      style={{
        width: props.width,
        textAlign: 'center',
        //   /* maxWidth: 0,
        //   overflow: 'hidden',
        //   textOverflow: 'ellipsis',
        //   whiteSpace: 'nowrap', */
      }}
    >
      <IconButton size="small" color="secondary" onClick={actionHandler}>
        <VisibilityOutlinedIcon />
      </IconButton>
    </TableCell>
  );
}

export function ToggleLeadStatus(props) {
  const {
    agencyId,
    row: { lead_id, billable },
  } = props;

  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(_.toLower(billable) === 'true');
  }, [billable]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(activateLead(lead_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
          dispatch(getAllLeadsByAgencyId(agencyId));
        }
      });
    } else {
      dispatch(deactivateLead(lead_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
          dispatch(getAllLeadsByAgencyId(agencyId));
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
        textAlign: 'left',
      }}
    >
      <IOSSwitch checked={active} onChange={handleChange} name={lead_id} />
    </TableCell>
  );
}
