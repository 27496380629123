import * as _ from 'lodash';
import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  NOTIFIER_AUTO_HIDE,
  CLOSE_SNACKBAR,
} from './constant';
import { httpStatusCodeMapToVariant } from '../../utils';

export const enqueueSnackbar = (notification) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: _.uniqueId('notifier_'),
    options: {
      autoHideDuration: NOTIFIER_AUTO_HIDE,
      variant: httpStatusCodeMapToVariant(notification.statusCode),
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      preventDuplicate: true,
    },
    ...notification,
  },
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});
