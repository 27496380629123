import { SET_TOKEN, TOKEN_INITIAL_STATE } from './constants';
import { LOG_OUT } from '../Logout/constants';

export function Reducer(state = TOKEN_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        loadedAt: new Date().toJSON(),
      };
    case LOG_OUT:
      return TOKEN_INITIAL_STATE;
    default:
      return state;
  }
}
