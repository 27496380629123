/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useReducer } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import Box from '@material-ui/core/Box';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import { AppContainer } from '../../packages/AppContainer';
import {
  convertTime12to24,
  convertTimeToAMPM,
  debounced,
  validateForm,
} from '../../utils';
import { defaultFieldError, requiredFields } from './constants';
import FormFields from './FormField';
import FormGroupComponent from '../../packages/AppFormGroup';
import {
  getAgency,
  getAgencyById,
  updateAgency,
  setSelectedAgency,
} from '../../modules/Agency/actions';
import AppCard from '../../packages/AppCard';
import {
  COUNTRY_STATES,
  COUNTRY_LABELS,
  COUNTRY_REGEX,
} from '../../data/config';
import ClientConversationPreferences from '../../components/ClientConversationPreferences';
import EmployeeScreeningPreferences from '../../components/EmployeeScreeningPreferences';
import AvailabilitySlotPreferences from '../../components/AvailabilitySlotPreferences';

const OPTIONAL_FIELDS_WITH_VALIDATION = [
  'client_lead_notifications_emails',
  'employee_lead_notifications_emails',
  'franchisee_lead_notifications_emails',
  'client_lead_notification_phones',
  'employee_lead_notifications_phones',
  'franchisee_lead_notifications_phones',
];

const initialState = {
  formValue: {},
  lastFieldNameChanged: '',
  fieldError: { ...defaultFieldError },
  validForm: false,
  validOptionalField: false,
  loading: false,
  agencyObj: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUE':
      return { ...state, formValue: { ...action.payload } };
    case 'UPDATE_FORM_VALUE':
      return {
        ...state,
        formValue: {
          ...state.formValue,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_LAST_FIELD_NAME_CHANGED_VALUE':
      return { ...state, lastFieldNameChanged: action.payload };
    case 'SET_FIELD_ERROR':
      return { ...state, fieldError: action.payload };
    case 'UPDATE_FIELD_ERROR':
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_VALID_FORM':
      return { ...state, validForm: action.payload };
    case 'SET_VALID_OPTIONAL_FIELD':
      return { ...state, validOptionalField: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_AGENCY':
      return { ...state, agencyObj: action.payload };
    default:
      throw new Error('Unknown action type');
  }
};

export default function UpdateBasicAgencyPage() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    formValue,
    fieldError,
    validForm,
    validOptionalField,
    loading,
    agencyObj,
    lastFieldNameChanged,
  } = state;

  const timezones = useSelector((rState) => rState.MetaData.timezones);
  const paymentOption = useSelector((rState) => rState.MetaData.paymentOption);
  const agencyType = useSelector((rState) => rState.MetaData.agencyType);
  const serviceProvided = useSelector(
    (rState) => rState.MetaData.serviceProvided,
  );
  const userType = useSelector((rState) => rState.User.userType);
  const serviceProvidedADC = useSelector(
    (rState) => rState.MetaData.serviceProvidedADC,
  );
  const serviceProvidedALF = useSelector(
    (rState) => rState.MetaData.serviceProvidedALF,
  );

  const history = useHistory();
  const rDispatch = useDispatch();
  const params = useParams();
  const { agencyId } = params;

  let clientConversationData = {
    coverageAreaCheck: formValue?.agency_preferences?.coverageAreaCheck,
  };

  (function () {
    if (formValue?.agency_preferences?.clientFollowUpTerm) {
      clientConversationData = {
        ...clientConversationData,
        clientFollowUpTerm: formValue?.agency_preferences?.clientFollowUpTerm,
      };
    }
  })();

  const employeeScreeningData = {
    legallyWorkInUS: formValue?.agency_preferences?.legallyWorkInUS,
    workCities: formValue?.agency_preferences?.workCities,
    certifications: formValue?.agency_preferences?.certifications,
    validDriverLicenseAndtransportation:
      formValue?.agency_preferences?.validDriverLicenseAndtransportation,
    ownQualification: formValue?.agency_preferences?.ownQualification,
  };

  const setFormValue = (value) => {
    dispatch({ type: 'SET_FORM_VALUE', payload: value });
  };

  const updateFormValue = (name, value) => {
    dispatch({ type: 'UPDATE_FORM_VALUE', payload: { name, value } });
  };

  const setFieldError = (error) => {
    dispatch({ type: 'SET_FIELD_ERROR', payload: error });
  };

  const updateFieldError = (name, value) => {
    dispatch({ type: 'UPDATE_FIELD_ERROR', payload: { name, value } });
  };

  const setValidForm = (isValid) => {
    dispatch({ type: 'SET_VALID_FORM', payload: isValid });
  };

  const setValidOptionalField = (isValid) => {
    dispatch({ type: 'SET_VALID_OPTIONAL_FIELD', payload: isValid });
  };

  const setLoading = (isLoading) => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  };

  const setAgency = (agency) => {
    dispatch({ type: 'SET_AGENCY', payload: agency });
  };

  const setLastFieldNameValue = (fields) => {
    dispatch({ type: 'SET_LAST_FIELD_NAME_CHANGED_VALUE', payload: fields });
  };

  const doFormValidation = debounced(400, () => {
    setValidForm(validateForm({ requiredFields, fieldError }));
  });

  const findInputFieldIndex = (fieldName) => {
    let formFieldsIndex;
    FormFields.forEach((iterratedObj, iteratedObjIndex) => {
      if (Object.keys(iterratedObj.list).includes(fieldName)) {
        formFieldsIndex = iteratedObjIndex;
      }
    });
    return formFieldsIndex;
  };

  const handleChange = ({ name, value, error }) => {
    let updatedValidOptionalField = false;

    if (_.includes(OPTIONAL_FIELDS_WITH_VALIDATION, name)) {
      updatedValidOptionalField = error;
    }

    updateFormValue(name, value);
    updateFieldError(name, error);
    setLastFieldNameValue(`${name}+=${value}`);
    setValidOptionalField(updatedValidOptionalField);
  };

  const onAdditionalPreferencesChange = (key, value, error) => {
    const FIELD_ERROR_KEY_MAPPING = {
      employeeInterviewBookingPreferences:
        'employeeInterviewBookingPreferences',
      clientBookingPreferences: 'clientBookingPreferences',
      legallyWorkInUS: 'employeeScreeningPrefrences',
      workCities: 'employeeScreeningPrefrences',
      certifications: 'employeeScreeningPrefrences',
      validDriverLicenseAndtransportation: 'employeeScreeningPrefrences',
      ownQualification: 'employeeScreeningPrefrences',
      clientFollowUpTerm: 'clientScreeningPrefrences',
      coverageAreaCheck: 'clientScreeningPrefrences',
    };
    const agencyPref = { ...formValue?.agency_preferences, [key]: value };
    updateFormValue('agency_preferences', agencyPref);
    updateFieldError(FIELD_ERROR_KEY_MAPPING[key], error);
  };

  function getNullForEmptyString(dayWeekHours) {
    return dayWeekHours === '' ? null : dayWeekHours;
  }

  const formSubmitHandler = () => {
    setLoading(true);
    setValidForm(false);
    const formData = new FormData();

    formData.append(
      'agency_details',
      new Blob(
        [
          JSON.stringify({
            agency_details: {
              ...agencyObj,
              ...formValue,
              agency_preferences: {
                ...formValue.agency_preferences,
                clientBookingPreferences:
                  formValue?.agency_preferences?.clientBookingPreferences,

                employeeInterviewBookingPreferences:
                  formValue?.agency_preferences
                    ?.employeeInterviewBookingPreferences,
              },
              agency_type: {
                agency_type: formValue?.agency_type
                  ? [formValue?.agency_type]
                  : agencyObj?.agency_type?.agency_type,
              },

              lead_notifications_emails: {
                client_lead_notifications_emails:
                  formValue?.client_lead_notifications_emails ||
                  agencyObj?.client_lead_notifications_emails,
                employee_lead_notifications_emails:
                  formValue?.employee_lead_notifications_emails ||
                  agencyObj?.employee_lead_notifications_emails,
                franchisee_lead_notifications_emails:
                  formValue?.franchisee_lead_notifications_emails ||
                  agencyObj?.franchisee_lead_notifications_emails,
                billing_notifications_emails:
                  formValue?.billing_notifications_email ||
                  agencyObj?.billing_notifications_email,
              },
              lead_notification_phones: {
                client_lead_notification_phones:
                  formValue?.client_lead_notification_phones ||
                  agencyObj?.client_lead_notification_phones,
                employee_lead_notifications_phones:
                  formValue?.employee_lead_notifications_phones ||
                  agencyObj?.employee_lead_notifications_phones,
                franchisee_lead_notifications_phones:
                  formValue?.franchisee_lead_notifications_phones ||
                  agencyObj?.franchisee_lead_notifications_phones,
              },
              min_hours_per_visit:
                'min_hours_per_visit' in formValue
                  ? getNullForEmptyString(formValue?.min_hours_per_visit)
                  : getNullForEmptyString(agencyObj?.min_hours_per_visit),
              min_hours_per_day:
                'min_hours_per_day' in formValue
                  ? getNullForEmptyString(formValue?.min_hours_per_day)
                  : getNullForEmptyString(agencyObj?.min_hours_per_day),
              min_hours_per_week:
                'min_hours_per_week' in formValue
                  ? getNullForEmptyString(formValue?.min_hours_per_week)
                  : getNullForEmptyString(agencyObj?.min_hours_per_week),
              payment_option: {
                payment_option:
                  'payment_option' in formValue
                    ? formValue?.payment_option
                    : agencyObj?.payment_option?.payment_option,
              },
              service_provided: {
                service_provided:
                  formValue?.service_provided ||
                  agencyObj?.service_provided?.service_provided,
              },
              t_and_c_accepted_date: agencyObj?.terms_and_conditon_accepted_date
                ? new Date(agencyObj?.terms_and_conditon_accepted_date)
                : '',
              office_hours_from: formValue?.office_hours_from
                ? convertTimeToAMPM(formValue?.office_hours_from)
                : agencyObj.office_hours_from,
              office_hours_to: formValue?.office_hours_to
                ? convertTimeToAMPM(formValue?.office_hours_to)
                : agencyObj.office_hours_to,
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    rDispatch(updateAgency(agencyId, formData))
      .then((resp) => {
        if (resp?.data?.success) {
          rDispatch(getAgencyById(agencyId));
        }
        setValidForm(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setValidForm(true);
      });
  };

  useEffect(() => {
    if (lastFieldNameChanged !== '') {
      const updatedFormValue = { ...formValue };
      const updatedFieldError = { ...fieldError };

      const name = lastFieldNameChanged.split('+=')[0];
      const value = lastFieldNameChanged.split('+=')[1];

      if (name === 'agency_type') {
        FormFields[
          findInputFieldIndex('service_provided')
        ].list.service_provided.values = [];
        FormFields[
          findInputFieldIndex('min_hours_per_visit')
        ].list.min_hours_per_visit.hidden = false;
        FormFields[
          findInputFieldIndex('min_hours_per_day')
        ].list.min_hours_per_day.hidden = false;
        FormFields[
          findInputFieldIndex('min_hours_per_week')
        ].list.min_hours_per_week.hidden = false;
        FormFields[
          findInputFieldIndex('payment_option')
        ].list.payment_option.width = 50;
        let sp = serviceProvided;
        updatedFormValue.service_provided = '';
        updatedFieldError.service_provided = true;
        updatedFormValue['24hour'] = false;
        FormFields[
          findInputFieldIndex('office_hours_from')
        ].list.office_hours_from.disabled = false;
        FormFields[
          findInputFieldIndex('office_hours_to')
        ].list.office_hours_to.disabled = false;
        updatedFormValue.office_hours_from = '09:00';
        updatedFormValue.office_hours_to = '17:00';

        if (_.includes(value, 'Adult Day Care Facility')) {
          sp = serviceProvidedADC;
        }

        if (_.includes(value, 'Assisted Living Facility')) {
          FormFields[
            findInputFieldIndex('min_hours_per_visit')
          ].list.min_hours_per_visit.hidden = true;
          FormFields[
            findInputFieldIndex('min_hours_per_day')
          ].list.min_hours_per_day.hidden = true;
          FormFields[
            findInputFieldIndex('min_hours_per_week')
          ].list.min_hours_per_week.hidden = true;
          FormFields[
            findInputFieldIndex('payment_option')
          ].list.payment_option.width = 100;
          updatedFormValue.min_hours_per_visit = null;
          updatedFormValue.min_hours_per_day = null;
          updatedFormValue.min_hours_per_week = null;
          sp = serviceProvidedALF;
          updatedFormValue['24hour'] = true;
          FormFields[
            findInputFieldIndex('office_hours_from')
          ].list.office_hours_from.disabled = true;
          FormFields[
            findInputFieldIndex('office_hours_to')
          ].list.office_hours_to.disabled = true;
          updatedFormValue.office_hours_from = '00:00';
          updatedFormValue.office_hours_to = '23:59';
        }

        _.forEach(sp, (avl) => {
          FormFields[
            findInputFieldIndex('service_provided')
          ].list.service_provided.values.push({
            label: avl,
            value: avl,
          });
        });
      }

      if (name === 'country') {
        updatedFormValue.state = '';
        updatedFormValue.zip_postal_code = '';
        updatedFieldError.state = true;
        updatedFieldError.zip_postal_code = true;
        FormFields[findInputFieldIndex('state')].list.state.values =
          COUNTRY_STATES[value];
        FormFields[findInputFieldIndex('state')].list.state.label =
          COUNTRY_LABELS[value].state;
        FormFields[
          findInputFieldIndex('zip_postal_code')
        ].list.zip_postal_code.label = COUNTRY_LABELS[value].zip;
        FormFields[
          findInputFieldIndex('zip_postal_code')
        ].list.zip_postal_code.regExpValue = COUNTRY_REGEX[value];
      }

      if (name === '24hour') {
        if (value === 'true') {
          FormFields[
            findInputFieldIndex('office_hours_from')
          ].list.office_hours_from.disabled = true;
          FormFields[
            findInputFieldIndex('office_hours_to')
          ].list.office_hours_to.disabled = true;
          updatedFormValue.office_hours_from = '00:00';
          updatedFormValue.office_hours_to = '23:59';
        } else {
          FormFields[
            findInputFieldIndex('office_hours_from')
          ].list.office_hours_from.disabled = false;
          FormFields[
            findInputFieldIndex('office_hours_to')
          ].list.office_hours_to.disabled = false;
          updatedFormValue.office_hours_from = '09:00';
          updatedFormValue.office_hours_to = '17:00';
        }
      }

      setFormValue({
        ...updatedFormValue,
      });
      setFieldError({
        ...updatedFieldError,
      });
    }
  }, [lastFieldNameChanged]);

  useEffect(() => {
    FormFields[findInputFieldIndex('agency_type')].list.agency_type.values = [];
    FormFields[
      findInputFieldIndex('office_hours_timezone')
    ].list.office_hours_timezone.values = [];
    FormFields[
      findInputFieldIndex('payment_option')
    ].list.payment_option.values = [];

    _.forEach(agencyType, (agencyTypeVal) => {
      FormFields[
        findInputFieldIndex('agency_type')
      ].list.agency_type.values.push({
        label: agencyTypeVal,
        value: agencyTypeVal,
      });
    });

    _.forEach(timezones, (tz) => {
      FormFields[
        findInputFieldIndex('office_hours_timezone')
      ].list.office_hours_timezone.values.push({
        label: tz,
        value: tz,
      });
    });

    _.forEach(paymentOption, (po) => {
      FormFields[
        findInputFieldIndex('payment_option')
      ].list.payment_option.values.push({
        label: po,
        value: po,
      });
    });
  }, [timezones, paymentOption, agencyType]);

  useEffect(() => {
    doFormValidation();
  }, [formValue]);

  useEffect(() => {
    if (userType && userType.length > 0 && userType !== 'BOOMERS_ADMIN') {
      FormFields[findInputFieldIndex('UUID')].list.UUID.disabled = true;
      FormFields[
        findInputFieldIndex('search_url')
      ].list.search_url.disabled = true;
      FormFields[
        findInputFieldIndex('admin_notes')
      ].list.admin_notes.disabled = true;
    }
  }, [userType]);

  useEffect(() => {
    rDispatch(getAgencyById(agencyId)).then((resp) => {
      if (resp?.data) {
        const { data } = resp;
        let sp = serviceProvided;
        rDispatch(setSelectedAgency(data));

        FormFields[
          findInputFieldIndex('service_provided')
        ].list.service_provided.values = [];
        FormFields[findInputFieldIndex('state')].list.state.values =
          COUNTRY_STATES[data?.country];
        FormFields[findInputFieldIndex('state')].list.state.label =
          COUNTRY_LABELS[data?.country]?.state;
        FormFields[
          findInputFieldIndex('zip_postal_code')
        ].list.zip_postal_code.label = COUNTRY_LABELS[data?.country]?.zip;
        FormFields[
          findInputFieldIndex('zip_postal_code')
        ].list.zip_postal_code.regExpValue = COUNTRY_REGEX[data?.country];
        if (
          _.includes(data?.agency_type?.agency_type, 'Adult Day Care Facility')
        ) {
          sp = serviceProvidedADC;
        }
        if (
          _.includes(data?.agency_type?.agency_type, 'Assisted Living Facility')
        ) {
          sp = serviceProvidedALF;
          FormFields[
            findInputFieldIndex('min_hours_per_visit')
          ].list.min_hours_per_visit.hidden = true;
          FormFields[
            findInputFieldIndex('min_hours_per_day')
          ].list.min_hours_per_day.hidden = true;
          FormFields[
            findInputFieldIndex('min_hours_per_week')
          ].list.min_hours_per_week.hidden = true;
        }

        _.forEach(sp, (avl) => {
          FormFields[
            findInputFieldIndex('service_provided')
          ].list.service_provided.values.push({
            label: avl,
            value: avl,
          });
        });

        const updatedAgencyFormData = {
          ...data,
          '24hour':
            convertTime12to24(data?.office_hours_from) === '00:00' &&
            convertTime12to24(data?.office_hours_to) === '23:59',
          payment_option: data?.payment_option?.payment_option,
          min_hours_per_day: data?.min_hours_per_day,
          min_hours_per_visit: data?.min_hours_per_visit,
          min_hours_per_week: data?.min_hours_per_week,
          agency_type: _.head(_.flattenDeep(data?.agency_type?.agency_type)),
          service_provided: data?.service_provided?.service_provided,
          office_hours_to: convertTime12to24(data?.office_hours_to),
          office_hours_from: convertTime12to24(data?.office_hours_from),
          client_lead_notifications_emails:
            data?.lead_notifications_emails?.client_lead_notifications_emails,
          employee_lead_notifications_emails:
            data?.lead_notifications_emails?.employee_lead_notifications_emails,
          franchisee_lead_notifications_emails:
            data?.lead_notifications_emails
              ?.franchisee_lead_notifications_emails,
          client_lead_notification_phones:
            data?.lead_notification_phones?.client_lead_notification_phones,
          employee_lead_notifications_phones:
            data?.lead_notification_phones?.employee_lead_notifications_phones,
          franchisee_lead_notifications_phones:
            data?.lead_notification_phones
              ?.franchisee_lead_notifications_phones,
        };
        setAgency({ ...updatedAgencyFormData });
        setFormValue({ ...updatedAgencyFormData });
      }
    });
  }, [agencyId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Update Agency">
            <AppButton
              variant="text"
              color="primary"
              label="Back to Agencies"
              handleClick={() => {
                history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
              }}
            />
          </AppPageTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid
            direction="column"
            alignItems="center"
            justify="center"
            alignContent="center"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppCard
                content={
                  <>
                    {FormFields.map((ele) => {
                      const filteredData = {};
                      const keys = Object.keys(ele?.list);
                      keys.forEach((key) => {
                        const data = ele?.list[key];
                        if (
                          !data?.showToUserType ||
                          (data?.showToUserType &&
                            data?.showToUserType?.includes(userType))
                        ) {
                          filteredData[key] = data;
                        }
                      });
                      const filteredEle = {
                        ...ele,
                        list: filteredData,
                      };

                      return (
                        <div
                          style={{
                            border: '2px solid lightgrey',
                            marginBottom: '20px',
                            borderRadius: '10px',
                            padding: '10px',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                          }}
                        >
                          <h2
                            style={{ marginBottom: '15px', color: '#5EC5BA' }}
                          >
                            {ele?.CardHeading}
                          </h2>
                          <div>
                            <FormGroupComponent
                              handleChange={handleChange}
                              formFields={filteredEle}
                              values={formValue}
                            />
                          </div>
                        </div>
                      );
                    })}

                    {formValue?.show_preferences && (
                      <Grid container spacing={2} style={{ paddingTop: '5px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={5}>
                          <div
                            style={{
                              border: '1px solid lightgray',
                              borderRadius: '10px',
                              height: '430px',
                              overflowY: 'auto',
                              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                            }}
                          >
                            <ClientConversationPreferences
                              apiFormData={clientConversationData}
                              handleChange={onAdditionalPreferencesChange}
                            ></ClientConversationPreferences>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7}>
                          <div
                            style={{
                              border: '1px solid lightgray',
                              borderRadius: '10px',
                              height: '430px',
                              overflowY: 'auto',
                              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                            }}
                          >
                            <AvailabilitySlotPreferences
                              apiFormData={
                                formValue?.agency_preferences
                                  ?.clientBookingPreferences || []
                              }
                              pageType="appointment_booking"
                              handleChange={onAdditionalPreferencesChange}
                              formDataKey="clientBookingPreferences"
                            ></AvailabilitySlotPreferences>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={5}>
                          <div
                            style={{
                              border: '1px solid lightgray',
                              borderRadius: '10px',
                              height: '430px',
                              overflowY: 'auto',
                              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                            }}
                          >
                            <EmployeeScreeningPreferences
                              apiFormData={employeeScreeningData}
                              handleChange={onAdditionalPreferencesChange}
                            ></EmployeeScreeningPreferences>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7}>
                          <div
                            style={{
                              border: '1px solid lightgray',
                              borderRadius: '10px',
                              height: '430px',
                              overflowY: 'auto',
                              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                            }}
                          >
                            <AvailabilitySlotPreferences
                              apiFormData={
                                formValue?.agency_preferences
                                  ?.employeeInterviewBookingPreferences || []
                              }
                              pageType="interview_booking"
                              handleChange={onAdditionalPreferencesChange}
                              formDataKey="employeeInterviewBookingPreferences"
                            ></AvailabilitySlotPreferences>
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      paddingTop={2}
                    >
                      <AppButton
                        label="Cancel"
                        loaderColor="secondary"
                        handleClick={() =>
                          history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES)
                        }
                      />
                      <AppButton
                        marginleft={1}
                        variant="contained"
                        color="primary"
                        loaderColor="secondary"
                        label="Update Agency"
                        disabled={!validForm || validOptionalField}
                        loading={loading}
                        loaderSize={24}
                        handleClick={formSubmitHandler}
                      />
                    </Box>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
