import * as _ from 'lodash';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import React, { PureComponent } from 'react';
import AppAdapterLink from '../AppAdapterLink';

export const StyledLink = styled(Link)`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.typography.fontSize}px;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.text.primary};
  &.active,
  &:hover {
    color: ${(props) =>
      props.hovercolor
        ? _.get(props.theme, `mui.palette.${props.hovercolor}.main`)
        : props.theme.palette.primary.main};
    text-decoration: underline;
  }
`;

class LinkComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      path,
      title,
      children,
      color,
      hovercolor,
      activeclassname,
      fontSize,
    } = this.props;
    return (
      <StyledLink
        fontSize={fontSize}
        component={AppAdapterLink}
        to={path}
        color={color}
        hovercolor={hovercolor}
        activeclassname={activeclassname}
        {...this.props}
      >
        {title}
        {children}
      </StyledLink>
    );
  }
}

export default LinkComponent;
