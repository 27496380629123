import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

/**
 * @constant
 * AdapterLink component
 * @summary Returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export default React.forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} />
));
