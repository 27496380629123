import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import {
  StyledDataList,
  StyledDivider,
} from '../../packages/AppStyledComponents';
import { getAgencyById, setSelectedAgency } from '../../modules/Agency/actions';

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  min-height: 100%;
`;

export default function AgencyDetailsPage() {
  const [agencyObj, setAgency] = useState({});
  const userType = useSelector((state) => state.User.userType);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { agencyId } = params;

  useEffect(() => {
    dispatch(getAgencyById(agencyId)).then((resp) => {
      setAgency(resp.data);
      dispatch(setSelectedAgency(resp.data));
    });
  }, [agencyId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Agency Details">
            <Box display="flex">
              <AppButton
                variant="text"
                color="primary"
                label="Back to Agencies"
                handleClick={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
                }}
              />
              {userType !== 'DELEGATE' && (
                <AppButton
                  variant="text"
                  color="primary"
                  label="Edit Agency"
                  handleClick={() => {
                    history.push(
                      `${
                        agencyObj?.package_type === 'BASIC'
                          ? APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY
                          : APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY
                      }/${agencyObj?.agency_id}`,
                    );
                  }}
                />
              )}
            </Box>
          </AppPageTitle>
        </Grid>
        {agencyObj.package_type !== 'BASIC' && (
          <Grid item xs={12} sm={4}>
            <StyledCard elevation={0}>
              <CardHeader
                title={
                  <Typography variant="subtitle1">Theme and Logo</Typography>
                }
              />
              <StyledDivider />
              <CardContent>
                <StyledDataList w={150}>
                  <li>
                    <span>Agency Logo</span>
                    {agencyObj?.logo_image_url && (
                      <img
                        style={{ maxWidth: '100%', width: 80, height: 'auto' }}
                        src={agencyObj?.logo_image_url}
                        alt="agency"
                      />
                    )}
                  </li>
                  <li>
                    <span>Primary Color</span>
                    <span
                      style={{
                        padding: 16,
                        backgroundColor: agencyObj?.primary_site_color,
                      }}
                    >
                      {agencyObj?.primary_site_color}
                    </span>
                  </li>
                  <li>
                    <span>Secondary Color</span>
                    <span
                      style={{
                        padding: 16,
                        backgroundColor: agencyObj?.secondary_site_color,
                      }}
                    >
                      {agencyObj?.secondary_site_color}
                    </span>
                  </li>
                </StyledDataList>
              </CardContent>
            </StyledCard>
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <StyledCard elevation={0}>
            <CardHeader
              title={
                <Typography variant="subtitle1">
                  Agency Basic Information
                </Typography>
              }
            />
            <StyledDivider />
            <CardContent>
              <StyledDataList w={140}>
                <li>
                  <span>Name</span>
                  <span>{agencyObj?.name}</span>
                </li>
                <li>
                  <span>UUID</span>
                  <span>{agencyObj?.UUID}</span>
                </li>
                <li>
                  <span>Website</span>
                  <span>{agencyObj?.agency_website_url}</span>
                </li>
                <li>
                  <span>Country</span>
                  <span>{agencyObj?.country}</span>
                </li>
                <li>
                  <span>Address</span>
                  <span>{agencyObj?.address}</span>
                </li>
                <li>
                  <span>City</span>
                  <span>{agencyObj?.city}</span>
                </li>
                <li>
                  <span>State</span>
                  <span>{agencyObj?.state}</span>
                </li>
                <li>
                  <span>Zip Code</span>
                  <span>{agencyObj?.zip_postal_code}</span>
                </li>
              </StyledDataList>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard elevation={0}>
            <CardHeader
              title={
                <Typography variant="subtitle1">
                  Additional Information
                </Typography>
              }
            />
            <StyledDivider />
            <CardContent>
              <StyledDataList w={190}>
                <li>
                  <span>Agency Type</span>
                  <span>
                    {_.map(
                      agencyObj?.agency_type?.agency_type,
                      (at) => `${at}, `,
                    )}
                  </span>
                </li>
                <li>
                  <span>Service Provided</span>
                  {userType === 'AGENCY_ADMIN_BASIC' ? (
                    <span>
                      {agencyObj?.service_provided?.service_provided.join(', ')}
                    </span>
                  ) : (
                    <span>{agencyObj?.availability}</span>
                  )}
                </li>
                <li>
                  <span>Working Hours</span>
                  <span>
                    {agencyObj?.office_hours_from} -{' '}
                    {agencyObj?.office_hours_to}
                  </span>
                </li>
                <li>
                  <span>Timezone</span>
                  <span>{agencyObj?.office_hours_timezone}</span>
                </li>
                <li>
                  <span>Leads</span>
                  <span>{agencyObj?.total_leads || 0}</span>
                </li>
                {userType === 'BOOMERS_ADMIN' && (
                  <li>
                    <span>Caregivers</span>
                    <span>{agencyObj?.total_active_caregivers || 0}</span>
                  </li>
                )}
                <li>
                  <span>Affiliate</span>
                  <span>{agencyObj?.affiliate}</span>
                </li>
                <li>
                  <span>Agency Info</span>
                  <span>{agencyObj?.description}</span>
                </li>
                {agencyObj?.payment_option?.payment_option &&
                  agencyObj?.payment_option?.payment_option.length > 0 && (
                    <li>
                      <span>Payment Options</span>
                      <span>
                        {agencyObj?.payment_option?.payment_option.join(', ')}
                      </span>
                    </li>
                  )}

                {agencyObj?.min_hours_per_visit && (
                  <li>
                    <span>Min Hours / Visit</span>
                    <span>{`${agencyObj?.min_hours_per_visit} hours`}</span>
                  </li>
                )}
                {agencyObj?.min_hours_per_day && (
                  <li>
                    <span>Min Hours / Day</span>
                    <span>{`${agencyObj?.min_hours_per_day} hours`}</span>
                  </li>
                )}

                {agencyObj?.min_hours_per_week && (
                  <li>
                    <span>Min Hours / Week</span>
                    <span>{`${agencyObj?.min_hours_per_week} hours`}</span>
                  </li>
                )}
                {agencyObj?.pricing_hour_detail && (
                  <li>
                    <span>Pricing and Hours Details</span>
                    <span>{agencyObj?.pricing_hour_detail}</span>
                  </li>
                )}
                <li>
                  <span>Coverage Area Cities</span>
                  <span>{agencyObj?.coverage_area}</span>
                </li>
                <li>
                  <span>Coverage Area Zip Code</span>
                  <span>{agencyObj?.coverage_area_cities}</span>
                </li>
              </StyledDataList>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard elevation={0}>
            <CardHeader
              title={
                <Typography variant="subtitle1">Email & Phones</Typography>
              }
            />
            <StyledDivider />
            <CardContent>
              <StyledDataList w={150}>
                {_.map(
                  agencyObj?.lead_notifications_emails,
                  (emails, client) => (
                    <li
                      key={`emails-${client}`}
                      className="list-with-email-phone"
                    >
                      <span>{_.lowerCase(client)}</span>
                      <span>{_.join(emails, ', ')}</span>
                    </li>
                  ),
                )}
              </StyledDataList>

              <StyledDataList w={150}>
                {_.map(
                  agencyObj?.lead_notification_phones,
                  (phones, client) => (
                    <li
                      key={`phone-${client}`}
                      className="list-with-email-phone"
                    >
                      <span>{_.lowerCase(client)}</span>
                      <span>{_.join(phones, ', ')}</span>
                    </li>
                  ),
                )}
              </StyledDataList>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
