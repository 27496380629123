import React, { useEffect, useState } from 'react';
// import * as _ from 'lodash';
import Fade from '@material-ui/core/Fade';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { APP_CONSTANTS } from '../constants';
import DashboardPage from '../pages/Dashboard';
import CaregiversPage from '../pages/Caregivers';
import AddCaregiversPage from '../pages/AddCaregivers';
import UploadCaregiversPage from '../pages/UploadCaregivers';
import LeadsPage from '../pages/Leads';
import LeadDetailsPage from '../pages/LeadDetails';
import UpdateCaregiverPage from '../pages/UpdateCaregiver';
import AgencyPage from '../pages/Agencies';
import AddAgencyPage from '../pages/AddAgency';
import ReportPage from '../pages/Report';
import GroupPage from '../pages/Groups';
import AgencyDetailsPage from '../pages/AgencyDetails';
import GroupDetailsPage from '../pages/GroupDetails';
import UpdateAgencyPage from '../pages/UpdateAgency';
import UpdateGroupPage from '../pages/UpdateGroup';
import AppProgressIndicator from '../packages/AppProgressIndicator';
import { getAgency } from '../modules/Agency/actions';
import { getGroup } from '../modules/Group/actions';
// import { setTheme } from '../modules/Theme/actions';
import ProfilePage from '../pages/Profile';
import ChangePasswordPage from '../pages/ChangePassword';
import { doLogOut } from '../modules/Logout/actions';
import UpdateBasicAgencyPage from '../pages/UpdateBasicAgency';
import PaymentPage from '../pages/Payment';
import GroupPayment from '../pages/GroupPayment';
import UserPage from '../pages/Users';
// import AddUserPage from '../pages/AddUser';
import AgencyUserAccess from '../pages/AgencyUserAccess';
import AddUserAndPermission from '../pages/AddUserAndPermission';
import Billings from '../pages/Billings';
// import ThemesPage from '../pages/Themes';

export default function AppRoutes() {
  const [isOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.Session?.isAuthenticated);
  const userType = useSelector((state) => state.User.userType);
  const currentFetchList = useSelector(
    (state) => state.FetchApiList?.currentFetchList,
  );

  useEffect(() => {
    if (loggedIn) {
      if (
        userType === 'AGENCY_ADMIN' ||
        userType === 'AGENCY_ADMIN_BASIC' ||
        userType === 'DELEGATE' ||
        userType === 'BOOMERS_ADMIN_AGENCY' ||
        userType === 'SUPER_ADMIN_2' ||
        userType === 'DELEGATE_RW'
      ) {
        setDialogOpen(true);
      }

      if (userType === 'BOOMERS_ADMIN' || userType === 'SUPER_ADMIN_2') {
        dispatch(getGroup()).then((resp) => {
          if (!(resp.data.length > 0)) {
            setTimeout(() => {
              dispatch(doLogOut());
            }, 5000);
          }

          // dispatch(setSelectedGroup(resp.data[0]));
          // dispatch(setTheme(primaryColor, secondaryColor));
          setTimeout(() => {
            setDialogOpen(false);
          }, 1000);
        });
      }

      dispatch(getAgency()).then((resp) => {
        if (!(resp.data.length > 0)) {
          setTimeout(() => {
            dispatch(doLogOut());
          }, 5000);
        }
        if (
          userType === 'AGENCY_ADMIN' ||
          userType === 'AGENCY_ADMIN_BASIC' ||
          userType === 'DELEGATE' ||
          userType === 'BOOMERS_ADMIN_AGENCY' ||
          userType === 'SUPER_ADMIN_2' ||
          userType === 'DELEGATE_RW'
        ) {
          // const primaryColor = _.get(resp, 'data[0].primary_site_color');
          // const secondaryColor = _.get(resp, 'data[0].secondary_site_color');
          // dispatch(setSelectedAgency(resp.data[0]));
          // dispatch(setTheme(primaryColor, secondaryColor));
          setTimeout(() => {
            setDialogOpen(false);
          }, 1000);
        }
      });
    }
  }, [loggedIn, userType]);

  return (
    <>
      <Fade>
        <Switch>
          <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD}>
            <DashboardPage />
          </Route>
          <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.PROFILE}>
            <ProfilePage />
          </Route>
          <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.CHANGE_PASSWORD}>
            <ChangePasswordPage />
          </Route>

          {userType === 'AGENCY_ADMIN_BASIC' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.USERS}>
                <UserPage />
              </Route>
              <Route exact path={`${APP_CONSTANTS.APP_ROUTE_URLS.ADD_USER}`}>
                <AddUserAndPermission />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.ACCESS_AGENCY}`}
              >
                <AgencyUserAccess />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.REPORT}>
                <ReportPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route exact path={`${APP_CONSTANTS.APP_ROUTE_URLS.ADD_AGENCY}`}>
                <AddAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/:agencyId`}
              >
                <UpdateBasicAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`}
              >
                <PaymentPage />
              </Route>

              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/leads`}
              >
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/lead/:id`}
              >
                <LeadDetailsPage />
              </Route>
            </>
          )}

          {userType === 'DELEGATE' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.REPORT}>
                <ReportPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/leads`}
              >
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/lead/:id`}
              >
                <LeadDetailsPage />
              </Route>
            </>
          )}

          {userType === 'DELEGATE_RW' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.REPORT}>
                <ReportPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/:agencyId`}
              >
                <UpdateBasicAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/leads`}
              >
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/lead/:id`}
              >
                <LeadDetailsPage />
              </Route>
            </>
          )}

          {userType === 'AGENCY_ADMIN' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.CARE_GIVERS}>
                <CaregiversPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.ADD_CARE_GIVERS}>
                <AddCaregiversPage />
              </Route>
              <Route
                exact
                path={APP_CONSTANTS.APP_ROUTE_URLS.UPLOAD_CARE_GIVERS}
              >
                <UploadCaregiversPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_CARE_GIVER}:id`}
              >
                <UpdateCaregiverPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.LEADS}>
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.LEAD_DETAILS}:id`}
              >
                <LeadDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY}/:agencyId`}
              >
                <UpdateAgencyPage />
              </Route>
            </>
          )}

          {userType === 'BOOMERS_ADMIN' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.BILLING}>
                <Billings />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.GROUPS}>
                <GroupPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_GROUP}/:groupId`}
              >
                <GroupDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY}/:agencyId`}
              >
                <UpdateAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/:agencyId`}
              >
                <UpdateBasicAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/care-givers`}
              >
                <CaregiversPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/leads`}
              >
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/lead/:id`}
              >
                <LeadDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`}
              >
                <PaymentPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.GROUP}/:groupId${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`}
              >
                <GroupPayment />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_GROUP}/:groupId`}
              >
                <UpdateGroupPage />
              </Route>
            </>
          )}

          {userType === 'SUPER_ADMIN_2' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.GROUPS}>
                <GroupPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_GROUP}/:groupId`}
              >
                <GroupDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY}/:agencyId`}
              >
                <UpdateAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/:agencyId`}
              >
                <UpdateBasicAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/leads`}
              >
                <LeadsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId/lead/:id`}
              >
                <LeadDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/:agencyId${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`}
              >
                <PaymentPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.GROUP}/:groupId${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`}
              >
                <GroupPayment />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_GROUP}/:groupId`}
              >
                <UpdateGroupPage />
              </Route>
            </>
          )}

          {userType === 'BOOMERS_ADMIN_AGENCY' && (
            <>
              <Route exact path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}>
                <AgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/:agencyId`}
              >
                <AgencyDetailsPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY}/:agencyId`}
              >
                <UpdateAgencyPage />
              </Route>
              <Route
                exact
                path={`${APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY}/:agencyId`}
              >
                <UpdateBasicAgencyPage />
              </Route>
            </>
          )}
          <Route path={APP_CONSTANTS.BASE_URL}>
            <Redirect to={APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD} />
          </Route>
        </Switch>
      </Fade>

      {!isOpen && currentFetchList && currentFetchList.length > 0 && (
        <Backdrop open style={{ zIndex: 99999 }}>
          <AppProgressIndicator
            fullPage
            position="fixed"
            progressType="circular"
          />
        </Backdrop>
      )}
      <Dialog open={isOpen} disableBackdropClick disableEscapeKeyDown>
        <Box p={2}>
          <Typography variant="subtitle2">
            Please wait while loading your configuration...
          </Typography>
        </Box>
      </Dialog>
    </>
  );
}
