export const DEFAULT_CAREGIVER_LIST_TABLE_HEADERS = [
  { label: 'First Name', key: 'first_name', search: true, sort: true },
  { label: 'Last Name', key: 'last_name', search: true, sort: true },
  { label: 'email', key: 'email', search: true, sort: true },
  { label: 'gender', key: 'gender', search: true, sort: true },
  { label: 'city', key: 'city', search: true, sort: true },
  { label: 'zip', key: 'zip_postal_code', search: true, sort: true },
  { label: 'status', key: 'status', search: true, sort: true },
];

export const CAREGIVER_LIST_TABLE_HEADERS = (userType) =>
  userType === 'AGENCY_ADMIN'
    ? [
        ...DEFAULT_CAREGIVER_LIST_TABLE_HEADERS,
        {
          label: 'ACTIONS',
          key: 'action',
          search: false,
          sort: false,
          width: '60px',
        },
      ]
    : [...DEFAULT_CAREGIVER_LIST_TABLE_HEADERS];
