import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import { GET_METADATA_API } from './constants';

export const getMetaData = () => (dispatch) => {
  const config = {
    url: APP_CONSTANTS.API_LINKS.METADATA,
    actionType: GET_METADATA_API,
  };
  return dispatch(doRequest.get(config));
};
