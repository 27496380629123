import React, { useEffect, useMemo, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const StyledSnackbarContent = styled(SnackbarContent)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  &:last-child {
    margin-bottom: 0;
  }
  &.success {
    background-color: ${green[600]};
  }
  &.error {
    background-color: ${(props) => props.theme.palette.error.dark};
  }
  &.info {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  &.warning {
    background-color: ${amber[700]};
  }
  .icon {
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  .iconVariant {
    opacity: 0.9;
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }
  .message {
    display: flex;
    align-items: center;
  }
`;

function SnackbarContentWrapper(props) {
  const [itemId, setItemId] = useState(null);
  const [visible, setVisible] = useState(true);
  const [className, setClassName] = useState('');
  const [message, setMessage] = useState('Message is required!');
  const [variant, setVariant] = useState('info');
  const [onScreenNotifier, setOnScreenNotifier] = useState(null);

  const onCloseProcedure = () => {
    const { onClose } = props;

    if (onScreenNotifier) {
      clearTimeout(onScreenNotifier);
      setVisible(false);
      setTimeout(() => {
        onClose();
      }, 500);
    } else {
      setVisible(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  useEffect(() => {
    const { onexit } = props;
    setItemId(props.itemId);
    setClassName(props.className);
    setMessage(props.message);
    setVariant(props.variant);

    const timeoutId = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onexit(props.itemId);
      }, 500);
    }, props.duration || 1000);

    setOnScreenNotifier(timeoutId);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [props.itemId]);

  return useMemo(() => {
    const Icon = variantIcon[variant];
    return (
      <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
        <StyledSnackbarContent
          className={clsx([variant], className)}
          message={
            <span id="client-snackbar" className="message">
              <Icon className={clsx('icon', 'iconVariant')} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => {
                onCloseProcedure();
              }}
            >
              <CloseIcon className="icon" />
            </IconButton>,
          ]}
        />
      </Slide>
    );
  }, [itemId, visible]);
}

export default SnackbarContentWrapper;
