export const USER_LIST_TABLE_HEADERS = [
  { label: 'First Name', key: 'first_name', search: true, sort: true },
  { label: 'Last Name', key: 'last_name', search: true, sort: true },
  { label: 'Login Id', key: 'login_id', search: true, sort: true },
  { label: 'email', key: 'email', search: true, sort: true },
  { label: 'phone', key: 'cell_phone', search: true, sort: true },
  {
    label: 'STATUS',
    key: 'status',
    search: false,
    sort: true,
    width: '200px',
  },
  {
    label: 'ACTIONS',
    key: 'action',
    search: false,
    sort: false,
    width: '170px',
  },
];
