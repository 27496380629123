import { FETCH_START, FETCH_STOP } from './constant';

export const fetchStop = (fetchActionName) => ({
  type: FETCH_STOP,
  fetchActionName,
});

export const fetchStart = (fetchActionName) => ({
  type: FETCH_START,
  fetchActionName,
});

export const dispatchError = (type, errorMsg) => ({
  type,
  isFetching: false,
  errorMsg,
});

export const requestStart = (type) => ({
  type,
  isFetching: true,
});

export const requestSuccess = (type, data) => ({
  type,
  isFetching: false,
  data: data?.data,
});
