import React, { useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import AppTable from '../../packages/AppTable';
import { LEADS_LIST_TABLE_HEADERS } from './constants';
import {
  getAllLeads,
  getAllLeadsByAgencyId,
} from '../../modules/Leads/actions';
import {
  LeadsActions,
  ToggleLeadStatus,
} from '../../components/LeadsTableAction';
import { getAgencyById, setSelectedAgency } from '../../modules/Agency/actions';
import {
  LeadsCreationTimestampCell,
  LeadsFirstName,
  LeadsLastName,
  LeadsZip,
} from '../../components/LeadsTableCustomRows';

export default function LeadsPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const { agencyId } = params;

  const leadsList = useSelector((state) => state.Leads.leadsList);
  const selectedAgency = useSelector((state) => state.Agency.selectedAgency);
  const userType = useSelector((state) => state.User.userType);

  const leadTableProps = {
    tableName: 'leads-list-table',
    size: 'small',
    orderBy: '',
    searchCol: true,
    data: leadsList || [],
    headers: LEADS_LIST_TABLE_HEADERS(userType),
    rowsPerPageOptions: [100, 500, 1000],
    enableCellRender: true,
    render: {
      first_name: (props) => <LeadsFirstName {...props} agencyId={agencyId} />,
      last_name: (props) => <LeadsLastName {...props} agencyId={agencyId} />,
      action: (props) => <LeadsActions {...props} agencyId={agencyId} />,
      billable: (props) => <ToggleLeadStatus {...props} agencyId={agencyId} />,
      creation_timestamp: (props) => <LeadsCreationTimestampCell {...props} />,
      zip_postal_code: (props) => <LeadsZip {...props} />,
    },
  };

  useEffect(() => {
    if (agencyId) {
      dispatch(getAllLeadsByAgencyId(agencyId));
      if (!(selectedAgency && 'agency_id' in selectedAgency)) {
        dispatch(getAgencyById(agencyId)).then((resp) => {
          const { data } = resp;
          dispatch(setSelectedAgency(data));
        });
      }
    } else {
      dispatch(getAllLeads());
    }
  }, []);

  return useMemo(
    () => (
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Leads">
              <Grid
                alignItems="flex-start"
                justify="space-between"
                alignContent="stretch"
                spacing={2}
                container
              ></Grid>
            </AppPageTitle>
          </Grid>
          <Grid item xs={12}>
            <ErrorBoundary>
              <AppTable {...leadTableProps} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </AppContainer>
    ),
    [leadsList],
  );
}
