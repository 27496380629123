import {
  Box,
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { APP_CONSTANTS } from '../../constants';
import {
  downloadByAgencyId,
  getAgencyById,
  setSelectedAgency,
} from '../../modules/Agency/actions';
import { getLeadCountByAgencyId } from '../../modules/Leads/actions';
import {
  getPaymentMethod,
  addPaymentMethod,
} from '../../modules/Payment/actions';
import { AppButton } from '../../packages/AppButton';
import { AppContainer } from '../../packages/AppContainer';
import ErrorBoundary from '../../packages/AppErrorBoundary';
import AppPageTitle from '../../packages/AppPageTitle';
import { StyledDivider } from '../../packages/AppStyledComponents';
import AppTable from '../../packages/AppTable';
import BillingInfoForm from '../../components/BillingInfoForm';
import {
  getAgencyBillingEmails,
  updateAgencyBillingEmails,
} from '../../modules/Billing/actions';

const LeadsTableProps = {
  tableName: 'leads-list-table',
  size: 'small',
  orderBy: '',
  searchCol: false,
  data: [],
  headers: [
    { label: 'Lead Type', key: 'lead_type', search: false, sort: false },
    { label: 'Count', key: 'lead_count', search: false, sort: false },
  ],
  pagination: false,
};

const todayDate = new Date();
const firstDayOfCurrentMonth = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  1,
);
const firstDayOfPreviousMonth = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth() - 1,
  1,
);
const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);

export default function PaymentPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { agencyId } = params;
  const agency = useSelector((state) => state.Agency?.selectedAgency);
  const userType = useSelector((state) => state.User.userType);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [payment, setPayment] = React.useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [nameOnCard, setNameOnCard] = React.useState(null);
  const [cardError, setCardError] = React.useState({});
  const [updatePaymentMethod, setUpdatePayment] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(firstDayOfPreviousMonth);
  const [toDate, setToDate] = React.useState(lastDayOfPreviousMonth);
  const [leadCount, setLeadCount] = React.useState({});
  const [leadTableProps, setLeadTableProps] = React.useState(LeadsTableProps);
  const [billingEmails, setBillingEmails] = React.useState({
    billing_info_id: '',
    billing_email: '',
  });
  const [isBillingEmailValid, setIsBillingEmailValid] = React.useState(true);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const downloadFile = () => {
    dispatch(
      downloadByAgencyId(
        agencyId,
        format(fromDate, 'yyyy-MM-dd'),
        format(toDate, 'yyyy-MM-dd'),
      ),
    ).then((resp) => {
      const blob = new Blob(['\ufeff', resp.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      let filename = 'AgencyLeads.csv';
      const disposition = resp.headers['content-disposition'];
      if (disposition && disposition.indexOf('inline') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      // setLeadCount(data);
    });
  };

  const onFilterAction = () => {
    dispatch(
      getLeadCountByAgencyId(
        agencyId,
        format(fromDate, 'yyyy-MM-dd'),
        format(toDate, 'yyyy-MM-dd'),
      ),
    ).then((resp) => {
      const { data } = resp;
      setLeadCount(data);
      setLeadTableProps((prevState) => ({
        ...prevState,
        data: data?.leads_count,
      }));
    });
  };

  const handleNameChange = (event) => {
    const {
      target: { value },
    } = event;
    setNameOnCard(value);
  };

  const handleCardChange = () => {
    setCardError({});
  };

  const handlePaymentMethodSubmit = (event) => {
    setLoading(true);
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe
      .createToken(cardElement, {
        name: nameOnCard,
      })
      .then((result) => {
        const { token, error } = result;
        if (error) {
          setCardError(error);
          setLoading(false);
        } else {
          dispatch(addPaymentMethod(agency?.agency_id, token))
            .then(() => {
              setLoading(false);
              setUpdatePayment(false);
              setNameOnCard(null);
            })
            .catch(() => {
              setLoading(false);
            });
        }
      });
  };

  useEffect(() => {
    if (agencyId) {
      dispatch(getAgencyById(agencyId)).then((resp) => {
        const { data } = resp;
        dispatch(setSelectedAgency(data));
        if (data?.agency_id) {
          dispatch(getPaymentMethod(data?.agency_id)).then((paymentResp) => {
            const { data: paymentData } = paymentResp;
            setPayment(paymentData);
          });
        }
      });

      if (userType === 'AGENCY_ADMIN_BASIC') {
        dispatch(getAgencyBillingEmails(agencyId)).then((resp) => {
          const { data } = resp;
          validateEmail(data?.billing_email);
          setBillingEmails({ ...data });
        });
      }
    }
  }, [agencyId]);

  const updateBillingEmails = () => {
    dispatch(updateAgencyBillingEmails({ ...billingEmails }));
  };

  const handelBillingEmailChange = (event) => {
    const value = event?.target?.value;
    validateEmail(value);
    setBillingEmails({ ...billingEmails, billing_email: value });
  };

  const validateEmail = (value) => {
    if (value && value !== '') {
      const regex = /^(?!.*,)[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const valuesArray = value.split(',');
      const validationValueArr = [];

      valuesArray.forEach((val) => {
        const isMatch = regex.test(val.trim());
        validationValueArr.push(isMatch);
      });

      setIsBillingEmailValid(!validationValueArr.includes(false));
    } else {
      setIsBillingEmailValid(true);
    }
  };

  return useMemo(
    () => (
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Payment Page">
              <AppButton
                variant="text"
                color="primary"
                label="Back to Agencies"
                handleClick={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES);
                }}
              />
            </AppPageTitle>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              {userType === 'SUPER_ADMIN_2' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Leads Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'from change date',
                            }}
                          />
                          <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={handleToDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'to change date',
                            }}
                          />
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Filter"
                            handleClick={() => {
                              onFilterAction();
                            }}
                          />
                        </Box>
                        <ErrorBoundary>
                          <AppTable {...leadTableProps} />
                        </ErrorBoundary>
                        <Typography variant="h6" gutterBottom>
                          Summary
                        </Typography>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1">Total Count</Typography>
                          <Box ml={4} />
                          <Typography variant="body1">
                            {leadCount?.total_count || 0}
                          </Typography>
                        </Box>
                        <StyledDivider />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1">Total Amount</Typography>
                          <Box ml={4} />
                          <Typography variant="body1">
                            $ {leadCount?.price || 0}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={2}
                        >
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Download Report"
                            handleClick={() => {
                              downloadFile();
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {userType === 'BOOMERS_ADMIN' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Billing Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <BillingInfoForm uuidList={[]}></BillingInfoForm>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Leads Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'from change date',
                            }}
                          />
                          <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={handleToDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'to change date',
                            }}
                          />
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Filter"
                            handleClick={() => {
                              onFilterAction();
                            }}
                          />
                        </Box>
                        <ErrorBoundary>
                          <AppTable {...leadTableProps} />
                        </ErrorBoundary>
                        <Typography variant="h6" gutterBottom>
                          Summary
                        </Typography>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1">Total Count</Typography>
                          <Box ml={4} />
                          <Typography variant="body1">
                            {leadCount?.total_count || 0}
                          </Typography>
                        </Box>
                        <StyledDivider />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1">Total Amount</Typography>
                          <Box ml={4} />
                          <Typography variant="body1">
                            $ {leadCount?.price || 0}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={2}
                        >
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Download Report"
                            handleClick={() => {
                              downloadFile();
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Card Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Typography gutterBottom>
                          Name: {agency?.name}
                        </Typography>
                        {payment?.success ? (
                          <>
                            <Typography gutterBottom>
                              {`${payment.network_brand} Card ending in ${payment.ends_with}`}
                            </Typography>
                            <Typography
                              gutterBottom
                            >{`Expiry: ${payment?.expiry}`}</Typography>
                          </>
                        ) : (
                          <Typography gutterBottom>
                            No Card available.
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {userType === 'AGENCY_ADMIN_BASIC' && (
                <>
                  <Grid item xs={12} md={4}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent
                        style={{ height: '100%', boxSizing: 'border-box' }}
                      >
                        <Typography variant="subtitle1">
                          Payment Method
                        </Typography>
                        <StyledDivider marginbottom={2} />
                        <Box
                          display="flex"
                          flexDirection="column"
                          height="calc(100% - 30px)"
                          boxSizing="border-box"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                        >
                          {payment?.success && !updatePaymentMethod ? (
                            <>
                              <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                              >
                                Card Details
                              </Typography>
                              <Typography
                                variant="body1"
                                align="center"
                                gutterBottom
                              >
                                {`Your ${payment.network_brand} Card ending in ${payment.ends_with}`}
                              </Typography>
                              <Typography
                                variant="body1"
                                align="center"
                                gutterBottom
                              >
                                {`Expiry: ${payment?.expiry}`}
                              </Typography>
                              <Box mb={2} />
                              <AppButton
                                variant="contained"
                                color="primary"
                                label="Update Payment Method"
                                handleClick={() => {
                                  setUpdatePayment(true);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{ justifyContent: 'center' }}
                              >
                                <Grid item xs={12}>
                                  <form
                                    name="AddCard"
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <FormControl fullWidth margin="dense">
                                      <TextField
                                        id="name"
                                        label="Name on card"
                                        required
                                        error={nameOnCard === ''}
                                        onChange={handleNameChange}
                                      />
                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth margin="dense">
                                      <CardElement
                                        onChange={handleCardChange}
                                        options={{
                                          style: {
                                            base: {
                                              fontSize: '16px',
                                              color: '#424770',
                                              '::placeholder': {
                                                color: '#aab7c4',
                                              },
                                            },
                                            invalid: {
                                              color: '#9e2146',
                                            },
                                          },
                                        }}
                                      />
                                      {cardError?.message && (
                                        <FormHelperText error>
                                          {cardError?.message}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth margin="dense">
                                      <AppButton
                                        disabled={
                                          !stripe ||
                                          !nameOnCard ||
                                          nameOnCard === ''
                                        }
                                        variant="contained"
                                        color="primary"
                                        label="Save"
                                        handleClick={handlePaymentMethodSubmit}
                                        fullWidth
                                      />
                                    </FormControl>
                                    {payment.success && (
                                      <FormControl fullWidth margin="dense">
                                        <AppButton
                                          variant="contained"
                                          color="inherit"
                                          label="Cancel"
                                          handleClick={() => {
                                            setUpdatePayment(false);
                                            setNameOnCard(null);
                                          }}
                                          fullWidth
                                        />
                                      </FormControl>
                                    )}
                                  </form>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent
                        style={{ height: '100%', boxSizing: 'border-box' }}
                      >
                        <Typography variant="subtitle1">
                          Billing Info
                        </Typography>
                        <StyledDivider marginbottom={2} />
                        <Box
                          display="flex"
                          flexDirection="column"
                          height="calc(100% - 30px)"
                          boxSizing="border-box"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                        >
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ justifyContent: 'center' }}
                          >
                            <Grid item xs={12}>
                              <form
                                name="billing"
                                noValidate
                                autoComplete="off"
                              >
                                <FormControl fullWidth margin="dense">
                                  <TextField
                                    style={{ marginTop: '10px' }}
                                    id="billing_emails"
                                    label="Billing Emails (Comma Seperated)"
                                    helperText={
                                      !isBillingEmailValid
                                        ? 'Please enter valid email'
                                        : ''
                                    }
                                    value={billingEmails.billing_email}
                                    error={!isBillingEmailValid}
                                    onChange={handelBillingEmailChange}
                                  />
                                </FormControl>
                                <Box mb={6} />
                                <FormControl fullWidth margin="dense">
                                  <AppButton
                                    variant="contained"
                                    color="primary"
                                    label="Save"
                                    disabled={!isBillingEmailValid}
                                    handleClick={updateBillingEmails}
                                    fullWidth
                                  />
                                </FormControl>
                              </form>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppContainer>
    ),
    [
      agency,
      fromDate,
      toDate,
      leadCount,
      leadTableProps,
      nameOnCard,
      cardError,
      payment,
      updatePaymentMethod,
      billingEmails,
      isBillingEmailValid,
    ],
  );
}
