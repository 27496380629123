import { USA_STATES } from './usaStates';
import { CANADA_STATES } from './canadaStates';

export const COUNTRY_STATES = {
  USA: USA_STATES,
  CANADA: CANADA_STATES,
};
export const COUNTRY_LABELS = {
  USA: {
    zip: 'Zip Code',
    state: 'State',
  },
  CANADA: {
    zip: 'Postal Code',
    state: 'Province',
  },
};
export const COUNTRY_REGEX = {
  USA: /^\d{5}(-\d{4})?$/,
  CANADA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
};
