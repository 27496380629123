export const APP_CONSTANTS = {
  get APP_NAME() {
    return 'aidquest';
  },
  get BASE_URL() {
    return `/${this.APP_NAME}`;
  },
  get BASE_API_URL() {
    return '/api/v2/';
  },
  get APP_ROUTE_URLS() {
    return {
      USERS: `${this.BASE_URL}/users`,
      ACCESS_AGENCY: `${this.BASE_URL}/user/access`,
      ADD_USER: `${this.BASE_URL}/add/user`,
      VIEW_AGENCY: `${this.BASE_URL}/agency/view`,
      UPDATE_AGENCY: `${this.BASE_URL}/agency/update`,
      UPDATE_BASIC_AGENCY: `${this.BASE_URL}/basic-agency/update`,
      AGENCY: `${this.BASE_URL}/agency`,
      BILLING: `${this.BASE_URL}/billing`,
      ADD_AGENCY: `${this.BASE_URL}/add/agency`,
      REPORT: `${this.BASE_URL}/report`,
      UPDATE_GROUP: `${this.BASE_URL}/group/update`,
      GROUP: `${this.BASE_URL}/group`,
      AGENCIES: `${this.BASE_URL}/agencies`,
      GROUPS: `${this.BASE_URL}/groups`,
      CARE_GIVERS: `${this.BASE_URL}/care-givers`,
      ADD_CARE_GIVERS: `${this.BASE_URL}/add-care-givers`,
      UPDATE_CARE_GIVER: `${this.BASE_URL}/update-care-giver/`,
      UPLOAD_CARE_GIVERS: `${this.BASE_URL}/upload-care-givers`,
      LEADS: `${this.BASE_URL}/leads`,
      LEAD_DETAILS: `${this.BASE_URL}/lead/`,
      THEME: `${this.BASE_URL}/theme`,
      DASHBOARD: `${this.BASE_URL}/dashboard`,
      PROFILE: `${this.BASE_URL}/profile`,
      CHANGE_PASSWORD: `${this.BASE_URL}/change-password`,
      PAYMENT: `/payment`,
    };
  },
  get BASE_ROUTE_URLS() {
    return {
      HOME: `${this.BASE_URL}/home`,
      LOGIN: `${this.BASE_URL}/login`,
      REGISTER: `${this.BASE_URL}/register`,
      BASIC_REGISTER: `${this.BASE_URL}/basic-register`,
      FORGOT_PASSWORD: `${this.BASE_URL}/forgot-password`,
      RESET_PASSWORD: `${this.BASE_URL}/reset-password`,
      SOMETHING_WENT_WRONG: `${this.BASE_URL}/404`,
      PAGE_NOT_FOUND: `${this.BASE_URL}/page-not-found`,
    };
  },
  get API_LINKS() {
    return {
      APP_SERVER_SESSION_API: `/support/myserversession`,
      AUTH: `/users/authenticate`,
      AGENCY: `/agency`,
      BILLING: `/billing`,
      BILLS: `/bills`,
      USER: `/user`,
      GROUP: `/group`,
      METADATA: `/support/metadata`,
      CAREGIVERS: `/caregivers`,
      UPLOAD_CAREGIVERS_PROFILE: `/caregivers/uploadcaregivers`,
      UPLOAD_CAREGIVERS_PROFILE_PROCESS_AND_MERGE: `/caregivers/uploadcaregivers/processandmerge`,
      LEADS: `/leads`,
      USERS: `/users`,
      PASSWORD: `/users/password`,
      RESET_PASSWORD: `/users/password/reset`,
      PAYMENT_METHOD: '/paymentmethod',
      REPORT: '/report',
    };
  },
  get AUTH_USER_DEFAULT_LINK() {
    return [
      /* {
        name: 'home',
        link: `${this.BASE_ROUTE_URLS.HOME}`,
        key: 'home-link',
        visible: true,
        active: true,
      }, */
      {
        name: 'Dashboard',
        link: `${this.APP_ROUTE_URLS.DASHBOARD}`,
        key: 'Dashboard-link',
        visible: true,
        active: true,
      },
    ];
  },
  get AUTH_USER_NAV_BAR_LINK() {
    return {
      AGENCY_ADMIN_BASIC: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'agencies-link',
          visible: true,
          active: true,
        },
        {
          name: 'Users',
          link: `${this.APP_ROUTE_URLS.USERS}`,
          key: 'user-link',
          visible: true,
          active: true,
        },
        {
          name: 'Report',
          link: `${this.APP_ROUTE_URLS.REPORT}`,
          key: 'report-link',
          visible: true,
          active: true,
        },
      ],
      AGENCY_ADMIN: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Caregivers',
          link: `${this.APP_ROUTE_URLS.CARE_GIVERS}`,
          key: 'care-givers-link',
          visible: true,
          active: true,
        },
        {
          name: 'Leads',
          link: `${this.APP_ROUTE_URLS.LEADS}`,
          key: 'leads-link',
          visible: true,
          active: true,
        },
        {
          name: 'Agency',
          link: `${this.APP_ROUTE_URLS.VIEW_AGENCY}/self`,
          key: 'agency-link',
          visible: true,
          active: true,
        },
      ],
      BOOMERS_ADMIN: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'Agencies-link',
          visible: true,
          active: true,
        },
        {
          name: 'Groups',
          link: `${this.APP_ROUTE_URLS.GROUPS}`,
          key: 'group-link',
          visible: true,
          active: true,
        },
        {
          name: 'Billing',
          link: `${APP_CONSTANTS.APP_ROUTE_URLS.BILLING}`,
          key: 'billing-link',
          visible: true,
          active: true,
        },
      ],
      DELEGATE: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'agencies-link',
          visible: true,
          active: true,
        },
        {
          name: 'Report',
          link: `${this.APP_ROUTE_URLS.REPORT}`,
          key: 'report-link',
          visible: true,
          active: true,
        },
      ],
      DELEGATE_RW: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'agencies-link',
          visible: true,
          active: true,
        },
        {
          name: 'Report',
          link: `${this.APP_ROUTE_URLS.REPORT}`,
          key: 'report-link',
          visible: true,
          active: true,
        },
      ],
      BOOMERS_ADMIN_AGENCY: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'agencies-link',
          visible: true,
          active: true,
        },
      ],
      SUPER_ADMIN_2: [
        ...this.AUTH_USER_DEFAULT_LINK,
        {
          name: 'Agencies',
          link: `${this.APP_ROUTE_URLS.AGENCIES}`,
          key: 'agencies-link',
          visible: true,
          active: true,
        },
        {
          name: 'Groups',
          link: `${this.APP_ROUTE_URLS.GROUPS}`,
          key: 'group-link',
          visible: true,
          active: true,
        },
      ],
    };
  },
  get NAV_BAR_LINK() {
    return [
      {
        name: 'home',
        link: `${this.BASE_ROUTE_URLS.HOME}`,
        key: 'home-link',
        visible: true,
        active: false,
      },
      {
        name: 'login',
        link: `${this.BASE_ROUTE_URLS.LOGIN}`,
        key: 'login-link',
        visible: true,
        active: false,
      },
      {
        name: 'register',
        link: `${this.BASE_ROUTE_URLS.BASIC_REGISTER}`,
        key: 'register-link',
        visible: true,
        active: false,
      },
    ];
  },
  get APP_FOOTER_LINK() {
    return [
      {
        name: 'blog',
        link: `${this.BASE_URL}/blog`,
        key: 'blog-link',
        visible: true,
        active: false,
      },
      {
        name: 'trust and safety',
        link: `${this.BASE_URL}/trust-and-safety`,
        key: 'trust-and-safety-link',
        visible: true,
        active: false,
      },
      {
        name: 'faqs',
        link: `${this.BASE_URL}/faqs`,
        key: 'faqs-link',
        visible: true,
        active: false,
      },
      {
        name: 'terms & conditions',
        link: `${this.BASE_URL}/terms-and-conditions`,
        key: 'terms-and-conditions-link',
        visible: true,
        active: false,
      },
      {
        name: 'sitemap',
        link: `${this.BASE_URL}/sitemap`,
        key: 'sitemap-link',
        visible: true,
        active: false,
      },
    ];
  },
  get SCREEN_SIZES() {
    return {
      xs: 320,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    };
  },
  get WHITELISTED_PERSIST_STORE() {
    return ['MetaData', 'Session', 'Theme', 'Token', 'User'];
  },
  get WHITELISTED_COMPRESS_STORE() {
    return [];
  },
  get EXPIRE_OPTION_STORE() {
    return [];
  },
  get EXPIRE_STORE_AFTER_SECOND() {
    return 86400; // 1800 for 30 min // 86400 for 1 day // 21600 6hrs
  },
  get WEEK_DAYS() {
    return [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
  },
  get DATE_FORMAT() {
    return {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  },
  get MESSAGE_MAP() {
    return {
      200: { severity: 'success', summary: 'Success' },
      300: { severity: 'success', summary: 'Success' },
      500: { severity: 'warning', summary: 'Server Error' },
      400: { severity: 'danger', summary: 'Bad Request' },
      404: { severity: 'danger', summary: 'Not Found!' },
      401: { severity: 'danger', summary: 'Not Allowed!' },
    };
  },
};
