import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import Box from '@material-ui/core/Box';
import {
  activateAgency,
  deactivateAgency,
  setSelectedAgency,
} from '../../modules/Agency/actions';
import { APP_CONSTANTS } from '../../constants';
import IOSSwitch from '../../packages/AppIOSSwitch';
import LinkComponent from '../../packages/AppLink';

export function AgencyAction(props) {
  const {
    deleteAgency,
    row: { agency_id, package_type },
  } = props;
  const history = useHistory();
  const userType = useSelector((appState) => appState.User.userType);

  return (
    <TableCell
      align="justify"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <Box display="flex" align="center" justifyContent="center">
        {userType !== 'DELEGATE' &&
          userType !== 'DELEGATE_RW' &&
          userType !== 'BOOMERS_ADMIN_AGENCY' && (
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                history.push(
                  `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agency_id}${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`,
                );
              }}
            >
              <PaymentOutlinedIcon />
            </IconButton>
          )}
        {userType !== 'DELEGATE' && (
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              history.push(
                `${
                  package_type === 'BASIC'
                    ? APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY
                    : APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY
                }/${agency_id}`,
              );
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            history.push(
              `${APP_CONSTANTS.APP_ROUTE_URLS.VIEW_AGENCY}/${agency_id}`,
            );
          }}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
        {userType === 'BOOMERS_ADMIN' && (
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              deleteAgency(agency_id);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
}

export function ToggleAgencyStatus(props) {
  const {
    row: { agency_id, status },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setActive(_.toLower(status) === 'active');
  }, [status]);

  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(activateAgency(agency_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
        }
      });
    } else {
      dispatch(deactivateAgency(agency_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <IOSSwitch checked={active} onChange={handleChange} name={agency_id} />
    </TableCell>
  );
}

export function AgencyLeads(props) {
  const {
    row: { agency_id, total_leads },
    row: rowObj,
  } = props;
  const dispatch = useDispatch();

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agency_id}/leads`}
        onClick={() => {
          dispatch(setSelectedAgency(rowObj));
        }}
      >
        {total_leads}
      </LinkComponent>
    </TableCell>
  );
}
export function AgencyCaregivers(props) {
  const {
    row: { agency_id, total_active_caregivers },
    row: rowObj,
  } = props;
  const dispatch = useDispatch();

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agency_id}/care-givers`}
        onClick={() => {
          dispatch(setSelectedAgency(rowObj));
        }}
      >
        {total_active_caregivers}
      </LinkComponent>
    </TableCell>
  );
}
export function AgencyName(props) {
  const {
    row: { name, agency_id, package_type },
  } = props;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${
          package_type === 'BASIC'
            ? APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY
            : APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_AGENCY
        }/${agency_id}`}
      >
        {name}
      </LinkComponent>
    </TableCell>
  );
}
export function AgencyCreationDateCell(props) {
  const {
    width,
    row: { created_date },
  } = props;
  return (
    <TableCell
      align="center"
      size="small"
      style={{
        width,
        textAlign: 'left',
      }}
    >
      {new Date(`${created_date}`).toLocaleString(
        'en-US',
        APP_CONSTANTS.DATE_FORMAT,
      )}
    </TableCell>
  );
}
