import {
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from '@material-ui/core';
import React, { useMemo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import * as _ from 'lodash';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  clearSelectedAgencyBillingInfo,
  createSelectedAgencyBillingInfo,
  getSelectedAgencyBillingInfo,
  setSelectedAgencyBillingInfo,
  updateSelectedAgencyBillingInfo,
} from '../../modules/Agency/actions';
import {
  clearSelectedGroupBillingInfo,
  createSelectedGroupBillingInfo,
  getSelectedGroupBillingInfo,
  setSelectedGroupBillingInfo,
  updateSelectedGroupBillingInfo,
} from '../../modules/Group/actions';
import {
  discount_type,
  charge_from_card_on_file,
  getRegex,
  bill_at_group_level,
  charge_at_corp_level,
  invoicing_type_group,
  invoicing_type_agency,
} from './constants';

export default function BillingInfoForm(props) {
  const params = useParams();
  const currentUrl = window.location.href;
  const billingInfoParent = currentUrl.split('aidquest/')[1].split('/')[0];
  const [uuidDropdownData, setUuidDropdownData] = useState([]);
  const dispatch = useDispatch();
  const { [`${billingInfoParent}Id`]: id } = params;

  const [range, setRange] = useState([
    {
      numberOfLeadsFrom: 1,
      numberOfLeadsTo: 'And Above',
      rate: null,
    },
  ]);

  const [formValue, setFormValue] = useState(
    billingInfoParent === 'agency'
      ? {
          billing_email: null,
          billing_start_date: null,
          billing_info_id: null,
          discount_type: 'NONE',
          bill_at_group_level: false,
          discount: null,
          invoicing_type: 'TIER_BASED_RATE',
          charge_from_card_on_file: false,
          flat_rate_per_month: null,
          charge_at_corp_level: false,
          corporate_agency_uuid: null,
          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        }
      : {
          billing_email: null,
          billing_start_date: null,
          billing_info_id: null,
          discount_type: null,
          bill_at_group_level: false,
          discount: null,
          invoicing_type: null,
          charge_from_card_on_file: null,
          flat_rate_per_month: null,
          charge_at_corp_level: null,
          corporate_agency_uuid: null,
          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        },
  );

  const [formValidStatus, setFormValidStatus] = useState({
    emailValid: true,
    rangeValid: false,
  });

  const clearBillingInfoAction = {
    agency: clearSelectedAgencyBillingInfo,
    group: clearSelectedGroupBillingInfo,
  };
  const getBillingInfoAction = {
    agency: getSelectedAgencyBillingInfo,
    group: getSelectedGroupBillingInfo,
  };
  const setBillingInfoAction = {
    agency: setSelectedAgencyBillingInfo,
    group: setSelectedGroupBillingInfo,
  };
  const createBillingInfoAction = {
    agency: createSelectedAgencyBillingInfo,
    group: createSelectedGroupBillingInfo,
  };
  const updateBillingInfoAction = {
    agency: updateSelectedAgencyBillingInfo,
    group: updateSelectedGroupBillingInfo,
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  useEffect(() => {
    checkRangeValidOrNot();
  }, [range]);

  useEffect(() => {
    setUuidDropdownData(props?.uuidList);
  }, [props?.uuidList]);

  const getBillingInfo = () => {
    dispatch(clearBillingInfoAction[billingInfoParent]?.());
    dispatch(getBillingInfoAction[billingInfoParent]?.(id)).then((resp) => {
      const { data } = resp;
      dispatch(setBillingInfoAction[billingInfoParent]?.(data));
      if (data && data.billing_info_id) {
        setFormValue({ ...formValue, ...data });
        if (data.tiered_rates) {
          const formattedRange = formatInputOutput(data, 'IMPORT');
          setRange(formattedRange);
        }
      }
    });
  };

  const onSubmit = async () => {
    const formData = { ...formValue };
    formData.flat_rate_per_month =
      formData.flat_rate_per_month !== null
        ? Number(formData.flat_rate_per_month)
        : null;
    formData.discount =
      formData.discount === null || formData.discount === ''
        ? null
        : Number(formData.discount);
    formData.tiered_rates = {};
    formData.tiered_rates.tieredRates = formValidStatus.rangeValid
      ? [...range]
      : null;
    if (formData.tiered_rates.tieredRates !== null) {
      formData.tiered_rates.tieredRates = formatInputOutput(formData, 'EXPORT');
    }

    if (billingInfoParent === 'agency') {
      delete formData.bill_at_group_level;
    }
    if (formData.billing_info_id === null) {
      delete formData.billing_info_id;
      await dispatch(
        createBillingInfoAction[billingInfoParent]?.(formData, id),
      );
      getBillingInfo();
    } else {
      await dispatch(updateBillingInfoAction[billingInfoParent]?.(formData));
      getBillingInfo();
    }
  };

  const handelAddRow = () => {
    const updated = [...range];
    const initRate = updated[updated.length - 1].rate;
    // Shifting last row rate and setting value of 'from' in new row.
    const test = {
      numberOfLeadsFrom:
        range.length === 1
          ? 1
          : Number(range[range.length - 2].numberOfLeadsTo) + 1,
      numberOfLeadsTo: '',
      rate: initRate,
    };
    updated.splice(updated.length - 1, 0, test);
    updated[updated.length - 1].numberOfLeadsFrom = 0;
    updated[updated.length - 1].rate = null;
    setRange(updated);
  };

  const handelRemoveRow = (index) => {
    const list = [...range];
    // Setting the rate of the row to be deleted in the last row and updating the last row 'from' value.
    const rateOfDataToBeDeleted = list[index].rate;
    list.splice(index, 1);
    list[list.length - 1].numberOfLeadsFrom =
      list.length === 1 ? 1 : Number(list[list.length - 2].numberOfLeadsTo) + 1;
    list[list.length - 1].rate = rateOfDataToBeDeleted;
    setRange(list);
  };

  const handelRowChange = (e, index, inputName) => {
    const name = inputName;
    const value = e?.target?.value;
    const list = [...range];
    const regex = getRegex(inputName);
    if (regex.test(value)) {
      list[index][name] = value;
      setRange(list);
    }

    if (name === 'numberOfLeadsTo') {
      // Updating last row 'from' on change of 'to' of prev row.
      const data = [...range];
      data[data.length - 1].numberOfLeadsFrom =
        Number(range[range.length - 2].numberOfLeadsTo) + 1;
      setRange(data);
    }
  };

  const handelInputValueChange = (e, fieldName) => {
    // Validating the email with regex.
    if (fieldName === 'billing_email') {
      validateEmail(e.target.value);
    }

    // Reset the form on bill_at_group_level change based on value (Only in case of group)
    if (fieldName === 'bill_at_group_level') {
      if (e?.target?.value === true) {
        setFormValue({
          ...formValue,
          billing_email: null,
          billing_start_date: null,
          discount_type: 'NONE',
          bill_at_group_level: e?.target?.value,
          discount: null,
          invoicing_type: 'TIER_BASED_RATE',
          charge_from_card_on_file: false,
          flat_rate_per_month: null,

          charge_at_corp_level: false,
          corporate_agency_uuid: null,

          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        });
      } else {
        setFormValue({
          ...formValue,
          billing_email: null,
          billing_start_date: null,
          discount_type: null,
          bill_at_group_level: e?.target?.value,
          discount: null,
          invoicing_type: null,
          charge_from_card_on_file: null,
          flat_rate_per_month: null,

          charge_at_corp_level: null,
          corporate_agency_uuid: null,

          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        });

        setRange([
          {
            numberOfLeadsFrom: 1,
            numberOfLeadsTo: 'And Above',
            rate: null,
          },
        ]);
      }
    }

    // Cleaning the value of corporate Agency UUID on field value change
    if (fieldName === 'charge_at_corp_level') {
      setFormValue({ ...formValue, corporate_agency_uuid: null });
    }

    // Set franchise share on corporate share change
    if (
      fieldName === 'corporate_share' &&
      isValidValue(fieldName, e.target.value)
    ) {
      setFormValue({
        ...formValue,
        franchisee_share: 100 - e?.target?.value,
      });
    }

    // Set corporate share on franchise share change
    if (
      fieldName === 'franchisee_share' &&
      isValidValue(fieldName, e.target.value)
    ) {
      setFormValue({
        ...formValue,
        corporate_share: 100 - e?.target?.value,
      });
    }

    // Cleaning the range or flat_rate based on invoicing type value.
    if (fieldName === 'invoicing_type') {
      if (e.target.value === 'FLAT_RATE_PER_MONTH') {
        setRange([
          {
            numberOfLeadsFrom: 1,
            numberOfLeadsTo: 'And Above',
            rate: null,
          },
        ]);
        setFormValue({
          ...formValue,
          charge_at_corp_level: false,
          corporate_agency_uuid: null,
          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        });
      } else if (e.target.value === 'TIER_BASED_RATE') {
        setFormValue({
          ...formValue,
          flat_rate_per_month: null,
          charge_at_corp_level: false,
          corporate_agency_uuid: null,
          franchisee_share: null,
          corporate_share: null,
          leads_threshold: null,
          shared_price_per_lead: null,
        });
      } else if (e.target.value === 'SHARED') {
        setRange([
          {
            numberOfLeadsFrom: 1,
            numberOfLeadsTo: 'And Above',
            rate: null,
          },
        ]);
        setFormValue({
          ...formValue,
          flat_rate_per_month: null,
          charge_at_corp_level: false,
          corporate_agency_uuid: null,
        });
      }
    }

    // Cleaning the discount value on discount_type value change.
    if (fieldName === 'discount_type') {
      setFormValue({ ...formValue, discount: '' });
    }
    setFormValue((prevState) => {
      // Restricting user from entering value greater than 2 dacimal places in inputs of type num.
      const updatedValue =
        (fieldName === 'discount' ||
          fieldName === 'flat_rate_per_month' ||
          fieldName === 'shared_price_per_lead' ||
          fieldName === 'corporate_share' ||
          fieldName === 'franchisee_share') &&
        !isValidValue(fieldName, e.target.value)
          ? { ...prevState }
          : {
              ...prevState,
              [fieldName]: e.target.value,
            };

      return updatedValue;
    });
  };

  const isValidValue = (field, value) => {
    let regex = getRegex(field);
    if (field === 'discount' && formValue.discount_type === 'PERCENTAGE') {
      regex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?|null)?$/;
    }
    return regex.test(value);
  };

  const formatInputOutput = (billingDetails, type) => {
    // func to format the range 'And Above/1000000' and datatypes before post and after get.
    const arrayData = billingDetails?.tiered_rates?.tieredRates;
    const finalData = arrayData.map((ele, index) => {
      const temp = { ...ele };
      if (index === arrayData.length - 1) {
        temp.numberOfLeadsTo = type === 'IMPORT' ? 'And Above' : 1000000;
      }
      temp.numberOfLeadsTo =
        type === 'IMPORT'
          ? temp.numberOfLeadsTo.toString()
          : Number(temp.numberOfLeadsTo);
      temp.rate = Number(temp.rate);
      return temp;
    });
    return finalData;
  };

  const checkRangeValidOrNot = () => {
    let valid = true;
    const dataToBeChecked = [...range];
    dataToBeChecked.forEach((ele) => {
      Object.keys(ele).forEach((key) => {
        if (
          ele[key] === null ||
          ele[key] === '' ||
          (dataToBeChecked.indexOf(ele) !== dataToBeChecked.length - 1 &&
            ele.numberOfLeadsTo < ele.numberOfLeadsFrom)
        ) {
          valid = false;
        }
      });
    });
    setFormValidStatus({ ...formValidStatus, rangeValid: valid });
  };

  const validateEmail = (value) => {
    if (value && value !== '') {
      const regex = /^(?!.*,)[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const valuesArray = value.split(',');
      const validationValueArr = [];

      valuesArray.forEach((val) => {
        const isMatch = regex.test(val.trim());
        validationValueArr.push(isMatch);
      });

      setFormValidStatus((prevState) => ({
        ...prevState,
        emailValid: !validationValueArr.includes(false),
      }));
    }
  };

  return useMemo(
    () => (
      <>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          {billingInfoParent === 'group' && (
            <Grid item xs={12} sm={6}>
              <TextField
                id="bill_at_group_level"
                name="bill_at_group_level"
                label="Bill At Group Level"
                variant="outlined"
                select
                value={formValue.bill_at_group_level}
                onChange={(e) => {
                  e.persist();
                  handelInputValueChange(e, 'bill_at_group_level');
                }}
                InputProps={{
                  style: {
                    borderRadius: '7px',
                    fontSize: '20px',
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: '80%',
                }}
              >
                {_.map(bill_at_group_level, (iterated_bill_at_group_level) => (
                  <MenuItem
                    value={iterated_bill_at_group_level.value}
                    key={iterated_bill_at_group_level.value}
                  >
                    {iterated_bill_at_group_level.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>

        {(billingInfoParent === 'agency' ||
          formValue?.bill_at_group_level === true) && (
          <div>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="billing_email"
                  name="billing_email"
                  label="Billing Email (Comma Seperated)"
                  error={!formValidStatus.emailValid}
                  helperText={
                    !formValidStatus.emailValid
                      ? 'Please enter valid email'
                      : ''
                  }
                  variant="outlined"
                  value={formValue.billing_email}
                  onChange={(e) => {
                    e.persist();
                    handelInputValueChange(e, 'billing_email');
                  }}
                  style={{
                    width: '90%',
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '7px',
                      fontSize: '20px',
                      height: '40px',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="charge_from_card_on_file"
                  name="charge_from_card_on_file"
                  label="Charge Card on File"
                  variant="outlined"
                  select
                  value={formValue.charge_from_card_on_file}
                  onChange={(e) => {
                    e.persist();
                    handelInputValueChange(e, 'charge_from_card_on_file');
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '7px',
                      fontSize: '20px',
                      height: '40px',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: '80%',
                  }}
                >
                  {_.map(
                    charge_from_card_on_file,
                    (iterated_charge_from_card_on_file) => (
                      <MenuItem
                        value={iterated_charge_from_card_on_file.value}
                        key={iterated_charge_from_card_on_file.value}
                      >
                        {iterated_charge_from_card_on_file.title}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="billing_start_date"
                  name="billing_start_date"
                  label="Start counting Leads On"
                  variant="outlined"
                  type="date"
                  value={formValue.billing_start_date}
                  onChange={(e) => {
                    e.persist();
                    handelInputValueChange(e, 'billing_start_date');
                  }}
                  style={{
                    width: '80%',
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '7px',
                      fontSize: '20px',
                      height: '40px',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="discount_type"
                  name="discount_type"
                  label="Discount Type"
                  variant="outlined"
                  type="number"
                  select
                  value={formValue.discount_type}
                  onChange={(e) => {
                    e.persist();
                    handelInputValueChange(e, 'discount_type');
                  }}
                  style={{
                    width: '80%',
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '7px',
                      fontSize: '20px',
                      height: '40px',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {_.map(discount_type, (iterated_discount_type) => (
                    <MenuItem
                      value={iterated_discount_type.value}
                      key={iterated_discount_type.value}
                    >
                      {iterated_discount_type.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {formValue.discount_type !== 'NONE' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="discount"
                    name="discount"
                    type="number"
                    label="Discount"
                    variant="outlined"
                    error={
                      (formValue.discount_type === 'AMOUNT' ||
                        formValue.discount_type === 'PERCENTAGE') &&
                      formValue.discount === ''
                    }
                    value={formValue.discount}
                    onChange={(e) => {
                      e.persist();
                      handelInputValueChange(e, 'discount');
                    }}
                    style={{
                      width: '80%',
                    }}
                    InputProps={{
                      // inputProps: { max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">
                          {formValue.discount_type === 'PERCENTAGE' ? '%' : '$'}
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '7px',
                        fontSize: '20px',
                        height: '40px',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: '10px' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="invoicing_type"
                  name="invoicing_type"
                  label="Bill Type"
                  variant="outlined"
                  select
                  value={formValue.invoicing_type}
                  onChange={(e) => {
                    e.persist();
                    handelInputValueChange(e, 'invoicing_type');
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '7px',
                      fontSize: '20px',
                      height: '40px',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    width: '80%',
                  }}
                >
                  {_.map(
                    billingInfoParent === 'group'
                      ? invoicing_type_group
                      : invoicing_type_agency,
                    (iterated_invoicing_type) => (
                      <MenuItem
                        value={iterated_invoicing_type.value}
                        key={iterated_invoicing_type.value}
                      >
                        {iterated_invoicing_type.title}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Grid>
            </Grid>

            {formValue.invoicing_type === 'TIER_BASED_RATE' && (
              <div>
                <Typography style={{ fontSize: '20px', fontWeight: 500 }}>
                  Tiered Rates:-
                </Typography>
                {billingInfoParent === 'group' && (
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: '15px', marginBottom: '20px' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="charge_at_corp_level"
                        name="charge_at_corp_level"
                        label="Charge at Corporate Level"
                        variant="outlined"
                        select
                        value={formValue.charge_at_corp_level}
                        onChange={(e) => {
                          e.persist();
                          handelInputValueChange(e, 'charge_at_corp_level');
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '7px',
                            fontSize: '20px',
                            height: '40px',
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{
                          width: '80%',
                        }}
                      >
                        {_.map(
                          charge_at_corp_level,
                          (iterated_charge_at_corp_level) => (
                            <MenuItem
                              value={iterated_charge_at_corp_level.value}
                              key={iterated_charge_at_corp_level.value}
                            >
                              {iterated_charge_at_corp_level.title}
                            </MenuItem>
                          ),
                        )}
                      </TextField>
                    </Grid>
                    {formValue?.charge_at_corp_level === true && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="corporate_agency_uuid"
                          name="corporate_agency_uuid"
                          label="Corporate Agency UUID"
                          variant="outlined"
                          select
                          value={formValue.corporate_agency_uuid}
                          onChange={(e) => {
                            e.persist();
                            handelInputValueChange(e, 'corporate_agency_uuid');
                          }}
                          InputProps={{
                            style: {
                              borderRadius: '7px',
                              fontSize: '20px',
                              height: '40px',
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{
                            width: '80%',
                          }}
                        >
                          {_.map(
                            uuidDropdownData,
                            (iterated_corporate_agency_uuid) => (
                              <MenuItem
                                value={iterated_corporate_agency_uuid.value}
                                key={iterated_corporate_agency_uuid.value}
                              >
                                {iterated_corporate_agency_uuid.title}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                )}

                <div
                  style={{
                    maxHeight: '250px',
                    overflow: 'auto',
                    marginTop: '20px',
                    paddingTop: '10px',
                  }}
                >
                  <table>
                    <tbody>
                      {range.map((x, i) => (
                        <tr>
                          <td style={{ paddingBottom: '10px' }}>
                            <TextField
                              id="numberOfLeadsFrom"
                              variant="outlined"
                              type="number"
                              disabled
                              value={range[i].numberOfLeadsFrom}
                              onChange={(e) => {
                                e.persist();
                                handelRowChange(e, i, 'numberOfLeadsFrom');
                              }}
                              InputProps={{
                                style: {
                                  borderRadius: '7px',
                                  fontSize: '20px',
                                  height: '40px',
                                },
                              }}
                              style={{
                                width: '120px',
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </td>
                          <td
                            style={{
                              paddingRight: '10px',
                              paddingBottom: '10px',
                              paddingLeft: '10px',
                              fontSize: '20px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {i === range.length - 1 ? '' : 'to'}
                            </div>
                          </td>
                          <td style={{ paddingBottom: '10px' }}>
                            <TextField
                              id="numberOfLeadsTo"
                              variant="outlined"
                              value={range[i].numberOfLeadsTo}
                              disabled={
                                i === range.length - 1 || i < range.length - 2
                              }
                              onChange={(e) =>
                                handelRowChange(e, i, 'numberOfLeadsTo')
                              }
                              error={
                                range[i].numberOfLeadsTo === '' ||
                                range[i].numberOfLeadsFrom >=
                                  Number(range[i].numberOfLeadsTo)
                              }
                              helperText={
                                range[i].numberOfLeadsTo !== '' &&
                                range[i].numberOfLeadsFrom >=
                                  Number(range[i].numberOfLeadsTo)
                                  ? `Must be ${range[i].numberOfLeadsFrom} Plus`
                                  : ''
                              }
                              style={{ width: '120px' }}
                              InputProps={{
                                style: {
                                  borderRadius: '7px',
                                  fontSize: '20px',
                                  height: '40px',
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </td>
                          <td
                            style={{
                              paddingRight: '10px',
                              paddingLeft: '10px',
                              paddingBottom: '10px',
                              fontSize: '20px',
                            }}
                          >
                            leads-
                          </td>
                          <td style={{ paddingBottom: '10px' }}>
                            <TextField
                              id="rate"
                              type="number"
                              variant="outlined"
                              onChange={(e) => handelRowChange(e, i, 'rate')}
                              error={!range[i].rate}
                              value={range[i].rate}
                              style={{ width: '120px' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                                style: {
                                  borderRadius: '7px',
                                  fontSize: '20px',
                                  height: '40px',
                                },
                              }}
                            />
                          </td>
                          <td
                            style={{
                              paddingRight: '10px',
                              paddingLeft: '10px',
                              paddingBottom: '10px',
                              fontSize: '20px',
                            }}
                          >
                            /lead
                          </td>
                          {range.length === i + 1 && (
                            <>
                              <td style={{ paddingBottom: '10px' }}>
                                <IconButton
                                  style={{ color: 'green' }}
                                  onClick={handelAddRow}
                                  disabled={
                                    range.length === 1
                                      ? false
                                      : range[range.length - 2]
                                          .numberOfLeadsTo === '' ||
                                        range[range.length - 2]
                                          .numberOfLeadsFrom >=
                                          Number(
                                            range[range.length - 2]
                                              .numberOfLeadsTo,
                                          )
                                  }
                                >
                                  <AddCircleOutlineOutlined fontSize="large" />
                                </IconButton>
                              </td>
                              <td style={{ paddingBottom: '10px' }}>
                                <IconButton
                                  style={{ color: 'red' }}
                                  disabled={range.length === 1}
                                  onClick={() => {
                                    handelRemoveRow(i - 1);
                                  }}
                                >
                                  <DeleteOutlineIcon fontSize="large" />
                                </IconButton>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {formValue.invoicing_type === 'FLAT_RATE_PER_MONTH' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <Typography style={{ fontSize: '20px', fontWeight: 500 }}>
                  Flat Rates:-
                </Typography>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: '10px', marginBottom: '15px' }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="flat_rate_per_month"
                      name="flat_rate_per_month"
                      type="number"
                      error={
                        formValue.invoicing_type === 'FLAT_RATE_PER_MONTH' &&
                        (formValue.flat_rate_per_month === '' ||
                          formValue.flat_rate_per_month === null)
                      }
                      label="Flat Rate"
                      variant="outlined"
                      value={formValue.flat_rate_per_month}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'flat_rate_per_month');
                      }}
                      style={{
                        width: '80%',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                {billingInfoParent === 'group' && (
                  <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="charge_at_corp_level"
                        name="charge_at_corp_level"
                        label="Charge at Corporate Level"
                        variant="outlined"
                        select
                        value={formValue.charge_at_corp_level}
                        onChange={(e) => {
                          e.persist();
                          handelInputValueChange(e, 'charge_at_corp_level');
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '7px',
                            fontSize: '20px',
                            height: '40px',
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{
                          width: '80%',
                        }}
                      >
                        {_.map(
                          charge_at_corp_level,
                          (iterated_charge_at_corp_level) => (
                            <MenuItem
                              value={iterated_charge_at_corp_level.value}
                              key={iterated_charge_at_corp_level.value}
                            >
                              {iterated_charge_at_corp_level.title}
                            </MenuItem>
                          ),
                        )}
                      </TextField>
                    </Grid>
                    {formValue?.charge_at_corp_level === true && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="corporate_agency_uuid"
                          name="corporate_agency_uuid"
                          label="Corporate Agency UUID"
                          variant="outlined"
                          select
                          value={formValue.corporate_agency_uuid}
                          onChange={(e) => {
                            e.persist();
                            handelInputValueChange(e, 'corporate_agency_uuid');
                          }}
                          InputProps={{
                            style: {
                              borderRadius: '7px',
                              fontSize: '20px',
                              height: '40px',
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{
                            width: '80%',
                          }}
                        >
                          {_.map(
                            uuidDropdownData,
                            (iterated_corporate_agency_uuid) => (
                              <MenuItem
                                value={iterated_corporate_agency_uuid.value}
                                key={iterated_corporate_agency_uuid.value}
                              >
                                {iterated_corporate_agency_uuid.title}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
            )}

            {formValue.invoicing_type === 'SHARED' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <Typography style={{ fontSize: '20px', fontWeight: 500 }}>
                  Shared Details:-
                </Typography>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: '10px', marginBottom: '15px' }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="corporate_agency_uuid"
                      name="corporate_agency_uuid"
                      label="Corporate Agency UUID"
                      error={
                        formValue.invoicing_type === 'SHARED' &&
                        !formValue.corporate_agency_uuid
                      }
                      variant="outlined"
                      select
                      value={formValue.corporate_agency_uuid}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'corporate_agency_uuid');
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        width: '80%',
                      }}
                    >
                      {_.map(
                        uuidDropdownData,
                        (iterated_corporate_agency_uuid) => (
                          <MenuItem
                            value={iterated_corporate_agency_uuid.value}
                            key={iterated_corporate_agency_uuid.value}
                          >
                            {iterated_corporate_agency_uuid.title}
                          </MenuItem>
                        ),
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="shared_price_per_lead"
                      name="shared_price_per_lead"
                      type="number"
                      label="Shared Price Per Lead"
                      variant="outlined"
                      error={
                        formValue.invoicing_type === 'SHARED' &&
                        !formValue.shared_price_per_lead
                      }
                      value={formValue.shared_price_per_lead}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'shared_price_per_lead');
                      }}
                      style={{
                        width: '80%',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {true ? '$' : '$'}
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="corporate_share"
                      name="corporate_share"
                      type="number"
                      label="Corporate Share"
                      variant="outlined"
                      error={
                        formValue.invoicing_type === 'SHARED' &&
                        formValue.corporate_share === null
                      }
                      value={formValue.corporate_share}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'corporate_share');
                      }}
                      style={{
                        width: '80%',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {true ? '%' : '%'}
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="franchisee_share"
                      name="franchisee_share"
                      type="number"
                      label="Franchise Share"
                      variant="outlined"
                      error={
                        formValue.invoicing_type === 'SHARED' &&
                        formValue.franchisee_share === null
                      }
                      value={formValue.franchisee_share}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'franchisee_share');
                      }}
                      style={{
                        width: '80%',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {true ? '%' : '%'}
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="leads_threshold"
                      name="leads_threshold"
                      type="number"
                      label="Leads Threshold"
                      variant="outlined"
                      error={
                        formValue.invoicing_type === 'SHARED' &&
                        !formValue.leads_threshold
                      }
                      value={formValue.leads_threshold}
                      onChange={(e) => {
                        e.persist();
                        handelInputValueChange(e, 'leads_threshold');
                      }}
                      style={{
                        width: '80%',
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '7px',
                          fontSize: '20px',
                          height: '40px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ width: '40%' }}
            onClick={onSubmit}
            disabled={
              (!formValidStatus.rangeValid &&
                formValue?.invoicing_type === 'TIER_BASED_RATE') ||
              ((formValue.flat_rate_per_month === null ||
                formValue.flat_rate_per_month === '') &&
                formValue?.invoicing_type === 'FLAT_RATE_PER_MONTH') ||
              ((!formValue.leads_threshold ||
                formValue.corporate_share === null ||
                !formValue.shared_price_per_lead ||
                formValue.franchisee_share === null) &&
                formValue?.invoicing_type === 'SHARED') ||
              !formValidStatus.emailValid ||
              ((formValue.discount_type === 'AMOUNT' ||
                formValue.discount_type === 'PERCENTAGE') &&
                formValue.discount === '')
            }
          >
            Submit
          </Button>
        </div>
      </>
    ),
    [range, formValue, formValidStatus, uuidDropdownData],
  );
}
