import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { StyledDivider } from '../AppStyledComponents';
import LinkComponent from '../AppLink';

const StyledLink = styled(LinkComponent)`
  text-align: right;
  font-weight: bold;
  display: block;
  margin-bottom: 0.35em;
`;

class AppPageTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, link, children } = this.props;
    return (
      <>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
          spacing={4}
        >
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            {link && (
              <StyledLink
                fontSize={13}
                path={link}
                activeclassname="active"
                title="View All"
              />
            )}
            {children}
          </Grid>
        </Grid>
        <StyledDivider marginbottom={2} />
      </>
    );
  }
}

export default AppPageTitle;
