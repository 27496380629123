import {
  DASHBOARD_INITIAL_STATE,
  GET_LASTFOURMONTHS_LEADSCOUNT_API,
  GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API,
} from './constants';

export function Reducer(state = DASHBOARD_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LASTFOURMONTHS_LEADSCOUNT_API.REQUEST:
    case GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_LASTFOURMONTHS_LEADSCOUNT_API.SUCCESS:
      return {
        ...state,
        lastFourMonthsLeads: action.data,
        isFetching: false,
      };
    case GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API.SUCCESS:
      return {
        ...state,
        lastFourMonthsQRCodeAppLeads: action.data,
        isFetching: false,
      };
    case GET_LASTFOURMONTHS_LEADSCOUNT_API.FAILURE:
    case GET_LASTFOURMONTHS_QRCODEAPP_LEADSCOUNT_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
