import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import ProfileForm from '../../components/ProfileForm';
import {
  getUserProfile,
  updateUserProfile,
} from '../../modules/Profile/actions';

export default function ProfilePage() {
  const profile = useSelector((state) => state.Profile);
  const payment = useSelector((state) => state.Payment);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFormSubmit = (formData) => {
    setLoading(true);
    dispatch(updateUserProfile(formData))
      .then((resp) => {
        if (resp?.data?.success) {
          dispatch(getUserProfile());
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return useMemo(
    () => (
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Profile Page" />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <ProfileForm
                  profile={profile}
                  loading={loading}
                  onSubmit={onFormSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppContainer>
    ),
    [profile, payment],
  );
}
