export const requiredFields = [
  'contact_name',
  'contact_phone',
  'contact_email',
  'name',
  'agency_website_url',
  'address',
  'city',
  'state',
  'zip_postal_code',
  'office_hours_from',
  'office_hours_to',
  'office_hours_timezone',
  'agency_type',
  'description',
  'coverage_area',
  'service_provided',
  'payment_option',
  'UUID',
  'country',
  'client_lead_notifications_emails',
  'employee_lead_notifications_emails',
  'employeeInterviewBookingPreferences',
  'clientBookingPreferences',
  'employeeScreeningPrefrences',
  'clientScreeningPrefrences',
];

export const defaultFieldError = {
  contact_name: false,
  contact_phone: false,
  contact_email: false,
  name: false,
  agency_website_url: false,
  address: false,
  city: false,
  state: false,
  zip_postal_code: false,
  office_hours_from: false,
  office_hours_to: false,
  office_hours_timezone: false,
  agency_type: false,
  description: false,
  coverage_area: false,
  service_provided: false,
  UUID: false,
  search_url: false,
  admin_notes: false,
  payment_option: false,
  min_hours_per_visit: false,
  min_hours_per_day: false,
  min_hours_per_week: false,
  country: false,
  client_lead_notifications_emails: false,
  employee_lead_notifications_emails: false,
  employeeInterviewBookingPreferences: false,
  clientBookingPreferences: false,
  employeeScreeningPrefrences: false,
  clientScreeningPrefrences: false,
  pricing_hour_detail: false,
};
