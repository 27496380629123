import { get as _get } from 'lodash';
import { GET_USER_PROFILE_API, PROFILE_INITIAL_STATE } from './constants';

export function Reducer(state = PROFILE_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_PROFILE_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_USER_PROFILE_API.SUCCESS:
      return {
        ...state,
        isFetching: false,
        address: _get(action, 'data.address'),
        cellNumber: _get(action, 'data.cell_number'),
        city: _get(action, 'data.city'),
        contactPreference: _get(action, 'data.contact_preference'),
        email: _get(action, 'data.email'),
        firstName: _get(action, 'data.first_name'),
        lastName: _get(action, 'data.last_name'),
        middleInitial: _get(action, 'data.middle_initial'),
        state: _get(action, 'data.state'),
        zip: _get(action, 'data.zip_postal_code'),
      };
    case GET_USER_PROFILE_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
