import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';

// Services & Actions
import { downloadLeadsReport } from '../../modules/Leads/actions';
import { clearSelectedAgency } from '../../modules/Agency/actions';

// Custom components
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { AppContainer } from '../../packages/AppContainer';
import { StyledDivider } from '../../packages/AppStyledComponents';

function ReportPage() {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const downloadFile = () => {
    dispatch(
      downloadLeadsReport(
        format(fromDate, 'yyyy-MM-dd'),
        format(toDate, 'yyyy-MM-dd'),
      ),
    ).then((resp) => {
      const blob = new Blob(['\ufeff', resp.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      let filename = 'LeadsReport.csv';
      const disposition = resp.headers['content-disposition'];
      if (disposition && disposition.indexOf('inline') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  React.useEffect(() => {
    dispatch(clearSelectedAgency());
  }, []);

  return (
    <AppContainer>
      <Grid
        alignItems="flex-start"
        justify="space-between"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Reports" />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <Card elevation={0} style={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6">
                    Leads Report for All Agencies
                  </Typography>
                  <StyledDivider marginbottom={2} />
                  <Box mb={2} />
                  <Box
                    display="flex"
                    align="center"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <KeyboardDatePicker
                      margin="normal"
                      id="from-date-picker-dialog"
                      label="From"
                      format="MM/dd/yyyy"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      disableFuture
                      KeyboardButtonProps={{
                        'aria-label': 'from change date',
                      }}
                    />
                    <KeyboardDatePicker
                      margin="normal"
                      id="to-date-picker-dialog"
                      label="To"
                      format="MM/dd/yyyy"
                      value={toDate}
                      onChange={handleToDateChange}
                      disableFuture
                      KeyboardButtonProps={{
                        'aria-label': 'to change date',
                      }}
                    />
                  </Box>

                  <Box
                    display="flex"
                    align="center"
                    justifyContent="flex-end"
                    alignItems="center"
                    mb={2}
                  >
                    <AppButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      label="Download Report"
                      handleClick={() => {
                        downloadFile();
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}

export default ReportPage;
