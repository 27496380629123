import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getAppServerSession } from '../../modules/Session/actions';
import AppPage from '../../app';
import { APP_CONSTANTS } from '../../constants';

function AppRouteResolver() {
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.Session?.isAuthenticated);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getAppServerSession());
    }
  }, [loggedIn]);

  return useMemo(
    () =>
      loggedIn ? (
        <AppPage />
      ) : (
        <Redirect to={APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN} />
      ),
    [loggedIn],
  );
}

export default hot(AppRouteResolver);
