import {
  forEach as _forEach,
  isString as _isString,
  toLower as _toLower,
} from 'lodash';
export const createAPIActions = (prefix, suffix) => {
  const PREFIX = prefix.toUpperCase();
  const SUFFIX = suffix.toUpperCase();
  return {
    NAME: `${PREFIX}/${SUFFIX}`,
    REQUEST: `${PREFIX}/${SUFFIX}_REQUEST`,
    SUCCESS: `${PREFIX}/${SUFFIX}_SUCCESS`,
    FAILURE: `${PREFIX}/${SUFFIX}_FAILURE`,
  };
};

export function debounced(delay, fn) {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export const httpStatusCodeMapToVariant = (statusCode) => {
  if (statusCode) {
    if (statusCode >= 200 && statusCode < 300) {
      return 'success';
    }
    if (statusCode >= 300 && statusCode < 400) {
      return 'info';
    }
    if (statusCode >= 400 && statusCode < 500) {
      return 'error';
    }
    if (statusCode >= 500) {
      return 'warning';
    }
  }
  return 'success';
};

export const getUniqueKey = (name, index) => `uid_${_toLower(name)}_${index}`;

/**
 *
 * @param value
 * @param regExpValue
 * @param required
 * @returns {boolean}
 * @constructor
 */
export function ValidateField(value, regExpValue, required) {
  if (value && regExpValue) {
    const re = new RegExp(regExpValue);
    if (re.test(value)) {
      return false;
    }
  } else if (value && value.length > 0) {
    return false;
  } else if (!required) {
    return false;
  }
  return true;
}

export const validateForm = ({ requiredFields, fieldError }) => {
  let validFieldCount = 0;
  _forEach(requiredFields, (fieldName) => {
    if (
      fieldError[fieldName] !== undefined &&
      fieldError[fieldName] === false
    ) {
      validFieldCount += 1;
    }
  });

  return validFieldCount === requiredFields.length;
};

export const getFirstCharFromString = (str) => {
  if (str && _isString(str) && str.length > 0) {
    return str.charAt(0);
  }
  return null;
};

export function convertTimeToAMPM(convertTime) {
  let time = convertTime;
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

export const convertTime12to24 = (time12h) => {
  if (time12h) {
    const [time, modifier] = time12h.split(' ');

    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  return `00:00`;
};

export const getQueryStringParams = (query) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          // eslint-disable-next-line no-param-reassign
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};

export const filterPayload = (payload) => {
  const filteredPayload = { ...payload };
  Object.keys(filteredPayload).forEach((key) => {
    const value = filteredPayload[key];
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // Filter empty values from arrays
        filteredPayload[key] = value.filter(
          (item) => item !== null && item !== '',
        );
      } else {
        // Recursively filter nested objects
        filteredPayload[key] = filterPayload(value);
        if (Object.keys(filteredPayload[key]).length === 0) {
          delete filteredPayload[key];
        }
      }
    } else if (value === undefined || value === null || value === '') {
      delete filteredPayload[key];
    }
  });
  return filteredPayload;
};

export function getNullForEmptyString(dayWeekHours) {
  return dayWeekHours === '' ? null : dayWeekHours;
}
