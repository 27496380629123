const FormFields = [
  {
    id: 'register-admin-page',
    title: '',
    type: 'register-admin-page',
    list: [
      {
        name: 'firstName',
        value: '',
        type: 'text',
        id: 'first-name',
        label: 'First Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'lastName',
        value: '',
        type: 'text',
        id: 'last-name',
        label: 'Last Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'email',
        value: '',
        type: 'email',
        id: 'email-id',
        label: 'Email Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\S+@\\S+\\.\\S+',
      },
      {
        name: 'cellPhone',
        value: '',
        type: 'phone',
        id: 'cellPhone-id',
        label: 'Phone Number',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '(XXX) XXX-XXXX',
        required: true,
        error: false,
        width: 50,
        regExpValue: '^\\([2-9]\\d\\d\\) [2-9]\\d\\d-\\d{4}$',
      },
    ],
  },
  {
    id: 'register-agency-basic-info-page',
    title: '',
    type: 'register-agency-basic-info-page',
    list: [
      {
        name: 'agencyName',
        value: '',
        type: 'text',
        id: 'agency-name-id',
        label: 'Agency Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'agencyWebsite',
        value: '',
        type: 'text',
        id: 'agency-website-id',
        label: 'Agency Website',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
      },
      {
        name: 'agencyAddress',
        value: '',
        type: 'text',
        id: 'agency-address-id',
        label: 'Agency Address',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'agencyCity',
        value: '',
        type: 'text',
        id: 'agency-city-id',
        label: 'Agency City',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
      {
        name: 'agencyState',
        value: '',
        type: 'select',
        id: 'agency-state-id',
        label: 'Agency State',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        values: [
          {
            label: 'Alabama',
            value: 'AL',
          },
          {
            label: 'Alaska',
            value: 'AK',
          },
          {
            label: 'American Samoa',
            value: 'AS',
          },
          {
            label: 'Arizona',
            value: 'AZ',
          },
          {
            label: 'Arkansas',
            value: 'AR',
          },
          {
            label: 'California',
            value: 'CA',
          },
          {
            label: 'Colorado',
            value: 'CO',
          },
          {
            label: 'Connecticut',
            value: 'CT',
          },
          {
            label: 'Delaware',
            value: 'DE',
          },
          {
            label: 'District Of Columbia',
            value: 'DC',
          },
          {
            label: 'Federated States Of Micronesia',
            value: 'FM',
          },
          {
            label: 'Florida',
            value: 'FL',
          },
          {
            label: 'Georgia',
            value: 'GA',
          },
          {
            label: 'Guam',
            value: 'GU',
          },
          {
            label: 'Hawaii',
            value: 'HI',
          },
          {
            label: 'Idaho',
            value: 'ID',
          },
          {
            label: 'Illinois',
            value: 'IL',
          },
          {
            label: 'Indiana',
            value: 'IN',
          },
          {
            label: 'Iowa',
            value: 'IA',
          },
          {
            label: 'Kansas',
            value: 'KS',
          },
          {
            label: 'Kentucky',
            value: 'KY',
          },
          {
            label: 'Louisiana',
            value: 'LA',
          },
          {
            label: 'Maine',
            value: 'ME',
          },
          {
            label: 'Marshall Islands',
            value: 'MH',
          },
          {
            label: 'Maryland',
            value: 'MD',
          },
          {
            label: 'Massachusetts',
            value: 'MA',
          },
          {
            label: 'Michigan',
            value: 'MI',
          },
          {
            label: 'Minnesota',
            value: 'MN',
          },
          {
            label: 'Mississippi',
            value: 'MS',
          },
          {
            label: 'Missouri',
            value: 'MO',
          },
          {
            label: 'Montana',
            value: 'MT',
          },
          {
            label: 'Nebraska',
            value: 'NE',
          },
          {
            label: 'Nevada',
            value: 'NV',
          },
          {
            label: 'New Hampshire',
            value: 'NH',
          },
          {
            label: 'New Jersey',
            value: 'NJ',
          },
          {
            label: 'New Mexico',
            value: 'NM',
          },
          {
            label: 'New York',
            value: 'NY',
          },
          {
            label: 'North Carolina',
            value: 'NC',
          },
          {
            label: 'North Dakota',
            value: 'ND',
          },
          {
            label: 'Northern Mariana Islands',
            value: 'MP',
          },
          {
            label: 'Ohio',
            value: 'OH',
          },
          {
            label: 'Oklahoma',
            value: 'OK',
          },
          {
            label: 'Oregon',
            value: 'OR',
          },
          {
            label: 'Palau',
            value: 'PW',
          },
          {
            label: 'Pennsylvania',
            value: 'PA',
          },
          {
            label: 'Puerto Rico',
            value: 'PR',
          },
          {
            label: 'Rhode Island',
            value: 'RI',
          },
          {
            label: 'South Carolina',
            value: 'SC',
          },
          {
            label: 'South Dakota',
            value: 'SD',
          },
          {
            label: 'Tennessee',
            value: 'TN',
          },
          {
            label: 'Texas',
            value: 'TX',
          },
          {
            label: 'Utah',
            value: 'UT',
          },
          {
            label: 'Vermont',
            value: 'VT',
          },
          {
            label: 'Virgin Islands',
            value: 'VI',
          },
          {
            label: 'Virginia',
            value: 'VA',
          },
          {
            label: 'Washington',
            value: 'WA',
          },
          {
            label: 'West Virginia',
            value: 'WV',
          },
          {
            label: 'Wisconsin',
            value: 'WI',
          },
          {
            label: 'Wyoming',
            value: 'WY',
          },
        ],
      },
      {
        name: 'agencyZip',
        value: '',
        type: 'text',
        id: 'agency-zip-id',
        label: 'Agency Zip Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 33,
        regExpValue: '\\b[0-9]{4,8}\\b',
      },
      {
        name: 'payment_option',
        value: [],
        type: 'multiSelect',
        id: 'payment_option',
        label: 'Payment Options',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: false,
        width: 33,
        values: [],
      },
      {
        name: 'hourly_rate_from',
        value: '',
        type: 'number',
        id: 'hourly_rate_from',
        label: 'From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        startAdornment: '$',
        haveCommonField: true,
        commonFieldHeading: 'Hourly Rate Range',
      },
      {
        name: 'hourly_rate_to',
        value: '',
        type: 'number',
        id: 'hourly_rate_to',
        label: 'To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        haveCommonField: true,
        startAdornment: '$',
        boxProps: {
          paddingTop: 3.75,
        },
      },
      {
        name: 'liveIn_rate_from',
        value: '',
        type: 'number',
        id: 'liveIn_rate_from',
        label: 'From',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        haveCommonField: true,
        commonFieldHeading: 'Live-In Rate Range',
        startAdornment: '$',
      },
      {
        name: 'liveIn_rate_to',
        value: '',
        type: 'number',
        id: 'liveIn_rate_to',
        label: 'To',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: false,
        error: false,
        width: 25,
        haveCommonField: true,
        startAdornment: '$',
        boxProps: {
          paddingTop: 3.75,
        },
      },
    ],
  },
  {
    id: 'register-agency-logo-color-info-page',
    title: '',
    type: 'register-agency-logo-color-info-page',
    list: [
      {
        name: 'primaryColor',
        value: '',
        type: 'color',
        id: 'primary-color-id',
        label: 'Primary Color Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{4,8}\\b',
      },
      {
        name: 'secondaryColor',
        value: '',
        type: 'color',
        id: 'secondary-color-id',
        label: 'Secondary Color Code',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{4,8}\\b',
      },
    ],
  },
  {
    id: 'register-agency-office-info-page',
    title: '',
    type: 'register-agency-office-info-page',
    list: [
      {
        name: 'fromTime',
        value: '09:00',
        type: 'time',
        id: 'from-time-id',
        label: 'Office Hours From Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
      },
      {
        name: 'toTime',
        value: '17:00',
        type: 'time',
        id: 'to-time-id',
        label: 'Office Hours To Time',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
      },
      {
        name: 'timeZone',
        value: '',
        type: 'select',
        id: 'time-zone-id',
        label: 'Time Zone',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 50,
        values: [],
      },
      {
        name: 'coverageCities',
        value: '',
        type: 'text',
        id: 'coverage-cities-id',
        label: 'Coverage Cities',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        required: true,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,50}\\b',
      },
      {
        name: 'serviceProvided',
        value: '',
        type: 'select',
        id: 'service-provided-id',
        label: 'Service Provided',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 50,
        values: [],
      },
      {
        name: 'serving_radius',
        value: '5',
        type: 'select',
        id: 'serving_radius-id',
        label: 'Serving Radius',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        values: [
          {
            label: '5 miles',
            value: '5',
          },
          {
            label: '10 miles',
            value: '10',
          },
          {
            label: '20 miles',
            value: '20',
          },
          {
            label: '30 miles',
            value: '30',
          },
          {
            label: '50 miles',
            value: '50',
          },
          {
            label: '100 miles',
            value: '100',
          },
        ],
      },
    ],
  },
];

export default FormFields;
