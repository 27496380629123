import {
  AGENCY_INITIAL_STATE,
  CLEAR_SELECTED_AGENCY,
  ADD_AGENCY_API,
  CREATE_AGENCY_API,
  GET_AGENCY_API,
  SET_SELECTED_AGENCY,
  CREATE_BILLING_INFO,
  CLEAR_SELECTED_AGENCY_BILLING_INFO,
  SET_SELECTED_AGENCY_BILLING_INFO,
} from './constants';

export function Reducer(state = AGENCY_INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_AGENCY_API.REQUEST:
    case CREATE_AGENCY_API.REQUEST:
    case CREATE_BILLING_INFO.REQUEST:
    case GET_AGENCY_API.REQUEST:
      return { ...state, isFetching: true };
    case ADD_AGENCY_API.SUCCESS:
    case CREATE_AGENCY_API.SUCCESS:
    case CREATE_BILLING_INFO.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_AGENCY_API.SUCCESS:
      return {
        ...state,
        agencyList: action.data,
        isFetching: false,
      };
    case SET_SELECTED_AGENCY:
      return {
        ...state,
        selectedAgency: action.agency,
      };
    case SET_SELECTED_AGENCY_BILLING_INFO:
      return {
        ...state,
        selectedAgencyBillingInfo: action.data,
      };
    case CLEAR_SELECTED_AGENCY:
      return {
        ...state,
        selectedAgency: {},
      };
    case CLEAR_SELECTED_AGENCY_BILLING_INFO:
      return {
        ...state,
        selectedAgencyBillingInfo: {},
      };
    case ADD_AGENCY_API.FAILURE:
    case CREATE_AGENCY_API.FAILURE:
    case CREATE_BILLING_INFO.FAILURE:
    case GET_AGENCY_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
