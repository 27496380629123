import { createAPIActions } from '../../utils';

export const LEADS_INITIAL_STATE = {
  isFetching: false,
  leadsList: [],
};
export const GET_ALL_LEADS_API = createAPIActions('get', 'all/leads/api');
export const GET_LEAD_BY_ID_API = createAPIActions('get', 'lead/by/id/api');
export const GET_LEAD_COUNT_API = createAPIActions('get', 'lead/count/api');
export const GET_LEADS_REPORT_API = createAPIActions('get', 'leads/report/api');
export const UPDATE_LEAD_BY_ID_API = createAPIActions('update', 'lead/id/api');
