import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, IconButton } from '@material-ui/core';
import TimePicker from 'react-time-picker';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import './availabilitySlots.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const pageConfig = {
  appointment_booking: {
    header: 'Potential Client Appointment Booking Preferences',
    subHeader:
      'NOTE: Pick days and times which you would like our chat agents to book appointments for you. Once you recieve a booking notification from AidQuest, You have to contact the client to confirm and/or adjust the time.',
  },
  interview_booking: {
    header: 'Potential Employee Interview Booking Preferences',
    subHeader:
      'NOTE: Pick days and times which you would like our chat agents to book appointments for you. Once you recieve a booking notification from AidQuest, You have to contact the applicant to confirm and/or adjust the time.',
  },
};

export default function AvailabilitySlotPreferences(props) {
  const { apiFormData, pageType, handleChange, formDataKey } = props;
  const [slots, setSlots] = useState([
    {
      dayOfWeek: 'MONDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'TUESDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'WEDNESDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'THURSDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'FRIDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'SATURDAY',
      slotsAvailable: false,
      timeframes: [],
    },
    {
      dayOfWeek: 'SUNDAY',
      slotsAvailable: false,
      timeframes: [],
    },
  ]);

  useEffect(() => {
    if (apiFormData) {
      handelIncomingApiData(slots, apiFormData);
    }
  }, [props]);

  function handelIncomingApiData(initState, apiRes) {
    const apiData = [...apiRes];
    const slotsData = [...slots];
    apiData.forEach((obj, index) => {
      slotsData[index] = { ...slotsData[index], ...obj };
    });
    setSlots([...slotsData]);
  }

  const onSubmit = (data) => {
    const dataArr = data;
    const error = checkFormValidOrNot(dataArr);
    handleChange(formDataKey, dataArr, error);
  };

  const addNewTimeRange = (slotsArrayIndex) => {
    const currentSlots = [...slots];
    if (currentSlots[slotsArrayIndex].slotsAvailable === false) {
      currentSlots[slotsArrayIndex].slotsAvailable = true;
    }
    currentSlots[slotsArrayIndex].timeframes.push({
      from: '',
      to: '',
    });
    setSlots(currentSlots);
    onSubmit(currentSlots);
  };

  const deleteTimeRange = (slotsArrayIndex) => {
    const slotsArr = [...slots];
    slotsArr[slotsArrayIndex].timeframes.pop();
    if (slotsArr[slotsArrayIndex].timeframes.length === 0) {
      slotsArr[slotsArrayIndex].slotsAvailable = false;
    }
    setSlots(slotsArr);
    onSubmit(slotsArr);
  };

  const copyTimeRange = (slotsArrayIndex) => {
    const slotsArr = JSON.parse(JSON.stringify(slots));
    slotsArr[slotsArrayIndex + 1].timeframes =
      slots[slotsArrayIndex].timeframes;

    slotsArr[slotsArrayIndex + 1].slotsAvailable =
      slots[slotsArrayIndex].slotsAvailable;

    setSlots(slotsArr);
    onSubmit(slotsArr);
  };

  const onCheckboxToggle = (event, slotsArrayIndex) => {
    const checkboxVal = event.target.checked;
    const currentSlots = [...slots];
    if (checkboxVal === true) {
      currentSlots[slotsArrayIndex].timeframes = [
        {
          from: '',
          to: '',
        },
      ];
    } else {
      currentSlots[slotsArrayIndex].timeframes = [];
    }
    currentSlots[slotsArrayIndex].slotsAvailable = checkboxVal;
    setSlots(currentSlots);
    onSubmit(currentSlots);
  };

  const onTimeValueChange = (event, slotsArrayIndex, timeFrameIndex, key) => {
    const slotsArray = [...slots];
    const fieldVal = event;
    slotsArray[slotsArrayIndex].timeframes[timeFrameIndex][key] = fieldVal;
    setSlots(slotsArray);
    onSubmit(slotsArray);
  };

  const checkFormValidOrNot = (data) => {
    let error = false;
    data.forEach((ele) => {
      ele.timeframes.forEach((daySlot) => {
        if (!daySlot.to || !daySlot.from) {
          error = true;
        }
      });
    });
    return error;
  };

  return useMemo(
    () => (
      <>
        <div className="parent-container">
          <h2 className="header">{pageConfig[pageType].header}</h2>
          <h2 className="sub-header">{pageConfig[pageType].subHeader}</h2>
          <div style={{ marginTop: '20px', overflow: 'auto' }}>
            {slots.map((obj, parentIndex) => (
              <div className="content-container">
                <Checkbox
                  checked={obj?.slotsAvailable}
                  onChange={(event) => {
                    onCheckboxToggle(event, parentIndex);
                  }}
                />
                <div>
                  <p className="day-text">{obj?.dayOfWeek}: </p>
                </div>
                <div className="row-slot-container">
                  {obj?.timeframes.map((childObj, childIndex) => (
                    <div className="textfield-container">
                      <div>
                        <TimePicker
                          disableClock
                          format="h:m a"
                          clearIcon={null}
                          className={
                            !slots[parentIndex].timeframes[childIndex].from
                              ? 'time-picker-error'
                              : 'time-picker-success'
                          }
                          onChange={(event) => {
                            onTimeValueChange(
                              event,
                              parentIndex,
                              childIndex,
                              'from',
                            );
                          }}
                          value={childObj.from}
                        />
                      </div>
                      <p style={{ marginLeft: '5px', marginRight: '5px' }}>
                        to
                      </p>
                      <div>
                        <TimePicker
                          disableClock
                          format="h:m a"
                          clearIcon={null}
                          className={
                            !slots[parentIndex].timeframes[childIndex].to
                              ? 'time-picker-error'
                              : 'time-picker-success'
                          }
                          onChange={(event) => {
                            onTimeValueChange(
                              event,
                              parentIndex,
                              childIndex,
                              'to',
                            );
                          }}
                          value={childObj.to}
                        />
                      </div>
                    </div>
                  ))}
                  {slots[parentIndex].timeframes.length < 3 && (
                    <Tooltip title="Add Slot">
                      <IconButton
                        onClick={() => {
                          addNewTimeRange(parentIndex);
                        }}
                        disabled={slots[parentIndex].timeframes.length >= 3}
                        style={{ color: '#033E63', padding: '0px' }}
                      >
                        <AddCircleOutlineOutlined fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {slots[parentIndex].timeframes.length !== 0 &&
                    parentIndex !== slots.length - 1 && (
                      <Tooltip title="Copy same slots to next day">
                        <IconButton
                          onClick={() => {
                            copyTimeRange(parentIndex);
                          }}
                          style={{
                            color: '#033E63',
                            padding: '0px',
                          }}
                        >
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {slots[parentIndex].timeframes.length !== 0 && (
                    <Tooltip title="Remove Slot">
                      <IconButton
                        onClick={() => {
                          deleteTimeRange(parentIndex);
                        }}
                        style={{ color: '#033E63', padding: '0px' }}
                      >
                        <DeleteOutlineIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    ),
    [slots],
  );
}
