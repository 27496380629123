import React, { PureComponent } from 'react';
import Link from '@material-ui/core/Link';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { AppButton } from '../../packages/AppButton';
import FormGroupComponent from '../../packages/AppFormGroup';
import AppAdapterLink from '../../packages/AppAdapterLink';
import FormFields from './FormField.json';
import { APP_CONSTANTS } from '../../constants';
import { debounced, validateForm } from '../../utils';
import AppBasePageCard from '../../packages/AppBasePageCard';
import { doLogin } from '../../modules/Login/actions';

class LoginPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      heading: 'Sign-in to AidQuest.',
      formValue: { email: '', password: '' },
      fieldError: {},
      requiredFields: ['email', 'password'],
      validForm: false,
      loading: false,
    };
    this.doFormValidation = debounced(400, () => {
      this.setState((prevState) => ({
        validForm: validateForm({ ...prevState }),
      }));
    });
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.props.history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
    }
  }

  handleChange = ({ name, value, error }) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        formValue: {
          ...prevState.formValue,
          [name]: value,
        },
        fieldError: {
          ...prevState.fieldError,
          [name]: error,
        },
      }),
      () => {
        this.doFormValidation();
      },
    );
  };

  formSubmitHandler = () => {
    this.setState({ loading: true, validForm: false });
    const {
      formValue: { email: username, password },
    } = this.state;

    this.props
      .doLogin({ username, password })
      .then(() => {
        this.props.history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
      })
      .catch(() => {
        this.setState({ loading: false, validForm: true });
      });
  };

  render() {
    const { heading, validForm, loading } = this.state;
    return (
      <AppBasePageCard
        cardClassName="login-card"
        title={['Welcome!', 'Please enter your account details to login.']}
        md={3}
      >
        <CardHeader title={heading} className="card-heading" />
        <CardContent className="card-content">
          <form
            noValidate
            autoComplete="off"
            name="signInForm"
            className="form-container"
          >
            <FormGroupComponent
              handleChange={this.handleChange}
              formFields={FormFields[0]}
              values={{ email: '', password: '' }}
            />
            <FormControl fullWidth margin="normal">
              <AppButton
                fullWidth
                variant="contained"
                color="primary"
                disabled={!validForm}
                label="Sign-in"
                loading={loading}
                loaderSize={24}
                handleClick={this.formSubmitHandler}
              />
            </FormControl>
          </form>
          <Typography variant="subtitle1" align="center">
            Not an AidQuest member?{' '}
            <Link
              component={AppAdapterLink}
              to={APP_CONSTANTS.BASE_ROUTE_URLS.BASIC_REGISTER}
              color="primary"
              style={{ marginLeft: 8 }}
            >
              Sign up here.
            </Link>
          </Typography>
          <Typography variant="subtitle1" align="center">
            <Link
              component={AppAdapterLink}
              to={APP_CONSTANTS.BASE_ROUTE_URLS.RESET_PASSWORD}
              color="primary"
            >
              Reset Password
            </Link>
          </Typography>
        </CardContent>
      </AppBasePageCard>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.Session.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      doLogin,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage),
);
