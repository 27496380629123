import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppContainer } from '../../packages/AppContainer';
import { APP_CONSTANTS } from '../../constants';
import { AppButton } from '../../packages/AppButton';
import {
  updateCaregiver,
  getCaregiverByID,
  getAllCaregivers,
} from '../../modules/Caregivers/actions';
import CaregiverForm from '../../components/CaregiverForm';

export default function UpdateCaregiverPage() {
  const [caregiverObj, setCaregiver] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { id: caregiverId } = params;

  const timezones = useSelector((state) => state.MetaData.timezones);
  const availability = useSelector((state) => state.MetaData.availability);
  const language = useSelector((state) => state.MetaData.language);
  const insurance = useSelector((state) => state.MetaData.insurance);
  const certificate = useSelector((state) => state.MetaData.certificate);
  const care_condition = useSelector((state) => state.MetaData.care_condition);
  const identity = useSelector((state) => state.MetaData.identity);

  useEffect(() => {
    dispatch(getCaregiverByID(caregiverId)).then((resp) => {
      setCaregiver(resp.data);
    });
  }, [caregiverId]);

  const onFormSubmit = (formData) => {
    setLoading(true);
    dispatch(updateCaregiver(formData, caregiverId))
      .then((resp) => {
        const {
          data: { success },
        } = resp;
        setLoading(false);
        if (success) {
          dispatch(getAllCaregivers());
          history.push(APP_CONSTANTS.APP_ROUTE_URLS.CARE_GIVERS);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <AppContainer>
      <Grid
        alignItems="flex-start"
        justify="space-between"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Update Caregiver">
            <AppButton
              variant="text"
              color="primary"
              label="Back to Caregivers"
              handleClick={() => {
                history.push(APP_CONSTANTS.APP_ROUTE_URLS.CARE_GIVERS);
              }}
            />
          </AppPageTitle>
        </Grid>
        {timezones.length > 0 &&
          availability.length > 0 &&
          language.length > 0 &&
          insurance.length > 0 &&
          certificate.length > 0 &&
          care_condition.length > 0 &&
          identity.length > 0 && (
            <Grid item xs={12}>
              <CaregiverForm
                timezones={timezones}
                availability={availability}
                language={language}
                insurance={insurance}
                certificate={certificate}
                care_condition={care_condition}
                identity={identity}
                loading={loading}
                caregiver={caregiverObj}
                formType="Update"
                onSubmit={onFormSubmit}
                onCancel={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.CARE_GIVERS);
                }}
              />
            </Grid>
          )}
      </Grid>
    </AppContainer>
  );
}
