import axios from 'axios';
import * as _ from 'lodash';
import {
  dispatchError,
  requestStart,
  fetchStart,
  fetchStop,
  requestSuccess,
} from './actions';
import { enqueueSnackbar } from '../packages/AppNotifier/actions';
import { API_TIMEOUT } from './constant';
import { APP_CONSTANTS } from '../constants';
import { setToken } from '../modules/Token/actions';
import { doLogOut } from '../modules/Logout/actions';
import { filterPayload } from '../utils';

function getQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');
}

function mapToErrorResponse({ response, dispatch, params, error, status }) {
  if (response?.data?.error_details) {
    _.forEach(response?.data?.error_details, (item) => {
      errorHandler(
        dispatch,
        params,
        item?.message || error?.message,
        status || response?.status,
      );
    });
  } else {
    errorHandler(
      dispatch,
      params,
      response?.data?.message || error.message,
      status || response?.status,
    );
  }
}

function errorHandler(dispatch, params, errorMsg, status) {
  dispatch(dispatchError(params.actionType.FAILURE, errorMsg));
  dispatch(fetchStop(params.actionType.NAME));
  dispatch(enqueueSnackbar({ message: errorMsg, statusCode: status }));
}

function authHandler(dispatch, response, isAuthenticated) {
  if (response) {
    if (response?.headers?.authorization || response?.headers?.Authorization) {
      if (!isAuthenticated) {
        dispatch(setToken(response.headers.authorization, true));
      }
    } else if (
      isAuthenticated &&
      (response.status === 403 || response.status === 401)
    ) {
      dispatch(doLogOut());
    }
  }
}

function request(params) {
  const method = params.method || 'GET';
  let qs = '';
  let { url } = params;
  let { baseURL } = params;
  let { timeout } = params;
  let data;
  const formData = new FormData();
  const payload = params?.filterPayload
    ? filterPayload(params.data)
    : params.data;
  if (params?.file) {
    formData.append('file', payload, params?.fileName);
  }
  const headers = params.headers || {
    Authorization: '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const { responseType } = params;
  if (!baseURL) {
    baseURL = APP_CONSTANTS.BASE_API_URL;
  }
  if (!timeout) {
    timeout = API_TIMEOUT;
  }
  if (['GET'].indexOf(method) > -1 && payload)
    qs = `?${getQueryString(payload)}`;
  else if (params?.file) data = formData;
  else if (params?.formData) data = payload;
  else data = JSON.stringify(payload); // POST PUT DELETE
  url += qs;

  return (dispatch, getState) => {
    const {
      Token: { token },
      Session: { isAuthenticated },
    } = getState();
    headers.Authorization = token;

    dispatch(requestStart(params.actionType.REQUEST));
    dispatch(fetchStart(params.actionType.NAME));
    return axios({
      method,
      url,
      headers,
      data,
      baseURL,
      timeout,
      responseType,
    })
      .then((response) => {
        if (response.data?.error) {
          mapToErrorResponse({
            response,
            dispatch,
            params,
            error: response,
            status: 400,
          });
        } else {
          authHandler(dispatch, response, isAuthenticated);
          dispatch(fetchStop(params.actionType.NAME));
          dispatch(requestSuccess(params.actionType.SUCCESS, response));
          if (response.data?.message) {
            dispatch(
              enqueueSnackbar({
                message: response.data?.message,
                statusCode: response.status,
              }),
            );
          }
        }

        return response;
      })
      .catch((error) => {
        // console.log(error);
        if (error?.message && error.message.includes('timeout')) {
          errorHandler(dispatch, params, error.message, 408);
        }
        mapToErrorResponse({
          response: error?.response,
          dispatch,
          params,
          error,
        });
        authHandler(dispatch, error.response, isAuthenticated);
        throw error;
      });
  };
}

export default {
  get: (params) => request({ method: 'GET', ...params }),
  post: (params) => request({ method: 'POST', ...params }),
  put: (params) => request({ method: 'PUT', ...params }),
  patch: (params) => request({ method: 'PATCH', ...params }),
  delete: (params) => request({ method: 'DELETE', ...params }),
};
