import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 280,
    backgroundColor: '#a6e0de',
  },
  media: {
    height: 280,
    backgroundSize: 'contain',
  },
}));

export default function DashboardCard(props) {
  const {
    cardMedia,
    cardDetails,
    cardTitle,
    textColor,
    path,
    additionalDetails,
  } = props;
  const [count, setCount] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (!cardMedia) {
      setCount(cardDetails);
    }
  }, [cardMedia, cardDetails]);

  const renderAdditionalDetails = () => {
    if (
      cardTitle === 'Last 4 Months Leads Count' ||
      cardTitle === 'QR Code App Leads Count'
    ) {
      return (
        <table style={{ marginTop: '2px' }}>
          <tbody>
            {Object.entries(additionalDetails).map(([key, value]) => (
              <tr>
                <td
                  style={{
                    width: '100px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  {key}
                </td>
                <td style={{ fontWeight: 'bold' }}>:</td>
                <td
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    paddingLeft: '18px',
                  }}
                >
                  {value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    // Return null or an empty fragment if cardTitle doesn't match
    return null;
  };
  return (
    <Card className={classes.root} elevation={0} square>
      <CardActionArea onClick={() => history.push(path)}>
        {cardMedia ? (
          <CardMedia className={classes.media} image={cardMedia} />
        ) : (
          <CardContent>
            <Box
              color={`common.${textColor}`}
              height="250px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="inherit"
              >
                {cardTitle}
              </Typography>
              {renderAdditionalDetails()}
              <Typography variant="h3">{count}</Typography>
            </Box>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
}

DashboardCard.propTypes = {
  cardMedia: PropTypes.node,
  cardTitle: PropTypes.string,
  cardDetails: PropTypes.any,
  path: PropTypes.string,
  textColor: PropTypes.oneOf(['black', 'white']),
  additionalDetails: PropTypes.any,
};
DashboardCard.defaultProps = {
  textColor: 'black',
};
