import React from 'react';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';

export default function AppSingleCheckbox(props) {
  const {
    formField: {
      label,
      labelPlacement,
      id,
      value: defaultValue,
      name,
      required,
      disabled,
    },
  } = props;

  const handleChange = (event) => {
    const {
      target: { checked, value, type: fieldType },
    } = event;
    const actValue = fieldType === 'checkbox' ? checked : value;
    props.handleChange({ name, value: actValue, error: false });
  };

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            color="primary"
            checked={
              props.value !== undefined &&
              props.value !== null &&
              props.value !== ''
                ? props.value
                : defaultValue
            }
            onChange={handleChange}
            name={name}
            required={required}
            disabled={disabled}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );
}
