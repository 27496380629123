import React from 'react';
import * as PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';

export default function AppCard(props) {
  const {
    boxProps,
    raised,
    header,
    content,
    action,
    children,
    headerProps,
    contentProps,
    actionProps,
    ...other
  } = props;
  return (
    <Box {...boxProps}>
      <Card raised={raised} {...other}>
        {header && <CardHeader title={header} {...headerProps} />}
        {content && <CardContent {...contentProps}>{content}</CardContent>}
        {action && <CardActions {...actionProps}>{action}</CardActions>}
        {children}
      </Card>
    </Box>
  );
}

AppCard.propTypes = {
  raised: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  action: PropTypes.node,
  component: PropTypes.elementType,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  variant: PropTypes.oneOf(['elevation', 'outlined']),
  boxProps: PropTypes.object,
  headerProps: PropTypes.object,
  contentProps: PropTypes.object,
  actionProps: PropTypes.object,
};
AppCard.defaultProps = {
  raised: false,
  elevation: 0,
  square: true,
  variant: 'elevation',
};
