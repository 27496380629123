import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppPageTitle from '../../packages/AppPageTitle';
import { AppButton } from '../../packages/AppButton';
import { APP_CONSTANTS } from '../../constants';
import { AppContainer } from '../../packages/AppContainer';
import { debounced, validateForm } from '../../utils';
import { defaultFieldError, requiredFields } from './constants';
import FormFields from './FormField';
import FormGroupComponent from '../../packages/AppFormGroup';
import {
  getGroup,
  getGroupById,
  updateGroup,
} from '../../modules/Group/actions';
import FileUploadComponent from '../../packages/AppFileUpload';
import AppCard from '../../packages/AppCard';

export default function UpdateGroupPage() {
  const [formValue, setFormValue] = useState({
    payment_option: '',
  });
  const [fieldError, setFieldError] = useState({ ...defaultFieldError });
  const [validForm, setValidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [groupObj, setGroup] = useState({});

  const userType = useSelector((state) => state.User.userType);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { groupId } = params;

  const doFormValidation = debounced(400, () => {
    setValidForm(validateForm({ requiredFields, fieldError }));
  });
  const handleChange = ({ name, value, error }) => {
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
    setFieldError((prevState) => ({ ...prevState, [name]: error }));
  };
  const formSubmitHandler = () => {
    setLoading(true);
    setValidForm(false);
    const formData = new FormData();
    if (selectedFile && selectedFile.length > 0) {
      formData.append('group_logo', selectedFile[0]);
    }
    formData.append(
      'group_details',
      new Blob(
        [
          JSON.stringify({
            group_details: {
              ...groupObj,
              ...formValue,
              logo_name:
                selectedFile && selectedFile.length > 0
                  ? selectedFile[0].name
                  : groupObj?.logo_name || '',
            },
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    dispatch(updateGroup(groupId, formData))
      .then((resp) => {
        if (resp?.data?.success) {
          dispatch(getGroup());
        }
        setValidForm(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setValidForm(true);
      });
  };

  useEffect(() => {
    doFormValidation();
  }, [formValue]);

  useEffect(() => {
    if (userType === 'BOOMERS_ADMIN') {
      FormFields[0].list.UUID.disabled = true;
    }
  }, [userType]);
  useEffect(() => {
    dispatch(getGroupById(groupId)).then((resp) => {
      setGroup(resp.data);
    });
  }, [groupId]);

  return (
    <AppContainer>
      <Grid
        alignItems="stretch"
        justify="flex-start"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Update Group">
            {userType === 'BOOMERS_ADMIN' && (
              <AppButton
                variant="text"
                color="primary"
                label="Back to Groups"
                handleClick={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.GROUPS);
                }}
              />
            )}
          </AppPageTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid
            direction="column"
            alignItems="center"
            justify="center"
            alignContent="center"
            spacing={2}
            container
          >
            <Grid item xs={12}>
              <AppCard
                content={
                  <>
                    <FormGroupComponent
                      handleChange={handleChange}
                      formFields={FormFields[0]}
                      values={{
                        ...groupObj,
                      }}
                    />
                    <Box
                      display="flex"
                      justifycontent="space-between"
                      alignItems="center"
                    >
                      <div>
                        <Typography gutterBottom variant="subtitle1">
                          Upload Logo
                        </Typography>
                        <FileUploadComponent
                          selectedImageUrl={groupObj.logo_image_url}
                          style={{ marginBottom: 16 }}
                          width={220}
                          accept=".png, .jpg, .jpeg"
                          onDrop={(data) => setSelectedFile(data)}
                          dropIcon
                        />
                      </div>
                      <Box
                        display="flex"
                        justifycontent="flex-end"
                        alignItems="center"
                      >
                        <AppButton
                          label="Cancel"
                          loaderColor="secondary"
                          handleClick={() =>
                            history.push(APP_CONSTANTS.APP_ROUTE_URLS.GROUPS)
                          }
                        />
                        <AppButton
                          marginleft={1}
                          variant="contained"
                          color="primary"
                          loaderColor="secondary"
                          label="Update Group"
                          disabled={!validForm}
                          loading={loading}
                          loaderSize={24}
                          handleClick={formSubmitHandler}
                        />
                      </Box>
                    </Box>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
