import * as _ from 'lodash';

export const FORM_STEPS = [
  'Organization Info',
  'Office Info',
  'Notifications',
  'Payment Info',
];

export const OPTIONAL_FIELDS_WITH_VALIDATION = [
  'client_lead_notifications_emails',
  'employee_lead_notifications_emails',
  'client_lead_notification_phones',
  'employee_lead_notifications_phones',
  'billing_email',
];

export const STEP_WISE_REQUIRED_FIELDS = {
  0: [
    'name',
    'agency_website_url',
    'address',
    'country',
    'city',
    'state',
    'zip_postal_code',
    'agency_type',
    'service_provided',
    'payment_option',
  ],
  1: ['office_hours_timezone', 'coverage_area'],
  2: ['client_lead_notifications_emails', 'employee_lead_notifications_emails'],
  3: [],
};

export const REQUIRED_FIELDS = _.flatten(_.values(STEP_WISE_REQUIRED_FIELDS));
