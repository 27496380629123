import { createAPIActions } from '../../utils';

export const CAREGIVERS_INITIAL_STATE = {
  isFetching: false,
  caregiversList: [],
};
export const GET_ALL_CAREGIVERS_API = createAPIActions(
  'get',
  'all/caregivers/api',
);
export const GET_CAREGIVER_BY_ID_API = createAPIActions(
  'get',
  'all/caregiver/id/api',
);
export const ADD_CAREGIVERS_API = createAPIActions('add', 'caregivers/api');
export const UPDATE_CAREGIVERS_API = createAPIActions(
  'update',
  'caregivers/api',
);
export const UPLOAD_CAREGIVERS_PROFILE_PROCESS_AND_MERGE_API = createAPIActions(
  'uploaded',
  'caregivers/profile/process/merge/api',
);
export const UPLOAD_CAREGIVERS_PROFILE_API = createAPIActions(
  'upload',
  'caregivers/profile/api',
);
