import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import {
  activateGroup,
  deactivateGroup,
  setSelectedGroup,
} from '../../modules/Group/actions';
import { APP_CONSTANTS } from '../../constants';
import IOSSwitch from '../../packages/AppIOSSwitch';
import LinkComponent from '../../packages/AppLink';

export function GroupAction(props) {
  const {
    deleteGroup,
    row: { id, type },
  } = props;
  const history = useHistory();

  return (
    <TableCell
      align="justify"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <Box display="flex" align="center" justifyContent="space-between">
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            history.push(
              `${APP_CONSTANTS.APP_ROUTE_URLS.GROUP}/${id}${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`,
            );
          }}
        >
          <PaymentOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            history.push(
              `${
                type === 'BASIC'
                  ? APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_BASIC_AGENCY
                  : APP_CONSTANTS.APP_ROUTE_URLS.UPDATE_GROUP
              }/${id}`,
            );
          }}
          disabled
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            history.push(
              `${APP_CONSTANTS.APP_ROUTE_URLS.GROUP}/${id}${APP_CONSTANTS.APP_ROUTE_URLS.PAYMENT}`,
            );
          }}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            deleteGroup(id);
          }}
          disabled
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    </TableCell>
  );
}

export function ToggleGroupStatus(props) {
  const {
    row: { id, status },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setActive(_.toLower(status) === 'active');
  }, [status]);

  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(activateGroup(id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
        }
      });
    } else {
      dispatch(deactivateGroup(id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <IOSSwitch checked={active} onChange={handleChange} name={id} />
    </TableCell>
  );
}

export function GroupLeads(props) {
  const {
    row: { id, total_leads },
    row: rowObj,
  } = props;
  const dispatch = useDispatch();

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${id}/leads`}
        onClick={() => {
          dispatch(setSelectedGroup(rowObj));
        }}
      >
        {total_leads}
      </LinkComponent>
    </TableCell>
  );
}
export function GroupCaregivers(props) {
  const {
    row: { id, total_active_caregivers },
    row: rowObj,
  } = props;
  const dispatch = useDispatch();

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={`${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${id}/care-givers`}
        onClick={() => {
          dispatch(setSelectedGroup(rowObj));
        }}
      >
        {total_active_caregivers}
      </LinkComponent>
    </TableCell>
  );
}
export function GroupName(props) {
  const {
    row: { name },
  } = props;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      {name}
    </TableCell>
  );
}
