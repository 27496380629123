export const REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'gender',
  'yearsOfExp',
  'availability',
  'skills',
  'city',
  'zip',
];

export const FIELD_ERROR_INITIAL_STATE = {
  firstName: true,
  lastName: true,
  gender: true,
  yearsOfExp: true,
  availability: true,
  language: false,
  identity: false,
  insurance: false,
  certificate: false,
  skills: true,
  pets: false,
  smoker: false,
  email: false,
  cellPhone: false,
  city: true,
  zip: true,
  state: false,
};

export const UPDATE_FIELD_ERROR_INITIAL_STATE = {
  ...FIELD_ERROR_INITIAL_STATE,
  firstName: false,
  lastName: false,
  gender: false,
  yearsOfExp: false,
  availability: false,
  skills: false,
  city: false,
  zip: false,
};
