export const requiredFields = [
  'firstName',
  'lastName',
  'email',
  'cellPhone',
  // 'address',
  // 'city',
  // 'state',
  // 'country',
  // 'zip',
];

export const fieldErrorInitialState = {
  firstName: false,
  lastName: false,
  email: false,
  cellPhone: false,
  // address: false,
  // city: false,
  // state: false,
  // country: false,
  // zip: false,
};
