import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import { getUniqueKey } from '../../utils';
import { StyledInputLabel } from '../AppStyledComponents';

export default function AppMultiSelect(props) {
  const {
    formField: {
      id,
      label,
      name,
      required,
      defaultValue,
      values,
      width,
      hidden,
      boxProps,
    },
    handleChange,
  } = props;
  const [selectedValue, setSelectValue] = useState([]);
  const [error, setError] = useState(true);

  useEffect(() => {
    setSelectValue(props.value || defaultValue || []);
  }, [props.value]);

  const handleSelectChange = (event) => {
    const { target } = event;
    const val = target.type === 'checkbox' ? target.checked : target.value;
    const err = required ? val && val.length > 0 : true;
    if (handleChange) {
      handleChange({ name, value: val, error: !err });
    }
    setSelectValue(val);
    setError(err);
  };

  return useMemo(
    () =>
      hidden ? null : (
        <Box width={1} {...boxProps}>
          <StyledInputLabel
            htmlFor={id}
            style={{ marginBottom: 8 }}
            color="primary"
            required={required}
            variant="standard"
          >
            {label}
          </StyledInputLabel>
          <Select
            required={required}
            input={<Input name={name} id={id} />}
            value={
              selectedValue && selectedValue.length > 0 ? selectedValue : []
            }
            error={!error}
            multiple
            onChange={handleSelectChange}
            style={{ width: `100%` }}
            renderValue={(selected) => selected.join(', ')}
          >
            {_.map(
              values,
              ({ label: optionLabel, value: optionValue }, index) => (
                <MenuItem
                  value={optionValue}
                  key={getUniqueKey(optionValue, index)}
                >
                  <Checkbox
                    checked={
                      selectedValue && selectedValue.indexOf(optionValue) > -1
                    }
                  />
                  <ListItemText primary={optionLabel || optionValue} />
                </MenuItem>
              ),
            )}
          </Select>
        </Box>
      ),
    [id, label, required, width, name, selectedValue, error, values],
  );
}
