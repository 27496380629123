export const discount_type = [
  {
    title: 'None',
    value: 'NONE',
  },
  {
    title: 'Percentage',
    value: 'PERCENTAGE',
  },
  {
    title: 'Amount',
    value: 'AMOUNT',
  },
];

export const invoicing_type_agency = [
  {
    title: 'Tiered',
    value: 'TIER_BASED_RATE',
  },
  {
    title: 'Fixed Monthly Pricing',
    value: 'FLAT_RATE_PER_MONTH',
  },
];

export const invoicing_type_group = [
  {
    title: 'Tiered',
    value: 'TIER_BASED_RATE',
  },
  {
    title: 'Fixed Monthly Pricing',
    value: 'FLAT_RATE_PER_MONTH',
  },
  {
    title: 'Shared',
    value: 'SHARED',
  },
];

export const charge_from_card_on_file = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
];

export const charge_at_corp_level = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
];

export const bill_at_group_level = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
];

export const getRegex = (fieldName) => {
  switch (fieldName) {
    case 'numberOfLeadsTo':
      return /^(\s?\d*)$/;
    case 'rate':
    case 'discount':
    case 'flat_rate_per_month':
    case 'shared_price_per_lead':
      return /^\s?(\d+(\.\d{1,2})?|\s?)\s?$/;
    case 'corporate_share':
    case 'franchisee_share':
      return /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?|null)?$/;
    default:
      return /.*/;
  }
};
