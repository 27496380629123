import { FETCH_START, FETCH_STOP } from './constant';

const addToFetchList = (fetchList, action) => {
  const currentFetchList = fetchList;
  currentFetchList.push(action);
  return currentFetchList;
};

const removeFromFetchList = (fetchList, action) =>
  fetchList && fetchList.length > 0
    ? fetchList.filter((item) => item !== action)
    : [];

const defaultState = {
  currentFetchList: [],
};

export function Reducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        currentFetchList: addToFetchList(
          [...state.currentFetchList],
          action.fetchActionName,
        ),
      };
    case FETCH_STOP:
      return {
        ...state,
        currentFetchList: removeFromFetchList(
          [...state.currentFetchList],
          action.fetchActionName,
        ),
      };
    default:
      return state;
  }
}
