import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import IconButton from '@material-ui/core/IconButton';
import IOSSwitch from '../../packages/AppIOSSwitch';
import {
  activateUser,
  deactivateUser,
  getUser,
  userAccess,
} from '../../modules/AgencyUsers/action';
import { APP_CONSTANTS } from '../../constants';

export function UsersActions(props) {
  const history = useHistory();

  const {
    row: { user_id, first_name, last_name },
  } = props;

  const actionHandler = (event) => {
    event.preventDefault();
    history.push(`${APP_CONSTANTS.APP_ROUTE_URLS.ACCESS_AGENCY}`, [
      user_id,
      first_name,
      last_name,
    ]);
  };

  return (
    <TableCell
      align="center"
      size="small"
      style={{
        width: props.width,
        textAlign: 'center',
      }}
    >
      <Button color="primary" onClick={actionHandler} variant="contained">
        Manage Permissions
      </Button>
    </TableCell>
  );
}

export function ToggleUserStatus(props) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();

  const {
    row: { user_id, status },
  } = props;

  useEffect(() => {
    setActive(_.toLower(status) === 'active');
  }, [status]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(activateUser(user_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
          dispatch(getUser());
        }
      });
    } else {
      dispatch(deactivateUser(user_id)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setActive(checked);
          dispatch(getUser());
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
        textAlign: 'left',
      }}
    >
      <IOSSwitch checked={active} onChange={handleChange} name={user_id} />
    </TableCell>
  );
}

export function ToggleUserAccess(props) {
  const dispatch = useDispatch();
  const [access, setAccess] = useState(false);

  const {
    row: { agency_id: agencyId, user_id: userId, access_status: accessStatus },
  } = props;

  const data = {
    agency_id: agencyId,
    user_id: userId,
    access: !access,
  };

  useEffect(() => {
    setAccess(_.toLower(accessStatus) === 'true');
  }, [accessStatus]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(userAccess(data)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setAccess(checked);
        }
      });
    } else {
      dispatch(userAccess(data)).then((resp) => {
        const {
          data: { success },
        } = resp;
        if (success) {
          setAccess(checked);
        }
      });
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <IOSSwitch checked={access} onChange={handleChange} name={userId} />
    </TableCell>
  );
}

export function ToggleUserAgencyAccess(props) {
  const [access, setAccess] = useState(false);

  const {
    row: { agency_id: agencyId },
    giveAccess: giveAgencyAccess,
    removeAccess: removeAgencyAccess,
  } = props;

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      giveAgencyAccess(agencyId);
      setAccess(checked);
    } else {
      removeAgencyAccess(agencyId);
      setAccess(checked);
    }
  };

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <IOSSwitch checked={access} onChange={handleChange} name={agencyId} />
    </TableCell>
  );
}
