import { SET_USER_INFO, USER_INITIAL_STATE } from './constants';
import { LOG_IN_API } from '../Login/constants';
import { GET_APP_SERVER_SESSION_API } from '../Session/constants';
import { LOG_OUT } from '../Logout/constants';

export function Reducer(state = USER_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_APP_SERVER_SESSION_API.SUCCESS:
    case LOG_IN_API.SUCCESS:
    case SET_USER_INFO:
      return {
        ...state,
        userType: action?.userInfo?.user_type || action?.data?.user_type,
        firstName: action?.userInfo?.first_name || action?.data?.first_name,
        lastName: action?.userInfo?.last_name || action?.data?.last_name,
        middleName:
          action?.userInfo?.middle_initial || action?.data?.middle_initial,
      };
    case LOG_OUT:
      return USER_INITIAL_STATE;
    default:
      return state;
  }
}
