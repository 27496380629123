import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { APP_CONSTANTS } from '../../constants';
import LinkComponent from '../../packages/AppLink';

export function LeadsCreationTimestampCell(props) {
  const {
    width,
    row: { creation_timestamp },
  } = props;
  return (
    <TableCell
      align="center"
      size="small"
      style={{
        width,
        textAlign: 'left',
      }}
    >
      {new Date(`${creation_timestamp}`).toLocaleString(
        'en-US',
        APP_CONSTANTS.DATE_FORMAT,
      )}
    </TableCell>
  );
}

export function LeadsZip(props) {
  const {
    width,
    row: { zip_postal_code, zip_where_care_needed },
  } = props;
  return (
    <TableCell
      align="center"
      size="small"
      style={{
        width,
        textAlign: 'left',
      }}
    >
      {zip_postal_code || zip_where_care_needed}
    </TableCell>
  );
}

export function LeadsFirstName(props) {
  const {
    agencyId,
    row: { lead_id, first_name },
  } = props;
  const path = agencyId
    ? `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agencyId}/lead/${lead_id}`
    : `${APP_CONSTANTS.APP_ROUTE_URLS.LEAD_DETAILS}${lead_id}`;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={path}
      >
        {first_name}
      </LinkComponent>
    </TableCell>
  );
}

export function LeadsLastName(props) {
  const {
    agencyId,
    row: { lead_id, last_name },
  } = props;
  const path = agencyId
    ? `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${agencyId}/lead/${lead_id}`
    : `${APP_CONSTANTS.APP_ROUTE_URLS.LEAD_DETAILS}${lead_id}`;

  return (
    <TableCell
      align="left"
      size="small"
      style={{
        width: props.width,
      }}
    >
      <LinkComponent
        fontSize={16}
        className="active"
        color="primary"
        path={path}
      >
        {last_name}
      </LinkComponent>
    </TableCell>
  );
}
