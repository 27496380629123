import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { forEach as _forEach } from 'lodash';
import { persistStore, persistReducer } from 'redux-persist';
import reduxReset from 'redux-reset';
import expireReducer from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
// import createCompressor from 'redux-persist-transform-compress';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../modules';
import { APP_CONSTANTS } from '../constants';

const isClient = typeof window !== 'undefined';
// const compressor = createCompressor();
const persistConfig = {
  key: APP_CONSTANTS.APP_NAME,
  storage,
  whitelist: APP_CONSTANTS.WHITELISTED_PERSIST_STORE,
  transforms: [],
};

// if (process.env.NODE_ENV === 'production') {
//   persistConfig.transforms.push(compressor);
// }

_forEach(APP_CONSTANTS.EXPIRE_OPTION_STORE, (storeName) => {
  persistConfig.transforms.push(
    expireReducer(storeName, {
      expireSeconds: APP_CONSTANTS.EXPIRE_STORE_AFTER_SECOND,
      expiredState: {},
      autoExpire: true,
    }),
  );
});

function getComposeEnhancers() {
  if (
    (['client', 'both'].includes(process.env.REACT_APP_DEBUG) ||
      process.env.NODE_ENV !== 'production') &&
    isClient &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: false }); // eslint-disable-line
  }
  return compose;
}

export function configureStore(history) {
  const middlewareList =
    process.env.NODE_ENV !== 'production'
      ? [thunk, routerMiddleware(history)]
      : [thunk, routerMiddleware(history)];
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const composeEnhancers = getComposeEnhancers();
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewareList), reduxReset()),
  );
  const memStore = persistStore(store);
  return { store, memStore };
}
