import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { AppButton } from '../../AppButton';
import { StyledDialogActions } from '../../AppStyledComponents';

/**
 *
 * @param title
 * @param children
 * @param isOpen
 * @param onClose
 * @param maxWidth
 * @returns {*}
 * @constructor
 */
const InfoModal = ({ title, children, isOpen, onClose, maxWidth }) => {
  /**
   * @member open
   */
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  /**
   * @method
   */
  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  /**
   * @returns {React.Component}
   */
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth || 'xs'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <StyledDialogActions>
        <AppButton
          size="small"
          color="primary"
          label="Ok"
          variant="contained"
          handleClick={handleClose}
        />
      </StyledDialogActions>
    </Dialog>
  );
};

export default InfoModal;
