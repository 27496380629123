const FormFields = [
  {
    id: 'register-admin-page',
    title: '',
    type: 'register-admin-page',
    list: {
      name: {
        name: 'name',
        value: '',
        type: 'text',
        id: 'group-name-id',
        label: 'Group Name',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        required: true,
        error: false,
        width: 50,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },

      UUID: {
        name: 'UUID',
        value: '',
        type: 'text',
        id: 'agency-UUID-id',
        label: 'Agency UUID',
        margin: 'normal',
        fullWidth: true,
        multiline: false,
        placeholder: '',
        error: false,
        width: 33,
        regExpValue: '\\b[a-zA-Z0-9]{1,16}\\b',
      },
    },
  },
];

export default FormFields;
