import React, { PureComponent } from 'react';
import * as _ from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { withRouter } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { APP_CONSTANTS } from '../../constants';
import { AppContainer, AppRow } from '../AppContainer';
import ListComponent from '../AppList/index';
import ListItemComponent from '../AppListItem/index';
import LinkComponent from '../AppLink';
import { getUniqueKey } from '../../utils';
import { doLogOut } from '../../modules/Logout/actions';
import AppLogo from '../AppLogo';
import AppMenu from '../AppMenu';
import AppUserProfileMenu from '../AppUserProfileMenu';

const StyledLogo = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  cursor: pointer;
`;
const StyledAgency = styled(StyledLogo)`
  margin-left: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.palette.shade3.main};
  > i {
    font-style: normal;
    padding-left: ${(props) => props.theme.spacing(1)}px;
    padding-right: ${(props) => props.theme.spacing(1)}px;
  }
`;
const Root = styled.div`
  flex-grow: 1;
  width: 100%;
`;
const StyledAppRow = styled(AppRow)`
  min-height: 65px;
`;
const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-colorPrimary {
    background-color: ${(props) => props.theme.palette.common.white};
  }
  background-color: ${(props) => props.theme.palette.common.white};
`;
const StyledListComponent = styled(ListComponent)`
  > li {
    padding: 0;
    margin-left: ${(props) => props.theme.spacing()}px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      > a {
        padding-right: 0;
      }
    }
  }
`;
const StyledLink = styled(LinkComponent)`
  padding: ${(props) => props.theme.spacing() * 2}px
    ${(props) => props.theme.spacing() + 4}px;
  display: block;
  text-transform: uppercase;
  &.active {
    color: ${(props) => props.theme.palette.primary.main};
    text-decoration: underline;
  }
`;

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

class AppHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  listenScrollEvent = (e) => {
    e.preventDefault();
    if (window.scrollY >= 40) {
      // this.setState({ color: 'primary' });
    } else {
      // this.setState({ color: 'transparent' });
    }
  };

  openDashboard = () => {
    this.props.history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
  };

  getAppNavLinks = (isAuthenticated, userType, selectedAgency) => {
    if (isAuthenticated) {
      return selectedAgency?.agency_id && userType === 'BOOMERS_ADMIN'
        ? [
            ...APP_CONSTANTS.AUTH_USER_NAV_BAR_LINK[userType],
            {
              name: 'Caregivers',
              link: `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${selectedAgency.agency_id}/care-givers`,
              key: 'care-givers-link',
              visible: true,
              active: true,
            },
            {
              name: 'Leads',
              link: `${APP_CONSTANTS.APP_ROUTE_URLS.AGENCY}/${selectedAgency.agency_id}/leads`,
              key: 'leads-link',
              visible: true,
              active: true,
            },
          ]
        : APP_CONSTANTS.AUTH_USER_NAV_BAR_LINK[userType];
    }
    return APP_CONSTANTS.NAV_BAR_LINK;
  };

  render() {
    const { isAuthenticated, userType, selectedAgency } = this.props;
    // const { color } = this.state;
    return (
      <Root>
        <ElevationScroll {...this.props}>
          <StyledAppBar>
            <AppContainer>
              <StyledAppRow>
                <Hidden mdUp>
                  <AppMenu
                    links={this.getAppNavLinks(
                      isAuthenticated,
                      userType,
                      selectedAgency,
                    )}
                  />
                </Hidden>
                <StyledLogo
                  component="div"
                  onClick={() => {
                    this.openDashboard();
                  }}
                >
                  <AppLogo width={240} />
                </StyledLogo>
                {this.props?.selectedAgency?.name && (
                  <StyledAgency variant="h6">
                    <i>|</i>
                    {this.props?.selectedAgency?.name}
                    <i>|</i>
                  </StyledAgency>
                )}
                <Box display="flex" flex={1} />
                <Hidden mdDown>
                  <StyledListComponent>
                    {_.map(
                      this.getAppNavLinks(
                        isAuthenticated,
                        userType,
                        selectedAgency,
                      ),
                      ({ key, name, link, visible }, index) =>
                        visible ? (
                          <ListItemComponent
                            key={getUniqueKey(`${name}_${key}`, index)}
                          >
                            <StyledLink
                              fontSize={16}
                              path={link}
                              activeclassname="active"
                              title={name}
                              hovercolor="secondary"
                              underline="hover"
                              color="textPrimary"
                            />
                          </ListItemComponent>
                        ) : null,
                    )}
                  </StyledListComponent>
                </Hidden>
                {isAuthenticated && <AppUserProfileMenu />}
              </StyledAppRow>
            </AppContainer>
          </StyledAppBar>
        </ElevationScroll>
      </Root>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.Session.isAuthenticated,
  selectedAgency: state.Agency.selectedAgency,
  theme: state.Theme,
  userType: state.User.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      doLogOut,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppHeader),
);
