import React, { useEffect } from 'react';
import * as _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { clearSelectedAgency } from '../../modules/Agency/actions';
import DashboardCard from '../../components/DashboardCard';
import { APP_CONSTANTS } from '../../constants';
import {
  getLastFourMonthsLeadsCount,
  getLastFourMonthsQRCodeAppLeadsCount,
} from '../../modules/Dashboard/actions';

function getActiveLeadsAndCaregivers(agencyList) {
  let totalLeads = 0;
  _.forEach(agencyList, (agencyObj) => {
    totalLeads += _.parseInt(agencyObj?.total_leads);
  });
  return {
    totalLeads,
  };
}

export default function DashboardPage() {
  const dispatch = useDispatch();
  const agencyList = useSelector((state) => state.Agency.agencyList);
  const groupList = useSelector((state) => state.Groups.groupList);
  const userType = useSelector((state) => state.User.userType);
  const lastFourMonthsLeads = useSelector(
    (state) => state.Dashboard.lastFourMonthsLeads,
  );
  const lastFourMonthsQRCodeAppLeads = useSelector(
    (state) => state.Dashboard.lastFourMonthsQRCodeAppLeads,
  );

  const { totalLeads } = getActiveLeadsAndCaregivers(agencyList);
  useEffect(() => {
    if (
      userType === 'BOOMERS_ADMIN' ||
      userType === 'AGENCY_ADMIN_BASIC' ||
      userType === 'SUPER_ADMIN_2'
    ) {
      dispatch(clearSelectedAgency());
    }
  }, [userType]);

  useEffect(() => {
    if (userType === 'BOOMERS_ADMIN' || userType === 'SUPER_ADMIN_2') {
      dispatch(getLastFourMonthsLeadsCount());
      dispatch(getLastFourMonthsQRCodeAppLeadsCount());
    }
  }, []);

  return (
    <AppContainer>
      <Grid
        alignItems="flex-start"
        justify="space-between"
        alignContent="stretch"
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <AppPageTitle title="Dashboard" />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            alignContent="center"
            spacing={2}
          >
            {userType !== 'BOOMERS_ADMIN_AGENCY' && (
              <Grid item xs={12} sm={4}>
                <DashboardCard
                  cardTitle="Total Number Of Leads"
                  cardDetails={totalLeads || 0}
                  textColor="black"
                  path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={4}>
              <DashboardCard
                cardTitle="Total Agencies"
                cardDetails={(agencyList && agencyList.length) || 0}
                textColor="black"
                path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}
              />
            </Grid>

            {(userType === 'BOOMERS_ADMIN' || userType === 'SUPER_ADMIN_2') && (
              <Grid item xs={12} sm={4}>
                <DashboardCard
                  cardTitle="Total Groups"
                  cardDetails={(groupList && groupList.length) || 0}
                  textColor="black"
                  path={APP_CONSTANTS.APP_ROUTE_URLS.GROUPS}
                />
              </Grid>
            )}

            {(userType === 'BOOMERS_ADMIN' || userType === 'SUPER_ADMIN_2') && (
              <Grid item xs={12} sm={4}>
                <DashboardCard
                  cardTitle="Last 4 Months Leads Count"
                  textColor="black"
                  path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}
                  additionalDetails={lastFourMonthsLeads}
                />
              </Grid>
            )}

            {(userType === 'BOOMERS_ADMIN' || userType === 'SUPER_ADMIN_2') && (
              <Grid item xs={12} sm={4}>
                <DashboardCard
                  cardTitle="QR Code App Leads Count"
                  textColor="black"
                  path={APP_CONSTANTS.APP_ROUTE_URLS.AGENCIES}
                  additionalDetails={lastFourMonthsQRCodeAppLeads}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
