import { AUTH_INITIAL_STATE, LOG_IN_API } from './constants';

export function Reducer(state = AUTH_INITIAL_STATE, action) {
  switch (action.type) {
    case LOG_IN_API.REQUEST:
      return { ...state, isFetching: true };
    case LOG_IN_API.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case LOG_IN_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
